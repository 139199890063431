// import { getGenerativeModel, getVertexAI } from 'firebase/vertexai-preview';

import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';
import { getFunctions } from 'firebase/functions';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyBm4EhH5rB3slXM8npGAmpXvMx4CuQDVJo',
	authDomain: 'city-crawler-llc.firebaseapp.com',
	projectId: 'city-crawler-llc',
	storageBucket: 'city-crawler-llc.appspot.com',
	messagingSenderId: '186270420544',
	appId: '1:186270420544:web:fc8f989a7d326c0a2799a5',
	measurementId: 'G-CG7ZNHK924',
	databaseURL: 'https://city-crawler-llc-default-rtdb.firebaseio.com/',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const vertexAI = getVertexAI(app);
// export const model = getGenerativeModel(vertexAI, {
// 	model: 'gemini-1.5-pro-preview-0409',
// });
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
