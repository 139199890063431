import { HeadingLarge, ParagraphLarge } from 'baseui/typography';
import { useContext, useState } from 'react';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { AuthModal } from '../auth/AuthModal';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { CardWithBackgroundImageAndGradient } from '../layout/CardWithBackgroundImageAndGradient';
import { sendPositiveNotification } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import zombieVacationCover from '../../img/zombie-vacation-cover.png';

const CONTENT_SHADOW = '3px 3px 7px black';

export function CreateTripCard() {
	const { t } = useTranslation();
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();

	const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

	const redirectUrl = '/plan';

	const handleDiscoverNew = () => {
		if (user) {
			navigate(redirectUrl);
		} else {
			sendPositiveNotification(t('loginToPlanTrip'));
			setIsAuthModalOpen(true);
		}
	};
	return (
		<>
			<CardWithBackgroundImageAndGradient
				backgroundUrl={zombieVacationCover}
				gradientColor="transparent"
				gradientDirection="to top"
				fitBackground={true}
			>
				<Block $style={{ height: '7rem' }} />
				<Block
					width="100%"
					display="flex"
					flexDirection="column"
					alignItems="flex-start"
				>
					<HeadingLarge
						style={{
							color: 'white',
							textShadow: CONTENT_SHADOW,
						}}
					>
						{t('planTrip')}
					</HeadingLarge>
					<ParagraphLarge
						style={{
							color: 'white',
							textShadow: CONTENT_SHADOW,
						}}
					>
						{t('noBrain')}
					</ParagraphLarge>
					<Button
						onClick={handleDiscoverNew}
						style={{
							boxShadow: CONTENT_SHADOW,
							width: '100%',
							marginTop: '1rem',
						}}
					>
						{t('discoverNew')}
					</Button>
				</Block>
			</CardWithBackgroundImageAndGradient>
			<AuthModal
				isOpen={isAuthModalOpen && !user}
				onClose={() => setIsAuthModalOpen(false)}
				successRedirectUrl={'/plan'}
			/>
		</>
	);
}
