export const getContactRequestPath = (uid: string, timestamp: number) =>
	`contact_requests/${uid}/${JSON.stringify(timestamp)}`;

export const getContactRequestForUserPath = (uid: string) =>
	`contact_requests/${uid}`;

export const getTripPath = (uid: string, tripId: string) =>
	`trips/${uid}/${tripId}`;

export const getTripsForUserPath = (uid: string) => `trips/${uid}`;

export const getTripForUserPath = (uid: string, tripId: string) =>
	`trips/${uid}/${tripId}`;
