import { Block } from 'baseui/block';
import { HeadingMedium } from 'baseui/typography';

export function Header({ text }: { text: string }) {
	return (
		<Block display='flex' justifyContent='center' paddingBottom='0.5rem'>
			<HeadingMedium>{text}</HeadingMedium>
		</Block>
	);
}
