import { useContext, useState } from 'react';

import { CountrySearchT } from '../../types';
import Fuse from 'fuse.js';
import { HeadingXSmall } from 'baseui/typography';
import { LanguageContext } from '../../contexts/language/LanguageContext';
import { ListHeading } from 'baseui/list';
import { SearchCard } from '../common/SearchCard';
import { TreeView } from 'baseui/tree-view';
import { getLocationNodeId } from '../../utils';
import { useGetCountries } from '../../api';
import { useTranslation } from 'react-i18next';

export function LocationSearch({
	onCountrySelect,
}: {
	onCountrySelect: (cc: string) => void;
}) {
	const { t } = useTranslation();
	const { language } = useContext(LanguageContext);
	const [searchText, setSearchText] = useState('');
	const [foundCountries, setFoundCountries] =
		useState<Array<CountrySearchT> | null>(null);
	const { countriesLoading, countriesData } = useGetCountries({
		language,
	});
	const handleSearch = () => {
		const countriesSearch = new Fuse(countriesData, {
			keys: ['name'],
			minMatchCharLength: 3,
			findAllMatches: false,
			threshold: 0.2,
		});
		const countriesSearchRes = countriesSearch.search(searchText);
		const countriesRes = countriesSearchRes.map((val) => {
			const country = val.item;
			return {
				...country,
				label: country.name,
				type: 'country',
			};
		});
		setFoundCountries(countriesRes);
	};
	const renderFoundEntities = () => {
		if (foundCountries === null) return null;
		if (foundCountries?.length === 0)
			return <ListHeading heading={t('noCountriesFound')} />;
		return (
			<>
				<HeadingXSmall $style={{ marginTop: '0.5rem' }}>
					{t('results')}
				</HeadingXSmall>
				<TreeView
					getId={getLocationNodeId}
					// @ts-ignore
					data={foundCountries ?? []}
					onToggle={(node) => {
						onCountrySelect(node.code);
					}}
					overrides={{ TreeItem: { style: { cursor: 'pointer' } } }}
					// @ts-ignore
					style={{ cursor: 'pointer' }}
				/>
			</>
		);
	};
	return (
		<SearchCard
			searchText={searchText}
			setSearchText={setSearchText}
			handleSearch={handleSearch}
			renderFoundEntities={renderFoundEntities}
			isSearchButtonDisabled={countriesLoading}
		/>
	);
}
