import { DEFAULT_LANGUAGE, LANGUAGE_KEY } from './config/i18nConfig';
import { ERROR_COLOR, PRIMARY_COLOR } from './config/themeConfig';
import { MutableRefObject, SyntheticEvent } from 'react';

import { ALL_LANGUAGE_CODES } from './components/common/Language';
import { EMOJI_CODEPOINTS } from './constants';
import { TreeNodeData } from 'baseui/tree-view';
import get from 'lodash/get';
import { toaster } from 'baseui/toast';
import { v4 as uuid } from 'uuid';

export const scrollToRef = (ref: MutableRefObject<null>) =>
	// @ts-ignore
	ref?.current?.scrollIntoView({
		behavior: 'smooth',
		block: 'center',
	});

export const addIdToObject = (object: Object) => ({
	...object,
	id: uuid(),
});

export const getLocationNodeId = (node: TreeNodeData<any>) => node?.geonameid;

export const getEmoji = (key: string | null | undefined) => {
	const codepoint = get(EMOJI_CODEPOINTS, key ?? 0x2754, 0x2754);
	return String.fromCodePoint(codepoint);
};

export const sendPositiveNotification = (text: string) =>
	toaster.positive(text, {
		overrides: {
			Body: {
				style: ({ $theme }) => ({
					backgroundColor: PRIMARY_COLOR,
				}),
			},
		},
	});

export const sendNegativeNotification = (text: string) =>
	toaster.negative(text, {
		overrides: {
			Body: {
				style: ({ $theme }) => ({
					backgroundColor: ERROR_COLOR,
				}),
			},
		},
	});

export const onEnterPress = (
	event: SyntheticEvent,
	onEnterPress: () => void,
) => {
	// @ts-ignore
	if (event.key === 'Enter') {
		onEnterPress();
	}
};

export const getNumDaysBetween = (startDate: string, endDate: string) => {
	const startTime = new Date(startDate).getTime();
	const endTime = new Date(endDate).getTime();
	const timeDiff = Math.abs(startTime - endTime);
	return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
};

export const getDefaultLanguage = () =>
	ALL_LANGUAGE_CODES.find(
		(lng) =>
			lng === localStorage.getItem(LANGUAGE_KEY)?.replace(/['"]+/g, ''),
	) ??
	ALL_LANGUAGE_CODES.find(
		(lng) => lng === navigator.language.substring(0, 2),
	) ??
	DEFAULT_LANGUAGE;
