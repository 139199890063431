import {
	MapMarkerT,
	OverviewDataT,
	PlanDataT,
	PlanDayT,
	PlanT,
	TripT,
} from '../../types';

import { addIdToObject } from '../../utils';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import set from 'lodash/set';

export function addIdsToOverviewData(overviewData: OverviewDataT) {
	if (!overviewData) return null;
	const basedOnYourInterestsRes = {};
	overviewData?.basedOnYourInterests &&
		Object.entries(overviewData?.basedOnYourInterests).forEach(
			([key, value]) => {
				set(basedOnYourInterestsRes, key, value?.map(addIdToObject));
			},
		);
	return {
		topReasonsToVisit: overviewData?.topReasonsToVisit,
		topAttractions: overviewData?.topAttractions?.map(addIdToObject),
		topNeighborhoods: overviewData?.topNeighborhoods?.map(addIdToObject),
		basedOnYourInterests: basedOnYourInterestsRes,
	};
}

export function addIdsToPlanData(planData: PlanDataT) {
	if (!planData || Object.keys(planData).length < 1) return null;
	const plan = get(planData, 'plan');
	// @ts-ignore
	const planRes: PlanT = plan?.map((planDay: PlanDayT) => ({
		overview: get(planDay, 'overview'),
		meals: {
			breakfast: addIdToObject(get(planDay, ['meals', 'breakfast'])),
			lunch: addIdToObject(get(planDay, ['meals', 'lunch'])),
			dinner: addIdToObject(get(planDay, ['meals', 'dinner'])),
		},
		options: get(planDay, 'options')?.map(addIdToObject),
	}));
	return { plan: planRes };
}

export function getMarkers(trip: TripT) {
	const locationIdToMarkerNumber = {};
	const markers: Array<MapMarkerT> = flatten([
		flatten(
			get(trip, ['res', 'planData', 'plan'])?.map((planDay: PlanDayT) => [
				get(planDay, ['meals', 'breakfast']),
				get(planDay, ['options', 0]),
				get(planDay, ['meals', 'lunch']),
				get(planDay, ['options', 1]),
				get(planDay, ['meals', 'dinner']),
				get(planDay, ['options', 2]),
			]),
		),
		get(trip, ['res', 'overviewData', 'topAttractions']),
		get(trip, ['res', 'overviewData', 'topNeighborhoods']),
		flatten(
			Object.values(
				get(trip, ['res', 'overviewData', 'basedOnYourInterests']),
			),
		),
	]).map((location, index) => {
		const locationNumber = String(index + 1);
		location?.id &&
			set(locationIdToMarkerNumber, location.id, locationNumber);
		return {
			location,
			label: locationNumber,
		} as MapMarkerT;
	});
	return { markers, locationIdToMarkerNumber };
}
