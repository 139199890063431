import { ALL_LANGUAGE_CODES } from '../components/common/Language';

export const LANGUAGE_KEY = 'no-brain-language';

export const DEFAULT_LANGUAGE = 'en';

// Take the following object and translate the values into each of the following languages.
// Object: { ... }
// Language codes: 'es', 'zh', 'it', 'pt', 'de', 'ru', 'ja', 'fr', 'ar', 'fa', 'pl'

export const I18N_CONFIG = {
	resources: {
		en: {
			translation: {
				appName: 'No Brain Travel',
				noBrainTravel: 'No Brain Travel',
				saveTrip: 'Save your trip',
				viewTrip: 'View trip',
				yourTrips: 'Your trips',
				tripLogin: 'Log in to save your trip',
				requestSuccess: 'Request submitted successfully.',
				contactLogin: 'You must be logged in to send a contact request',
				send: 'send',
				contactUs: 'Contact Us',
				planAnotherTrip: 'Plan another trip',
				noTrips: 'You have not yet planned any trips',
				myTrips: 'My trips',
				planFirstTrip: 'Plan your first trip',
				topNeighborhoods: 'Top neighborhoods',
				countryError: 'There was an error looking up that country.',
				tripSaveSuccess: 'Trip saved successfully.',
				tripSaveError: 'Failed to save trip.',
				yourTripTo: 'Your personalized trip to ',
				loadMore: 'Load more',
				basedOnYourInterests: 'Based on your interests',
				topReasonsToVisit: 'Top reasons to visit',
				error: 'There was an error.',
				sports: 'Sports',
				sportingEvents: 'Sporting events',
				amusementParks: 'Amusement parks',
				movies: 'Movies',
				arcades: 'Arcades',
				bars: 'Bars',
				clubs: 'Clubs',
				events: 'Events',
				familyFriendly: 'Family friendly',
				parks: 'Parks and trails',
				cancelTrip: 'Cancel trip',
				tripCancelSuccess: 'Successfully cancelled your trip',
				tripCancelError: 'We were unable to cancel your trip',
				quickFactsAbout: 'Quick facts about',
				back: 'Back',
				loginRequired: 'You must be logged in to do that.',
				signInSuccess: 'Successfully signed in.',
				signInFailure:
					'Failed to sign in. Please enter a valid email and password.',
				createAccountSuccess: 'Successfully created account.',
				createAccountFailure:
					'Failed to create account. Please enter a valid email and password.',
				resetPasswordSuccess: 'Reset password email sent to ',
				resetPasswordFailure: 'Failed to reset the password for ',
				subscribeForMore: 'Subscribe to plan more trips.',
				loginToPlanTrip: 'You must be logged in to plan a trip.',
				onePlan: 'One plan, low price, unlimited trips.',
				subscribe: 'Subscribe now',
				subscriptions: 'Subscriptions',
				// Above this line has not yet been translated
				yourTrip: 'Your personalized trip',
				searchCountries: 'Search countries',
				planTrip: 'Plan a trip in minutes',
				noBrain: 'No brain? No problem.',
				discoverNew: 'Discover something new',
				where: 'Where do you want to go?',
				who: "Who's coming with you?",
				when: 'When do you want to go?',
				what: 'What would you like to do?',
				selectACity: 'Select a city',
				reset: 'Reset',
				travelingWithChildren: 'Traveling with children?',
				previous: 'Previous',
				next: 'Next',
				submit: 'Submit',
				of: 'of',
				solo: 'Solo',
				partner: 'Partner',
				friends: 'Friends',
				family: 'Family',
				yes: 'Yes',
				no: 'No',
				otherOptional: 'Other (optional)',
				dream: 'Anything you can dream of...',
				attractions: 'Famous attractions',
				food: 'Great food',
				beaches: 'Beaches',
				waterSports: 'Water sports',
				nightlife: 'Nightlife',
				park: 'Parks and trails',
				shopping: 'Shopping',
				history: 'History',
				culture: 'Culture',
				art: 'Arts and theatre',
				spa: 'Spa',
				tripError: 'There was an error plannning your trip.',
				topAttractions: 'Top Attractions',
				plan: 'Plan',
				whyVisit: 'Why visit',
				allRightsReserved: 'All rights reserved.',
				language: 'Language',
				share: 'Share',
				shareCta: 'Share by scanning with this QR code.',
				pageUnderContruction:
					'This page is currently under construction.',
				pageNotFound: 'Oops, page not found.',
				goHome: 'Go home',
				click: 'Click',
				hereLowercase: 'here',
				update: 'Update',
				guest: 'Guest',
				admin: 'Admin',
				explorer: 'Explorer',
				profile: 'Profile',
				name: 'Name',
				contact: 'Contact',
				darkMode: 'Dark Mode',
				email: 'Email',
				password: 'Password',
				login: 'Login',
				register: 'Register',
				logout: 'Logout',
				resetPassword: 'Reset Password',
				noAccount: "Don't have an account?",
				haveAccount: 'Already have an account?',
				rememberPassword: 'Remember your password?',
				forgotPassword: 'Forgot your password?',
				updateProfileFail: 'Failed to update profile.',
				updateProfileSuccess: 'Successfully updated profile.',
				logoutSuccess: 'Successfully logged out',
				logoutFail: 'Failed to logout. Please try again.',
				results: 'Results',
				overview: 'Overview',
				wikiPage: 'wikipedia page',
				map: 'Map',
				cities: 'Cities',
				languages: 'Languages',
				capital: 'Capital',
				continent: 'Continent',
				cityName: 'City Name',
				country: 'Country',
				population: 'Population',
				timezone: 'Timezone',
				currency: 'Currency',
				elevation: 'Elevation',
				explore: 'Explore',
				search: 'Search',
				start: 'Start',
				whereTo: 'Where to?',
				noCountriesFound: 'No countries found.',
				topDestinations: 'Top destinations for your next vacation.',
				poweredByAi: 'Powered by AI',
				beta: 'BETA',
				getPersonalized: 'Get a personalized trip tailored to you.',
				startTripWithAi: 'Start a trip with AI',
				planNow: 'Plan now',
				cityError: 'There was an error looking up that city.',
				bangkok: 'Bangkok',
				thailand: 'Thailand',
				bangkokDescription:
					'Known for its vibrant street life, ornate shrines, and the majestic Grand Palace, Bangkok attracts visitors for its unique blend of traditional culture and modern entertainment.',
				london: 'London',
				uk: 'United Kingdom',
				londonDescription:
					'The historical and cultural landmarks like the British Museum, Buckingham Palace, and the Tower of London draw visitors, alongside its diverse culinary scene and theatre district.',
				paris: 'Paris',
				france: 'France',
				parisDescription:
					'Renowned for its art, fashion, gastronomy, and culture, Paris is home to iconic landmarks such as the Eiffel Tower, Louvre Museum, and Notre-Dame Cathedral.',
				dubai: 'Dubai',
				uae: 'United Arab Emirates',
				dubaiDescription:
					"Famous for luxury shopping, ultramodern architecture, and a lively nightlife scene, Dubai's Burj Khalifa, the tallest building in the world, is a key attraction.",
				singapore: 'Singapore',
				singaporeDescription:
					'Known for its multicultural population, futuristic gardens, and unique attractions like Marina Bay Sands, Singapore offers a blend of culture, cuisine, and art.',
				nyc: 'New York City',
				usa: 'USA',
				nycDescription:
					"The city's iconic sites include Times Square, Broadway theaters, the Statue of Liberty, and Central Park, making it a hub of entertainment, fashion, and culture.",
				istanbul: 'Istanbul',
				turkey: 'Turkey',
				istanbulDescription:
					'Its rich history as a bridge between Europe and Asia is visible in its architecture, with the Hagia Sophia and Blue Mosque among its most famous landmarks.',
				tokyo: 'Tokyo',
				japan: 'Japan',
				tokyoDescription:
					'Known for its towering skyscrapers, bustling Shibuya Crossing, historic temples, and vibrant pop culture, Tokyo offers a unique mix of traditional and modern.',
				kualaLumpur: 'Kuala Lumpur',
				malaysia: 'Malaysia',
				kualaLumpurDescription:
					'The city is famed for its colonial architecture, friendly locals, bustling markets, and the iconic Petronas Twin Towers.',
				barcelona: 'Barcelona',
				spain: 'Spain',
				barcelonaDescription:
					"Renowned for its art and architecture, Barcelona is home to several of Antoni Gaudí's works, including the Sagrada Família church.",
				amsterdam: 'Amsterdam',
				netherlands: 'Netherlands',
				amsterdamDescription:
					'Known for its artistic heritage, elaborate canal system, and narrow houses with gabled facades, the Van Gogh Museum and Rijksmuseum are major draws.',
				rome: 'Rome',
				italy: 'Italy',
				romeDescription:
					"The city's rich history as the former capital of the Roman Empire attracts visitors to the Colosseum, Roman Forum, and Vatican City.",
				osaka: 'Osaka',
				osakaDescription:
					'Known for its modern architecture, nightlife, and hearty street food, the Osaka Castle is a key landmark.',
				milan: 'Milan',
				milanDescription:
					'A global capital of fashion and design, Milan is home to the Gothic Duomo di Milano cathedral and the Santa Maria delle Grazie convent, housing Leonardo da Vinci\'s "The Last Supper."',
				vienna: 'Vienna',
				austria: 'Austria',
				viennaDescription:
					'Known for its artistic and intellectual legacy shaped by residents like Mozart, Beethoven, and Sigmund Freud, the city is also home to the baroque Schönbrunn Palace.',
				shanghai: 'Shanghai',
				china: 'China',
				shanghaiDescription:
					'Known for its Lujiazui skyline, historic Bund, bustling shopping streets, and the expansive Yu Garden, Shanghai melds tradition with cutting-edge development.',
				prague: 'Prague',
				czechRepublic: 'Czech Republic',
				pragueDescription:
					'Famous for its Old Town Square, colorful baroque buildings, Gothic churches, and the medieval Astronomical Clock, Prague has a rich history and vibrant cultural scene.',
				losAngeles: 'Los Angeles',
				losAngelesDescription:
					'Known for Hollywood, expansive beaches, and the entertainment industry, the Hollywood Sign and Griffith Observatory are among its most visited sites.',
			},
		},
		es: {
			translation: {
				yourTrip: 'Tu viaje personalizado',
				back: 'atrás',
				searchCountries: 'Buscar países',
				planTrip: 'Planifica un viaje en minutos',
				noBrain: '¿Sin cerebro? ¡No hay problema!',
				discoverNew: 'Descubre algo nuevo',
				where: '¿A dónde quieres ir?',
				who: '¿Quién viene contigo?',
				when: '¿Cuándo quieres ir?',
				what: '¿Qué te gustaría hacer?',
				selectACity: 'Selecciona una ciudad',
				reset: 'Restablecer',
				travelingWithChildren: '¿Viajando con niños?',
				previous: 'Anterior',
				next: 'Siguiente',
				submit: 'Enviar',
				of: 'de',
				solo: 'Solo',
				partner: 'Compañero',
				friends: 'Amigos',
				family: 'Familia',
				yes: 'Sí',
				no: 'No',
				otherOptional: 'Otro (opcional)',
				dream: 'Cualquier cosa que puedas soñar...',
				attractions: 'Atracciones famosas',
				food: 'Comida excelente',
				beaches: 'Playas',
				waterSports: 'Deportes acuáticos',
				nightlife: 'Vida nocturna',
				park: 'Parques y senderos',
				shopping: 'Compras',
				history: 'Historia',
				culture: 'Cultura',
				art: 'Artes y teatro',
				spa: 'Spa',
				tripError: 'Hubo un error al planificar tu viaje.',
				tripLogin: 'Debes iniciar sesión para guardar tu viaje.',
				topAttractions: 'Atracciones principales',
				basedOnInterests: 'Basado en tus intereses',
				plan: 'Planificar',
				whyVisit: '¿Por qué visitar',
				appName: 'Explorador de Ciudades',
				allRightsReserved: 'Todos los derechos reservados',
				share: 'Compartir',
				shareCta: 'Compartir escaneando este código QR.',
				pageUnderContruction:
					'Esta página está actualmente en construcción.',
				pageNotFound: 'Ups, página no encontrada.',
				goHome: 'Ir a casa',
				click: 'Clic',
				hereLowercase: 'aquí',
				update: 'Actualizar',
				guest: 'Invitado',
				admin: 'Administrador',
				explorer: 'Explorador',
				profile: 'Perfil',
				name: 'Nombre',
				contact: 'Contacto',
				darkMode: 'Modo Oscuro',
				email: 'Correo electrónico',
				password: 'Contraseña',
				login: 'Iniciar sesión',
				register: 'Registrarse',
				logout: 'Cerrar sesión',
				resetPassword: 'Restablecer contraseña',
				noAccount: '¿No tienes una cuenta?',
				haveAccount: '¿Ya tienes una cuenta?',
				rememberPassword: '¿Recuerda tu contraseña?',
				forgotPassword: '¿Olvidaste tu contraseña?',
				updateProfileFail: 'Error al actualizar perfil.',
				updateProfileSuccess: 'Perfil actualizado exitosamente.',
				logoutSuccess: 'Cierre de sesión exitoso',
				logoutFail:
					'Error al cerrar sesión. Por favor, inténtalo de nuevo.',
				results: 'Resultados',
				overview: 'Resumen',
				wikiPage: 'Página de Wikipedia',
				map: 'Mapa',
				cities: 'Ciudades',
				languages: 'Idiomas',
				capital: 'Capital',
				continent: 'Continente',
				cityName: 'Nombre de la Ciudad',
				country: 'País',
				population: 'Población',
				timezone: 'Zona horaria',
				currency: 'Moneda',
				elevation: 'Elevación',
				explore: 'Explorar',
				search: 'Buscar',
				whereTo: '¿Adónde?',
				noCountriesFound: 'No se encontraron países.',
				topDestinations:
					'Principales destinos para tus próximas vacaciones.',
				poweredByAi: 'Alimentado por IA',
				beta: 'BETA',
				buildTrip: 'Arma un viaje en minutos',
				getPersonalized: 'Obtén un viaje personalizado a tu medida.',
				startTripWithAi: 'Comienza un viaje con IA',
				cityError: 'Hubo un error al buscar esa ciudad.',
				bangkok: 'Bangkok',
				thailand: 'Tailandia',
				bangkokDescription:
					'Conocida por su vibrante vida callejera, santuarios ornamentados y el majestuoso Gran Palacio, Bangkok atrae a visitantes por su singular combinación de cultura tradicional y entretenimiento moderno.',
				london: 'Londres',
				uk: 'Reino Unido',
				londonDescription:
					'Los hitos históricos y culturales como el Museo Británico, el Palacio de Buckingham y la Torre de Londres atraen a visitantes, junto con su diversa escena culinaria y distrito teatral.',
				paris: 'París',
				france: 'Francia',
				parisDescription:
					'Renombrada por su arte, moda, gastronomía y cultura, París es hogar de hitos icónicos como la Torre Eiffel, el Museo del Louvre y la Catedral de Notre-Dame.',
				dubai: 'Dubái',
				uae: 'Emiratos Árabes Unidos',
				dubaiDescription:
					'Famosa por sus lujosas compras, arquitectura ultramoderna y una animada vida nocturna, el Burj Khalifa de Dubái, el edificio más alto del mundo, es una atracción clave.',
				singapore: 'Singapur',
				singaporeDescription:
					'Conocida por su población multicultural, jardines futuristas y atracciones únicas como Marina Bay Sands, Singapur ofrece una mezcla de cultura, cocina y arte.',
				nyc: 'Nueva York',
				usa: 'Estados Unidos',
				nycDescription:
					'Los sitios icónicos de la ciudad incluyen Times Square, los teatros de Broadway, la Estatua de la Libertad y Central Park, lo que la convierte en un centro de entretenimiento, moda y cultura.',
				istanbul: 'Estambul',
				turkey: 'Turquía',
				istanbulDescription:
					'Su rica historia como puente entre Europa y Asia es visible en su arquitectura, con la Hagia Sophia y la Mezquita Azul entre sus hitos más famosos.',
				tokyo: 'Tokio',
				japan: 'Japón',
				tokyoDescription:
					'Conocida por sus rascacielos imponentes, el bullicioso Cruce de Shibuya, templos históricos y vibrante cultura pop, Tokio ofrece una mezcla única de lo tradicional y lo moderno.',
				kualaLumpur: 'Kuala Lumpur',
				malaysia: 'Malasia',
				kualaLumpurDescription:
					'La ciudad es famosa por su arquitectura colonial, locales amigables, mercados bulliciosos y las icónicas Torres Gemelas Petronas.',
				barcelona: 'Barcelona',
				spain: 'España',
				barcelonaDescription:
					'Renombrada por su arte y arquitectura, Barcelona es hogar de varias obras de Antoni Gaudí, incluida la iglesia de la Sagrada Familia.',
				amsterdam: 'Ámsterdam',
				netherlands: 'Países Bajos',
				amsterdamDescription:
					'Conocida por su patrimonio artístico, elaborado sistema de canales y estrechas casas con fachadas con frontones, el Museo Van Gogh y el Rijksmuseum son atracciones principales.',
				rome: 'Roma',
				italy: 'Italia',
				romeDescription:
					'La rica historia de la ciudad como antigua capital del Imperio Romano atrae a visitantes al Coliseo, el Foro Romano y la Ciudad del Vaticano.',
				osaka: 'Osaka',
				osakaDescription:
					'Conocida por su arquitectura moderna, vida nocturna y abundante comida callejera, el Castillo de Osaka es un punto de referencia clave.',
				milan: 'Milán',
				milanDescription:
					'Capital mundial de la moda y el diseño, Milán es hogar de la catedral gótica Duomo di Milano y el convento de Santa Maria delle Grazie, que alberga "La última cena" de Leonardo da Vinci.',
				vienna: 'Viena',
				austria: 'Austria',
				viennaDescription:
					'Conocida por su legado artístico e intelectual moldeado por residentes como Mozart, Beethoven y Sigmund Freud, la ciudad también alberga el palacio barroco Schönbrunn.',
				shanghai: 'Shanghái',
				china: 'China',
				shanghaiDescription:
					'Conocida por su horizonte de Lujiazui, el histórico Bund, bulliciosas calles comerciales y el extenso Jardín Yu, Shanghái combina la tradición con el desarrollo de vanguardia.',
				prague: 'Praga',
				czechRepublic: 'República Checa',
				pragueDescription:
					'Famosa por su Plaza de la Ciudad Vieja, edificios barrocos de colores, iglesias góticas y el reloj astronómico medieval, Praga tiene una rica historia y una escena cultural vibrante.',
				losAngeles: 'Los Ángeles',
				losAngelesDescription:
					'Conocida por Hollywood, playas extensas y la industria del entretenimiento, el letrero de Hollywood y el Observatorio Griffith están entre sus sitios más visitados.',
			},
		},
		zh: {
			translation: {
				yourTrip: '您的个性化行程',
				back: '返回',
				searchCountries: '搜索国家',
				planTrip: '几分钟内计划旅行',
				noBrain: '没大脑？没问题。',
				discoverNew: '发现新事物',
				where: '你想去哪里？',
				who: '谁和你一起来？',
				when: '你想什么时候去？',
				what: '你想做什么？',
				selectACity: '选择一个城市',
				reset: '重置',
				travelingWithChildren: '带孩子旅行？',
				previous: '上一页',
				next: '下一页',
				submit: '提交',
				of: '的',
				solo: '单独',
				partner: '伴侣',
				friends: '朋友',
				family: '家人',
				yes: '是',
				no: '不',
				otherOptional: '其他（可选）',
				dream: '你能梦到的任何事情...',
				attractions: '著名景点',
				food: '美食',
				beaches: '海滩',
				waterSports: '水上运动',
				nightlife: '夜生活',
				park: '公园和步道',
				shopping: '购物',
				history: '历史',
				culture: '文化',
				art: '艺术和戏剧',
				spa: '水疗',
				tripError: '计划旅行时出错。',
				tripLogin: '您必须登录才能保存您的行程。',
				topAttractions: '热门景点',
				basedOnInterests: '根据您的兴趣',
				plan: '计划',
				whyVisit: '为什么要访问',
				appName: '城市爬虫',
				allRightsReserved: '保留所有权利',
				share: '分享',
				shareCta: '通过扫描此二维码分享。',
				pageUnderContruction: '此页面目前正在建设中。',
				pageNotFound: '糟糕，页面未找到。',
				goHome: '回家',
				click: '点击',
				hereLowercase: '这里',
				update: '更新',
				guest: '访客',
				admin: '管理员',
				explorer: '探险者',
				profile: '个人资料',
				name: '姓名',
				contact: '联系方式',
				darkMode: '暗模式',
				email: '邮箱',
				password: '密码',
				login: '登录',
				register: '注册',
				logout: '登出',
				resetPassword: '重设密码',
				noAccount: '还没有账号？',
				haveAccount: '已有账号？',
				rememberPassword: '记住密码？',
				forgotPassword: '忘记密码？',
				updateProfileFail: '个人资料更新失败。',
				updateProfileSuccess: '个人资料更新成功。',
				logoutSuccess: '成功登出',
				logoutFail: '登出失败，请重试。',
				results: '结果',
				overview: '概述',
				wikiPage: '维基页面',
				map: '地图',
				cities: '城市',
				languages: '语言',
				capital: '首都',
				continent: '大洲',
				cityName: '城市名称',
				country: '国家',
				population: '人口',
				timezone: '时区',
				currency: '货币',
				elevation: '海拔',
				explore: '探索',
				search: '搜索',
				whereTo: '去哪里？',
				noCountriesFound: '未找到国家。',
				topDestinations: '下一个度假的热门目的地。',
				poweredByAi: '由人工智能驱动',
				beta: '测试版',
				buildTrip: '在几分钟内规划旅行',
				getPersonalized: '获得个性化的旅行建议。',
				startTripWithAi: '开始与AI的旅行',
				cityError: '查找该城市时出错。',
				bangkok: '曼谷',
				thailand: '泰国',
				bangkokDescription:
					'以其充满活力的街头生活、华丽的神龛和壮丽的大皇宫而闻名，曼谷因其独特的传统文化和现代娱乐而吸引游客。',
				london: '伦敦',
				uk: '英国',
				londonDescription:
					'像大英博物馆、白金汉宫和伦敦塔等历史和文化地标吸引着游客，同时其多样化的美食场所和剧院区也备受瞩目。',
				paris: '巴黎',
				france: '法国',
				parisDescription:
					'以其艺术、时尚、美食和文化而闻名，巴黎是埃菲尔铁塔、卢浮宫和巴黎圣母院等标志性地标的所在地。',
				dubai: '迪拜',
				uae: '阿联酋',
				dubaiDescription:
					'以奢侈购物、超现代建筑和繁华的夜生活而闻名，迪拜的哈利法塔是世界最高建筑之一，是主要景点之一。',
				singapore: '新加坡',
				singaporeDescription:
					'以其多元文化人口、未来花园和滨海湾金沙等独特景点而闻名，新加坡融合了文化、美食和艺术。',
				nyc: '纽约市',
				usa: '美国',
				nycDescription:
					'该市的标志性景点包括时代广场、百老汇剧院、自由女神像和中央公园，使其成为娱乐、时尚和文化的中心。',
				istanbul: '伊斯坦布尔',
				turkey: '土耳其',
				istanbulDescription:
					'伊斯坦布尔作为欧亚大陆的桥梁，其丰富的历史可见于其建筑，其中圣索菲亚大教堂和蓝色清真寺是最著名的地标之一。',
				tokyo: '东京',
				japan: '日本',
				tokyoDescription:
					'以其高耸的摩天大楼、繁华的涩谷十字路口、历史悠久的寺庙和充满活力的流行文化而闻名，东京提供了传统与现代的独特结合。',
				kualaLumpur: '吉隆坡',
				malaysia: '马来西亚',
				kualaLumpurDescription:
					'该城市以其殖民时期的建筑、友好的当地人、繁华的市场和标志性的双子塔而闻名。',
				barcelona: '巴塞罗那',
				spain: '西班牙',
				barcelonaDescription:
					'以其艺术和建筑而闻名，巴塞罗那拥有安东尼·高迪的几座作品，包括圣家堂。',
				amsterdam: '阿姆斯特丹',
				netherlands: '荷兰',
				amsterdamDescription:
					'以其艺术遗产、精美的运河系统和带有山形立面的狭窄房屋而闻名，梵高博物馆和国立博物馆是主要景点。',
				rome: '罗马',
				italy: '意大利',
				romeDescription:
					'作为罗马帝国的前首都，该城市的丰富历史吸引着游客前往参观斗兽场、罗马广场和梵蒂冈城。',
				osaka: '大阪',
				osakaDescription:
					'以其现代建筑、夜生活和丰盛的街头美食而闻名，大阪城是其的一个关键地标。',
				milan: '米兰',
				milanDescription:
					'作为时尚和设计的全球之都，米兰拥有哥特式的米兰大教堂和圣玛利亚德拉格拉齐大教堂，收藏了达芬奇的《最后的晚餐》。',
				vienna: '维也纳',
				austria: '奥地利',
				viennaDescription:
					'以莫扎特、贝多芬和西格蒙德·弗洛伊德等居民塑造的艺术和智力遗产而闻名，这座城市还拥有巴洛克风格的舍恩布伦宫。',
				shanghai: '上海',
				china: '中国',
				shanghaiDescription:
					'以陆家嘴的天际线、历史悠久的外滩、繁华的购物街和庞大的豫园而闻名，上海融合了传统与前沿的发展。',
				prague: '布拉格',
				czechRepublic: '捷克共和国',
				pragueDescription:
					'以其老城广场、色彩斑斓的巴洛克建筑、哥特式教堂和中世纪天文钟而闻名，布拉格拥有丰富的历史和充满活力的文化场景。',
				losAngeles: '洛杉矶',
				losAngelesDescription:
					'以好莱坞、广阔的海滩和娱乐产业而闻名，好莱坞标志和格里菲斯天文台是其中最受欢迎的景点之一。',
			},
		},
		it: {
			translation: {
				yourTrip: 'Il tuo viaggio personalizzato',
				back: 'indietro',
				searchCountries: 'Cerca paesi',
				planTrip: 'Pianifica un viaggio in pochi minuti',
				noBrain: 'Nessun cervello? Nessun problema.',
				discoverNew: 'Scopri qualcosa di nuovo',
				where: 'Dove vuoi andare?',
				who: 'Chi viene con te?',
				when: 'Quando vuoi andare?',
				what: 'Cosa ti piacerebbe fare?',
				selectACity: 'Seleziona una città',
				reset: 'Reset',
				travelingWithChildren: 'Viaggi con bambini?',
				previous: 'Precedente',
				next: 'Avanti',
				submit: 'Invia',
				of: 'di',
				solo: 'Solo',
				partner: 'Partner',
				friends: 'Amici',
				family: 'Famiglia',
				yes: 'Sì',
				no: 'No',
				otherOptional: 'Altro (opzionale)',
				dream: 'Qualsiasi cosa tu possa sognare...',
				attractions: 'Attrazioni famose',
				food: 'Cibo eccellente',
				beaches: 'Spiagge',
				waterSports: 'Sport acquatici',
				nightlife: 'Vita notturna',
				park: 'Parchi e sentieri',
				shopping: 'Shopping',
				history: 'Storia',
				culture: 'Cultura',
				art: 'Arte e teatro',
				spa: 'Spa',
				tripError:
					'Si è verificato un errore durante la pianificazione del viaggio.',
				tripLogin: 'Devi accedere per salvare il tuo viaggio.',
				topAttractions: 'Attrazioni principali',
				basedOnInterests: 'In base ai tuoi interessi',
				plan: 'Pianifica',
				whyVisit: 'Perché visitare',
				appName: 'Esploratore di città',
				allRightsReserved: 'Tutti i diritti riservati',
				share: 'Condividi',
				shareCta: 'Condividi scansionando questo codice QR.',
				pageUnderContruction:
					'Questa pagina è attualmente in costruzione.',
				pageNotFound: 'Ops, pagina non trovata.',
				goHome: 'Torna a casa',
				click: 'Clicca',
				hereLowercase: 'qui',
				update: 'Aggiorna',
				guest: 'Ospite',
				admin: 'Amministratore',
				explorer: 'Esploratore',
				profile: 'Profilo',
				name: 'Nome',
				contact: 'Contatto',
				darkMode: 'Modalità scura',
				email: 'Email',
				password: 'Password',
				login: 'Accesso',
				register: 'Registrati',
				logout: 'Disconnetti',
				resetPassword: 'Resetta password',
				noAccount: 'Non hai un account?',
				haveAccount: 'Hai già un account?',
				rememberPassword: 'Ricorda la tua password?',
				forgotPassword: 'Hai dimenticato la tua password?',
				updateProfileFail: 'Aggiornamento del profilo non riuscito.',
				updateProfileSuccess: 'Profilo aggiornato con successo.',
				logoutSuccess: 'Disconnessione avvenuta con successo',
				logoutFail: 'Disconnessione non riuscita. Per favore, riprova.',
				results: 'Risultati',
				overview: 'Panoramica',
				wikiPage: 'Pagina Wikipedia',
				map: 'Mappa',
				cities: 'Città',
				languages: 'Lingue',
				capital: 'Capitale',
				continent: 'Continente',
				cityName: 'Nome della città',
				country: 'Paese',
				population: 'Popolazione',
				timezone: 'Fuso orario',
				currency: 'Valuta',
				elevation: 'Elevazione',
				explore: 'Esplora',
				search: 'Cerca',
				whereTo: 'Dove?',
				noCountriesFound: 'Nessun paese trovato.',
				topDestinations:
					'Principali destinazioni per la tua prossima vacanza.',
				poweredByAi: 'Alimentato da IA',
				beta: 'BETA',
				buildTrip: 'Costruisci un viaggio in pochi minuti',
				getPersonalized:
					'Ottieni un viaggio personalizzato su misura per te.',
				startTripWithAi: "Inizia un viaggio con l'IA",
				cityError:
					'Si è verificato un errore durante la ricerca di quella città.',
				bangkok: 'Bangkok',
				thailand: 'Thailandia',
				bangkokDescription:
					'Conosciuta per la sua vivace vita da strada, i sontuosi santuari e il maestoso Grand Palace, Bangkok attira i visitatori per la sua unica miscela di cultura tradizionale e intrattenimento moderno.',
				london: 'Londra',
				uk: 'Regno Unito',
				londonDescription:
					'I luoghi storici e culturali come il British Museum, Buckingham Palace e la Tower of London attirano i visitatori, insieme alla sua variegata scena culinaria e al distretto teatrale.',
				paris: 'Parigi',
				france: 'Francia',
				parisDescription:
					'Famosa per arte, moda, gastronomia e cultura, Parigi è sede di landmark iconici come la Torre Eiffel, il Museo del Louvre e la Cattedrale di Notre-Dame.',
				dubai: 'Dubai',
				uae: 'Emirati Arabi Uniti',
				dubaiDescription:
					"Famosa per lo shopping di lusso, l'architettura ultramoderna e una vivace scena notturna, il Burj Khalifa di Dubai, il più alto edificio del mondo, è una attrazione chiave.",
				singapore: 'Singapore',
				singaporeDescription:
					'Conosciuta per la sua popolazione multiculturale, i giardini futuristici e le attrazioni uniche come Marina Bay Sands, Singapore offre una miscela di cultura, cucina e arte.',
				nyc: 'New York City',
				usa: 'Stati Uniti',
				nycDescription:
					'I luoghi iconici della città includono Times Square, i teatri di Broadway, la Statua della Libertà e Central Park, rendendola un polo di intrattenimento, moda e cultura.',
				istanbul: 'Istanbul',
				turkey: 'Turchia',
				istanbulDescription:
					'La sua ricca storia come ponte tra Europa e Asia è visibile nella sua architettura, con la Basilica di Santa Sofia e la Moschea Blu tra i suoi landmark più famosi.',
				tokyo: 'Tokyo',
				japan: 'Giappone',
				tokyoDescription:
					'Conosciuta per i suoi grattacieli imponenti, il vivace Shibuya Crossing, i templi storici e la vibrante cultura pop, Tokyo offre una miscela unica di tradizionale e moderno.',
				kualaLumpur: 'Kuala Lumpur',
				malaysia: 'Malaysia',
				kualaLumpurDescription:
					'La città è famosa per la sua architettura coloniale, i locali amichevoli, i mercati frenetici e le iconiche Petronas Twin Towers.',
				barcelona: 'Barcellona',
				spain: 'Spagna',
				barcelonaDescription:
					'Famosa per arte e architettura, Barcellona è sede di diverse opere di Antoni Gaudí, tra cui la chiesa della Sagrada Família.',
				amsterdam: 'Amsterdam',
				netherlands: 'Paesi Bassi',
				amsterdamDescription:
					'Conosciuta per il suo patrimonio artistico, il sistema di canali elaborato e le case strette con facciate a gradoni, il Museo Van Gogh e il Rijksmuseum sono le principali attrazioni.',
				rome: 'Roma',
				italy: 'Italia',
				romeDescription:
					"La ricca storia della città come ex capitale dell'Impero Romano attira visitatori al Colosseo, al Foro Romano e alla Città del Vaticano.",
				osaka: 'Osaka',
				osakaDescription:
					'Conosciuta per la sua architettura moderna, la vita notturna e il cuore di cibo di strada, il Castello di Osaka è un landmark chiave.',
				milan: 'Milano',
				milanDescription:
					'Capitale mondiale della moda e del design, Milano è sede del Duomo di Milano in stile gotico e del convento di Santa Maria delle Grazie, che ospita "L\'Ultima Cena" di Leonardo da Vinci.',
				vienna: 'Vienna',
				austria: 'Austria',
				viennaDescription:
					'Conosciuta per il suo lascito artistico e intellettuale plasmato da residenti come Mozart, Beethoven e Sigmund Freud, la città è anche sede del barocco Palazzo Schönbrunn.',
				shanghai: 'Shanghai',
				china: 'Cina',
				shanghaiDescription:
					"Conosciuta per il suo skyline di Lujiazui, il Bund storico, le affollate strade dello shopping e il vasto Giardino Yu, Shanghai fonde tradizione e sviluppo all'avanguardia.",
				prague: 'Praga',
				czechRepublic: 'Repubblica Ceca',
				pragueDescription:
					"Famosa per la sua Piazza della Città Vecchia, gli edifici barocchi colorati, le chiese gotiche e l'orologio astronomico medievale, Praga ha una ricca storia e una vivace scena culturale.",
				losAngeles: 'Los Angeles',
				losAngelesDescription:
					"Conosciuta per Hollywood, le spiagge ampie e l'industria dell'intrattenimento, il cartello di Hollywood e l'osservatorio di Griffith sono tra i suoi siti più visitati.",
			},
		},
		pt: {
			translation: {
				yourTrip: 'Sua viagem personalizada',
				back: 'voltar',
				searchCountries: 'Pesquisar países',
				planTrip: 'Planeje uma viagem em minutos',
				noBrain: 'Sem cérebro? Sem problemas.',
				discoverNew: 'Descubra algo novo',
				where: 'Para onde você quer ir?',
				who: 'Quem vai com você?',
				when: 'Quando você quer ir?',
				what: 'O que você gostaria de fazer?',
				selectACity: 'Selecione uma cidade',
				reset: 'Resetar',
				travelingWithChildren: 'Viajando com crianças?',
				previous: 'Anterior',
				next: 'Próximo',
				submit: 'Enviar',
				of: 'de',
				solo: 'Sozinho',
				partner: 'Parceiro',
				friends: 'Amigos',
				family: 'Família',
				yes: 'Sim',
				no: 'Não',
				otherOptional: 'Outro (opcional)',
				dream: 'Tudo o que você pode sonhar...',
				attractions: 'Atrações famosas',
				food: 'Comida ótima',
				beaches: 'Praias',
				waterSports: 'Esportes aquáticos',
				nightlife: 'Vida noturna',
				park: 'Parques e trilhas',
				shopping: 'Compras',
				history: 'História',
				culture: 'Cultura',
				art: 'Artes e teatro',
				spa: 'Spa',
				tripError: 'Houve um erro ao planejar sua viagem.',
				tripLogin: 'Você deve estar logado para salvar sua viagem.',
				topAttractions: 'Principais Atrações',
				basedOnInterests: 'Com base nos seus interesses',
				plan: 'Planejar',
				whyVisit: 'Por que visitar',
				appName: 'Explorador de Cidades',
				allRightsReserved: 'Todos os direitos reservados',
				share: 'Compartilhar',
				shareCta: 'Compartilhar escaneando este código QR.',
				pageUnderContruction:
					'Esta página está atualmente em construção.',
				pageNotFound: 'Ops, página não encontrada.',
				goHome: 'Ir para casa',
				click: 'Clique',
				hereLowercase: 'aqui',
				update: 'Atualizar',
				guest: 'Visitante',
				admin: 'Administrador',
				explorer: 'Explorador',
				profile: 'Perfil',
				name: 'Nome',
				contact: 'Contato',
				darkMode: 'Modo Escuro',
				email: 'Email',
				password: 'Senha',
				login: 'Entrar',
				register: 'Registrar',
				logout: 'Sair',
				resetPassword: 'Redefinir Senha',
				noAccount: 'Não tem uma conta?',
				haveAccount: 'Já tem uma conta?',
				rememberPassword: 'Lembrar sua senha?',
				forgotPassword: 'Esqueceu sua senha?',
				updateProfileFail: 'Falha ao atualizar o perfil.',
				updateProfileSuccess: 'Perfil atualizado com sucesso.',
				logoutSuccess: 'Desconectado com sucesso',
				logoutFail: 'Falha ao sair. Por favor, tente novamente.',
				results: 'Resultados',
				overview: 'Visão geral',
				wikiPage: 'Página da Wikipedia',
				map: 'Mapa',
				cities: 'Cidades',
				languages: 'Idiomas',
				capital: 'Capital',
				continent: 'Continente',
				cityName: 'Nome da Cidade',
				country: 'País',
				population: 'População',
				timezone: 'Fuso Horário',
				currency: 'Moeda',
				elevation: 'Elevação',
				explore: 'Explorar',
				search: 'Pesquisar',
				whereTo: 'Onde?',
				noCountriesFound: 'Nenhum país encontrado.',
				topDestinations:
					'Principais destinos para suas próximas férias.',
				poweredByAi: 'Alimentado por IA',
				beta: 'BETA',
				buildTrip: 'Monte uma viagem em minutos',
				getPersonalized:
					'Obtenha uma viagem personalizada feita para você.',
				startTripWithAi: 'Inicie uma viagem com IA',
				cityError: 'Houve um erro ao procurar por essa cidade.',
				bangkok: 'Bangkok',
				thailand: 'Tailândia',
				bangkokDescription:
					'Conhecida por sua vibrante vida de rua, templos ornamentados e o majestoso Grande Palácio, Bangkok atrai visitantes por sua mistura única de cultura tradicional e entretenimento moderno.',

				london: 'Londres',
				uk: 'Reino Unido',
				londonDescription:
					'Os marcos históricos e culturais como o Museu Britânico, o Palácio de Buckingham e a Torre de Londres atraem visitantes, juntamente com sua diversificada cena culinária e distrito teatral.',

				paris: 'Paris',
				france: 'França',
				parisDescription:
					'Renomada por sua arte, moda, gastronomia e cultura, Paris é lar de pontos de referência icônicos como a Torre Eiffel, o Museu do Louvre e a Catedral de Notre-Dame.',

				dubai: 'Dubai',
				uae: 'Emirados Árabes Unidos',
				dubaiDescription:
					'Famosa por compras de luxo, arquitetura ultramoderna e uma animada cena noturna, o Burj Khalifa de Dubai, o edifício mais alto do mundo, é uma atração-chave.',

				singapore: 'Cingapura',
				singaporeDescription:
					'Conhecida por sua população multicultural, jardins futuristas e atrações únicas como Marina Bay Sands, Cingapura oferece uma mistura de cultura, culinária e arte.',

				nyc: 'Cidade de Nova York',
				usa: 'EUA',
				nycDescription:
					'Os locais icônicos da cidade incluem Times Square, teatros da Broadway, a Estátua da Liberdade e o Central Park, tornando-a um polo de entretenimento, moda e cultura.',

				istanbul: 'Istambul',
				turkey: 'Turquia',
				istanbulDescription:
					'Sua rica história como ponte entre a Europa e a Ásia é visível em sua arquitetura, com a Basílica de Santa Sofia e a Mesquita Azul entre seus marcos mais famosos.',

				tokyo: 'Tóquio',
				japan: 'Japão',
				tokyoDescription:
					'Conhecida por seus arranha-céus imponentes, movimentado Cruzamento Shibuya, templos históricos e vibrante cultura pop, Tóquio oferece uma mistura única de tradicional e moderno.',

				kualaLumpur: 'Kuala Lumpur',
				malaysia: 'Malásia',
				kualaLumpurDescription:
					'A cidade é famosa por sua arquitetura colonial, habitantes amigáveis, mercados movimentados e as icônicas Petronas Twin Towers.',

				barcelona: 'Barcelona',
				spain: 'Espanha',
				barcelonaDescription:
					'Renomada por sua arte e arquitetura, Barcelona é lar de várias obras de Antoni Gaudí, incluindo a igreja da Sagrada Família.',

				amsterdam: 'Amsterdã',
				netherlands: 'Países Baixos',
				amsterdamDescription:
					'Conhecida por seu patrimônio artístico, sistema de canais elaborado e casas estreitas com fachadas em gablete, o Museu Van Gogh e o Rijksmuseum são grandes atrações.',

				rome: 'Roma',
				italy: 'Itália',
				romeDescription:
					'A rica história da cidade como antiga capital do Império Romano atrai visitantes para o Coliseu, Fórum Romano e Cidade do Vaticano.',

				osaka: 'Osaka',
				osakaDescription:
					'Conhecida por sua arquitetura moderna, vida noturna e comida de rua abundante, o Castelo de Osaka é um marco importante.',

				milan: 'Milão',
				milanDescription:
					'Capital global da moda e do design, Milão é lar da catedral gótica Duomo di Milano e do convento Santa Maria delle Grazie, que abriga "A Última Ceia" de Leonardo da Vinci.',

				vienna: 'Viena',
				austria: 'Áustria',
				viennaDescription:
					'Conhecida por seu legado artístico e intelectual moldado por residentes como Mozart, Beethoven e Sigmund Freud, a cidade também é lar do barroco Palácio Schönbrunn.',

				shanghai: 'Xangai',
				china: 'China',
				shanghaiDescription:
					'Conhecida por seu skyline de Lujiazui, o histórico Bund, movimentadas ruas comerciais e o expansivo Jardim Yu, Xangai mescla tradição com desenvolvimento de ponta.',

				prague: 'Praga',
				czechRepublic: 'República Tcheca',
				pragueDescription:
					'Famosa por sua Praça da Cidade Velha, edifícios barrocos coloridos, igrejas góticas e o Relógio Astronômico medieval, Praga tem uma rica história e uma vibrante cena cultural.',

				losAngeles: 'Los Angeles',
				losAngelesDescription:
					'Conhecida por Hollywood, praias extensas e pela indústria do entretenimento, o letreiro de Hollywood e o Observatório Griffith estão entre seus locais mais visitados.',
			},
		},
		de: {
			translation: {
				yourTrip: 'Ihre individuelle Reise',
				back: 'zurück',
				searchCountries: 'Länder suchen',
				planTrip: 'Planen Sie eine Reise in Minuten',
				noBrain: 'Kein Gehirn? Kein Problem.',
				discoverNew: 'Etwas Neues entdecken',
				where: 'Wo möchtest du hin?',
				who: 'Wer kommt mit dir?',
				when: 'Wann möchtest du gehen?',
				what: 'Was möchtest du tun?',
				selectACity: 'Stadt auswählen',
				reset: 'Zurücksetzen',
				travelingWithChildren: 'Reisen Sie mit Kindern?',
				previous: 'Zurück',
				next: 'Weiter',
				submit: 'Senden',
				of: 'von',
				solo: 'Allein',
				partner: 'Partner',
				friends: 'Freunde',
				family: 'Familie',
				yes: 'Ja',
				no: 'Nein',
				otherOptional: 'Andere (optional)',
				dream: 'Alles, was du träumen kannst...',
				attractions: 'Berühmte Sehenswürdigkeiten',
				food: 'Gutes Essen',
				beaches: 'Strände',
				waterSports: 'Wassersport',
				nightlife: 'Nachtleben',
				park: 'Parks und Wanderwege',
				shopping: 'Einkaufen',
				history: 'Geschichte',
				culture: 'Kultur',
				art: 'Kunst und Theater',
				spa: 'Spa',
				tripError:
					'Beim Planen Ihrer Reise ist ein Fehler aufgetreten.',
				tripLogin:
					'Sie müssen angemeldet sein, um Ihre Reise zu speichern.',
				topAttractions: 'Top-Sehenswürdigkeiten',
				basedOnInterests: 'Basierend auf Ihren Interessen',
				plan: 'Planen',
				whyVisit: 'Warum besuchen',
				appName: 'Stadt-Crawler',
				allRightsReserved: 'Alle Rechte vorbehalten',
				share: 'Teilen',
				shareCta: 'Teilen durch Scannen dieses QR-Codes.',
				pageUnderContruction:
					'Diese Seite befindet sich derzeit im Aufbau.',
				pageNotFound: 'Hoppla, Seite nicht gefunden.',
				goHome: 'Zur Startseite',
				click: 'Klicken',
				hereLowercase: 'hier',
				update: 'Aktualisieren',
				guest: 'Gast',
				admin: 'Administrator',
				explorer: 'Forscher',
				profile: 'Profil',
				name: 'Name',
				contact: 'Kontakt',
				darkMode: 'Dunkler Modus',
				email: 'E-Mail',
				password: 'Passwort',
				login: 'Einloggen',
				register: 'Registrieren',
				logout: 'Ausloggen',
				resetPassword: 'Passwort zurücksetzen',
				noAccount: 'Haben Sie kein Konto?',
				haveAccount: 'Haben Sie bereits ein Konto?',
				rememberPassword: 'Erinnern Sie sich an Ihr Passwort?',
				forgotPassword: 'Passwort vergessen?',
				updateProfileFail: 'Profilaktualisierung fehlgeschlagen.',
				updateProfileSuccess: 'Profil erfolgreich aktualisiert.',
				logoutSuccess: 'Erfolgreich ausgeloggt',
				logoutFail:
					'Ausloggen fehlgeschlagen. Bitte versuchen Sie es erneut.',
				results: 'Ergebnisse',
				overview: 'Übersicht',
				wikiPage: 'Wikipedia-Seite',
				map: 'Karte',
				cities: 'Städte',
				languages: 'Sprachen',
				capital: 'Hauptstadt',
				continent: 'Kontinent',
				cityName: 'Stadtname',
				country: 'Land',
				population: 'Bevölkerung',
				timezone: 'Zeitzone',
				currency: 'Währung',
				elevation: 'Höhe',
				explore: 'Erkunden',
				search: 'Suche',
				whereTo: 'Wohin?',
				noCountriesFound: 'Keine Länder gefunden.',
				topDestinations: 'Top-Ziele für Ihren nächsten Urlaub.',
				poweredByAi: 'Angetrieben von KI',
				beta: 'BETA',
				buildTrip: 'Erstellen Sie eine Reise in Minuten',
				getPersonalized:
					'Erhalten Sie eine personalisierte Reise, die auf Sie zugeschnitten ist.',
				startTripWithAi: 'Starten Sie eine Reise mit KI',
				cityError: 'Es gab einen Fehler beim Suchen dieser Stadt.',
				bangkok: 'Bangkok',
				thailand: 'Thailand',
				bangkokDescription:
					'Bekannt für sein lebhaftes Straßenleben, prächtige Schreine und den majestätischen Großen Palast zieht Bangkok Besucher aufgrund seiner einzigartigen Mischung aus traditioneller Kultur und modernem Unterhaltung an.',
				london: 'London',
				uk: 'Vereinigtes Königreich',
				londonDescription:
					'Die historischen und kulturellen Wahrzeichen wie das Britische Museum, der Buckingham Palace und der Tower of London ziehen Besucher an, neben seiner vielfältigen kulinarischen Szene und dem Theaterbezirk.',
				paris: 'Paris',
				france: 'Frankreich',
				parisDescription:
					'Bekannt für seine Kunst, Mode, Gastronomie und Kultur ist Paris die Heimat von ikonischen Sehenswürdigkeiten wie dem Eiffelturm, dem Louvre und der Kathedrale Notre-Dame.',
				dubai: 'Dubai',
				uae: 'Vereinigte Arabische Emirate',
				dubaiDescription:
					'Berühmt für Luxusshopping, ultramoderne Architektur und eine lebhafte Nachtszene ist der Burj Khalifa in Dubai, das höchste Gebäude der Welt, eine Hauptattraktion.',
				singapore: 'Singapur',
				singaporeDescription:
					'Bekannt für seine multikulturelle Bevölkerung, futuristischen Gärten und einzigartigen Attraktionen wie Marina Bay Sands bietet Singapur eine Mischung aus Kultur, Küche und Kunst.',
				nyc: 'New York City',
				usa: 'USA',
				nycDescription:
					'Die ikonischen Sehenswürdigkeiten der Stadt umfassen den Times Square, die Broadway-Theater, die Freiheitsstatue und den Central Park, was sie zu einem Zentrum für Unterhaltung, Mode und Kultur macht.',
				istanbul: 'Istanbul',
				turkey: 'Türkei',
				istanbulDescription:
					'Seine reiche Geschichte als Brücke zwischen Europa und Asien ist in seiner Architektur sichtbar, wobei die Hagia Sophia und die Blaue Moschee zu seinen berühmtesten Wahrzeichen gehören.',
				tokyo: 'Tokio',
				japan: 'Japan',
				tokyoDescription:
					'Bekannt für seine imposanten Wolkenkratzer, das geschäftige Shibuya-Kreuzung, historische Tempel und lebendige Popkultur bietet Tokio eine einzigartige Mischung aus traditionell und modern.',
				kualaLumpur: 'Kuala Lumpur',
				malaysia: 'Malaysia',
				kualaLumpurDescription:
					'Die Stadt ist berühmt für ihre koloniale Architektur, freundliche Einheimische, geschäftige Märkte und die ikonischen Petronas Twin Towers.',
				barcelona: 'Barcelona',
				spain: 'Spanien',
				barcelonaDescription:
					'Berühmt für seine Kunst und Architektur ist Barcelona die Heimat mehrerer Werke von Antoni Gaudí, darunter die Kirche Sagrada Família.',
				amsterdam: 'Amsterdam',
				netherlands: 'Niederlande',
				amsterdamDescription:
					'Bekannt für sein künstlerisches Erbe, sein komplexes Kanalsystem und seine schmalen Häuser mit Giebelfassaden sind das Van Gogh Museum und das Rijksmuseum große Anziehungspunkte.',
				rome: 'Rom',
				italy: 'Italien',
				romeDescription:
					'Die reiche Geschichte der Stadt als ehemalige Hauptstadt des Römischen Reiches zieht Besucher zum Kolosseum, zum Forum Romanum und zum Vatikan.',
				osaka: 'Osaka',
				osakaDescription:
					'Bekannt für seine moderne Architektur, sein Nachtleben und sein herzhaftes Straßenessen ist das Osaka Castle ein wichtiges Wahrzeichen.',
				milan: 'Mailand',
				milanDescription:
					"Als globale Hauptstadt der Mode und des Designs ist Mailand die Heimat des gotischen Doms von Mailand und des Klosters Santa Maria delle Grazie, das Leonardo da Vincis 'Das letzte Abendmahl' beherbergt.",
				vienna: 'Wien',
				austria: 'Österreich',
				viennaDescription:
					'Bekannt für sein künstlerisches und intellektuelles Erbe, das von Einwohnern wie Mozart, Beethoven und Sigmund Freud geprägt wurde, ist die Stadt auch die Heimat des barocken Schlosses Schönbrunn.',
				shanghai: 'Shanghai',
				china: 'China',
				shanghaiDescription:
					'Bekannt für seine Skyline von Lujiazui, den historischen Bund, belebte Einkaufsstraßen und den weitläufigen Yu Garden verbindet Shanghai Tradition mit modernster Entwicklung.',
				prague: 'Prag',
				czechRepublic: 'Tschechische Republik',
				pragueDescription:
					'Berühmt für seinen Altstädter Ring, farbenfrohe barocke Gebäude, gotische Kirchen und die mittelalterliche astronomische Uhr hat Prag eine reiche Geschichte und eine lebendige Kulturszene.',
				losAngeles: 'Los Angeles',
				losAngelesDescription:
					'Bekannt für Hollywood, ausgedehnte Strände und die Unterhaltungsindustrie, sind das Hollywood-Schild und das Griffith Observatorium unter den meistbesuchten Sehenswürdigkeiten.',
			},
		},
		ru: {
			translation: {
				yourTrip: 'Ваш персонализированный отдых',
				back: 'назад',
				searchCountries: 'Искать страны',
				planTrip: 'Запланируйте поездку за минуты',
				noBrain: 'Нет мозга? Нет проблем.',
				discoverNew: 'Откройте что-то новое',
				where: 'Куда вы хотите поехать?',
				who: 'Кто пойдет с вами?',
				when: 'Когда вы хотите поехать?',
				what: 'Что бы вы хотели сделать?',
				selectACity: 'Выберите город',
				reset: 'Сбросить',
				travelingWithChildren: 'Путешествие с детьми?',
				previous: 'Предыдущий',
				next: 'Следующий',
				submit: 'Отправить',
				of: 'из',
				solo: 'Один',
				partner: 'Партнер',
				friends: 'Друзья',
				family: 'Семья',
				yes: 'Да',
				no: 'Нет',
				otherOptional: 'Другое (необязательно)',
				dream: 'Все, что вы можете представить...',
				attractions: 'Известные достопримечательности',
				food: 'Великолепная еда',
				beaches: 'Пляжи',
				waterSports: 'Водные виды спорта',
				nightlife: 'Ночная жизнь',
				park: 'Парки и тропы',
				shopping: 'Шопинг',
				history: 'История',
				culture: 'Культура',
				art: 'Искусство и театр',
				spa: 'Спа',
				tripError: 'При планировании вашей поездки произошла ошибка.',
				tripLogin:
					'Вы должны войти в систему, чтобы сохранить свою поездку.',
				topAttractions: 'Лучшие достопримечательности',
				basedOnInterests: 'На основе ваших интересов',
				plan: 'Планировать',
				whyVisit: 'Зачем посещать',
				appName: 'Городской Краулер',
				allRightsReserved: 'Все права защищены',
				share: 'Поделиться',
				shareCta: 'Поделиться, отсканировав этот QR-код.',
				pageUnderContruction:
					'Эта страница в настоящее время находится в стадии разработки.',
				pageNotFound: 'Упс, страница не найдена.',
				goHome: 'Перейти на главную',
				click: 'Клик',
				hereLowercase: 'здесь',
				update: 'Обновить',
				guest: 'Гость',
				admin: 'Админ',
				explorer: 'Исследователь',
				profile: 'Профиль',
				name: 'Имя',
				contact: 'Контакт',
				darkMode: 'Тёмный режим',
				email: 'Электронная почта',
				password: 'Пароль',
				login: 'Вход',
				register: 'Регистрация',
				logout: 'Выход',
				resetPassword: 'Сбросить пароль',
				noAccount: 'У вас нет аккаунта?',
				haveAccount: 'Уже есть аккаунт?',
				rememberPassword: 'Запомнить пароль?',
				forgotPassword: 'Забыли пароль?',
				updateProfileFail: 'Не удалось обновить профиль.',
				updateProfileSuccess: 'Профиль успешно обновлен.',
				logoutSuccess: 'Выход выполнен успешно',
				logoutFail: 'Не удалось выйти. Пожалуйста, попробуйте еще раз.',
				results: 'Результаты',
				overview: 'Обзор',
				wikiPage: 'страница википедии',
				map: 'Карта',
				cities: 'Города',
				languages: 'Языки',
				capital: 'Столица',
				continent: 'Континент',
				cityName: 'Название города',
				country: 'Страна',
				population: 'Население',
				timezone: 'Часовой пояс',
				currency: 'Валюта',
				elevation: 'Высота над уровнем моря',
				explore: 'Исследовать',
				search: 'Поиск',
				whereTo: 'Куда?',
				noCountriesFound: 'Страны не найдены.',
				topDestinations:
					'Лучшие направления для вашего следующего отпуска.',
				poweredByAi: 'Работает на ИИ',
				beta: 'БЕТА',
				buildTrip: 'Построить путешествие за несколько минут',
				getPersonalized:
					'Получите персонализированное путешествие, подходящее вам.',
				startTripWithAi: 'Начать путешествие с ИИ',
				cityError: 'Произошла ошибка при поиске этого города.',
				bangkok: 'Бангкок',
				thailand: 'Таиланд',
				bangkokDescription:
					'Известный своей яркой уличной жизнью, изысканными святынями и величественным Дворцом, Бангкок привлекает посетителей своим уникальным сочетанием традиционной культуры и современного развлечения.',
				london: 'Лондон',
				uk: 'Соединенное Королевство',
				londonDescription:
					'Исторические и культурные памятники, такие как Британский музей, Букингемский дворец и Лондонская башня, привлекают посетителей, наряду с его разнообразной кулинарной сценой и театральным районом.',
				paris: 'Париж',
				france: 'Франция',
				parisDescription:
					'Известный своими искусством, модой, гастрономией и культурой, Париж является домом для символических достопримечательностей, таких как Эйфелева башня, Лувр и собор Парижской Богоматери.',
				dubai: 'Дубай',
				uae: 'Объединенные Арабские Эмираты',
				dubaiDescription:
					'Известный роскошными покупками, ультрасовременной архитектурой и оживленной ночной жизнью, Бурдж Халифа - самое высокое здание в мире, является ключевой достопримечательностью Дубая.',
				singapore: 'Сингапур',
				singaporeDescription:
					'Известный своим мультикультурным населением, футуристическими садами и уникальными достопримечательностями, такими как Марина Бей Сэндс, Сингапур предлагает смесь культуры, кухни и искусства.',
				nyc: 'Нью-Йорк',
				usa: 'США',
				nycDescription:
					'Иконические места города включают Таймс-сквер, бродвейские театры, Статую Свободы и Центральный парк, что делает его центром развлечений, моды и культуры.',
				istanbul: 'Стамбул',
				turkey: 'Турция',
				istanbulDescription:
					'Его богатая история как моста между Европой и Азией видна в его архитектуре, с Айя-Софией и Синей мечетью среди его самых известных достопримечательностей.',
				tokyo: 'Токио',
				japan: 'Япония',
				tokyoDescription:
					'Известный своими небоскребами, оживленным Шибуйским перекрестком, историческими храмами и живой поп-культурой, Токио предлагает уникальное сочетание традиционного и современного.',
				kualaLumpur: 'Куала-Лумпур',
				malaysia: 'Малайзия',
				kualaLumpurDescription:
					'Город знаменит своей колониальной архитектурой, дружелюбными местными жителями, оживленными рынками и символичными башнями-близнецами Петронас.',
				barcelona: 'Барселона',
				spain: 'Испания',
				barcelonaDescription:
					'Известный своим искусством и архитектурой, Барселона является домом для нескольких работ Антонио Гауди, включая церковь Святого Семейства.',
				amsterdam: 'Амстердам',
				netherlands: 'Нидерланды',
				amsterdamDescription:
					'Известный своим художественным наследием, сложной системой каналов и узкими домами с фасадами в стиле готики, Музей Ван Гога и Рейксмузей являются основными достопримечательностями.',
				rome: 'Рим',
				italy: 'Италия',
				romeDescription:
					'Богатая история города как бывшей столицы Римской империи привлекает посетителей в Колизей, Римский форум и Ватикан.',
				osaka: 'Осака',
				osakaDescription:
					'Известный своей современной архитектурой, ночной жизнью и сытной уличной едой, Осака-дзо - ключевая достопримечательность.',
				milan: 'Милан',
				milanDescription:
					"Мировая столица моды и дизайна, Милан является домом для готического собора Дуомо-ди-Милано и монастыря Санта-Мария-делле-Грацие, где находится 'Последняя тайная вечеря' Леонардо да Винчи.",
				vienna: 'Вена',
				austria: 'Австрия',
				viennaDescription:
					'Известный своим художественным и интеллектуальным наследием, формированным жителями, такими как Моцарт, Бетховен и Зигмунд Фрейд, город также является домом для барочного дворца Шенбрунн.',
				shanghai: 'Шанхай',
				china: 'Китай',
				shanghaiDescription:
					'Известный своим небоскребами в Лудзяцуй, историческим Бундом, оживленными торговыми улицами и обширным садом Ю, Шанхай сочетает в себе традиции с передовым развитием.',
				prague: 'Прага',
				czechRepublic: 'Чешская Республика',
				pragueDescription:
					'Известная своим Староместским площадью, красочными барочными зданиями, готическими церквями и средневековыми Астрономическими часами, Прага имеет богатую историю и живую культурную сцену.',
				losAngeles: 'Лос-Анджелес',
				losAngelesDescription:
					"Известный Голливудом, обширными пляжами и развлекательной индустрией, Знак 'Голливуд' и Обсерватория Гриффита - одни из самых посещаемых достопримечательностей.",
			},
		},
		ja: {
			translation: {
				yourTrip: 'あなたのパーソナライズされた旅',
				back: '戻る',
				searchCountries: '国を検索',
				planTrip: '数分で旅行を計画する',
				noBrain: '頭がない？問題ありません。',
				discoverNew: '新しいものを発見する',
				where: 'どこに行きたいですか？',
				who: '誰が一緒に行きますか？',
				when: 'いつ行きたいですか？',
				what: '何をしたいですか？',
				selectACity: '都市を選択する',
				reset: 'リセット',
				travelingWithChildren: '子供と一緒に旅行しますか？',
				previous: '前へ',
				next: '次へ',
				submit: '送信',
				of: 'の',
				solo: 'ひとりで',
				partner: 'パートナー',
				friends: '友達',
				family: '家族',
				yes: 'はい',
				no: 'いいえ',
				otherOptional: 'その他（任意）',
				dream: '夢見ることができるすべて...',
				attractions: '有名な観光地',
				food: '素晴らしい食べ物',
				beaches: 'ビーチ',
				waterSports: 'ウォータースポーツ',
				nightlife: 'ナイトライフ',
				park: '公園やトレイル',
				shopping: '買い物',
				history: '歴史',
				culture: '文化',
				art: '芸術と劇場',
				spa: 'スパ',
				tripError: '旅行を計画する際にエラーが発生しました。',
				tripLogin: '旅行を保存するにはログインする必要があります。',
				topAttractions: 'トップアトラクション',
				basedOnInterests: 'あなたの興味に基づいて',
				plan: '計画',
				whyVisit: 'なぜ訪れるのか',
				appName: 'シティクローラー',
				allRightsReserved: 'すべての権利を保有します',
				share: '共有',
				shareCta: 'このQRコードをスキャンして共有します。',
				pageUnderContruction: 'このページは現在工事中です。',
				pageNotFound: 'おっと、ページが見つかりません。',
				goHome: 'ホームに戻る',
				click: 'クリック',
				hereLowercase: 'こちら',
				update: '更新',
				guest: 'ゲスト',
				admin: '管理者',
				explorer: 'エクスプローラー',
				profile: 'プロフィール',
				name: '名前',
				contact: '連絡先',
				darkMode: 'ダークモード',
				email: 'メール',
				password: 'パスワード',
				login: 'ログイン',
				register: '登録',
				logout: 'ログアウト',
				resetPassword: 'パスワードをリセット',
				noAccount: 'アカウントをお持ちでないですか？',
				haveAccount: 'すでにアカウントをお持ちですか？',
				rememberPassword: 'パスワードを覚えていますか？',
				forgotPassword: 'パスワードをお忘れですか？',
				updateProfileFail: 'プロフィールの更新に失敗しました。',
				updateProfileSuccess: 'プロフィールが正常に更新されました。',
				logoutSuccess: 'ログアウトが正常に完了しました',
				logoutFail:
					'ログアウトに失敗しました。もう一度お試しください。',
				results: '結果',
				overview: '概要',
				wikiPage: 'ウィキペディアページ',
				map: '地図',
				cities: '都市',
				languages: '言語',
				capital: '首都',
				continent: '大陸',
				cityName: '都市名',
				country: '国',
				population: '人口',
				timezone: 'タイムゾーン',
				currency: '通貨',
				elevation: '標高',
				explore: '探検',
				search: '検索',
				whereTo: 'どこへ？',
				noCountriesFound: '国が見つかりません。',
				topDestinations: '次の休暇のトップ目的地。',
				poweredByAi: 'AIによって動作',
				beta: 'ベータ',
				buildTrip: '数分で旅行を計画する',
				getPersonalized: 'あなたに合わせた個人旅行を手に入れる。',
				startTripWithAi: 'AIとの旅行を始める',
				cityError: 'その都市の検索中にエラーが発生しました。',
				bangkok: 'バンコク',
				thailand: 'タイ',
				bangkokDescription:
					'活気のある街並み、装飾的な神社、壮大なグランドパレスで知られるバンコクは、伝統的な文化と現代のエンターテイメントのユニークな融合で訪問者を引き付けます。',
				london: 'ロンドン',
				uk: 'イギリス',
				londonDescription:
					'ブリティッシュ博物館、バッキンガム宮殿、ロンドン塔などの歴史的・文化的な名所が訪問者を引き付け、多様な飲食シーンや劇場地区と共に。',
				paris: 'パリ',
				france: 'フランス',
				parisDescription:
					'芸術、ファッション、美食、文化で有名なパリは、エッフェル塔、ルーヴル美術館、ノートルダム大聖堂などの象徴的な名所があります。',
				dubai: 'ドバイ',
				uae: 'アラブ首長国連邦',
				dubaiDescription:
					'高級ショッピング、超現代的な建築、活気あるナイトライフで有名なドバイのブルジュ・ハリファは、世界一の高さを誇る最大の魅力です。',
				singapore: 'シンガポール',
				singaporeDescription:
					'多文化な人口、未来的な庭園、マリーナベイサンズなどのユニークな観光スポットで知られるシンガポールは、文化、料理、アートの融合を提供します。',
				nyc: 'ニューヨーク市',
				usa: 'アメリカ',
				nycDescription:
					'タイムズスクエア、ブロードウェイ劇場、自由の女神像、セントラルパークなど、市の象徴的な場所があり、それがエンターテイメント、ファッション、文化の中心地となっています。',
				istanbul: 'イスタンブール',
				turkey: 'トルコ',
				istanbulDescription:
					'ヨーロッパとアジアの架け橋としての豊かな歴史は、ハジア・ソフィアやブルーモスクなどの建築物に現れています。',
				tokyo: '東京',
				japan: '日本',
				tokyoDescription:
					'高層ビル、賑やかな渋谷交差点、歴史的な寺院、そして活気あるポップカルチャーで知られる東京は、伝統と現代のユニークな組み合わせを提供します。',
				kualaLumpur: 'クアラルンプール',
				malaysia: 'マレーシア',
				kualaLumpurDescription:
					'植民地時代の建築、親しみやすい地元の人々、活気のある市場、象徴的なペトロナスツインタワーで有名な都市。',
				barcelona: 'バルセロナ',
				spain: 'スペイン',
				barcelonaDescription:
					'アントニ・ガウディの作品、サグラダ・ファミリア教会など、芸術と建築で有名なバルセロナ。',
				amsterdam: 'アムステルダム',
				netherlands: 'オランダ',
				amsterdamDescription:
					'芸術遺産、精巧な運河システム、ガブル屋根の家々で有名なアムステルダムは、バン・ゴッホ美術館やライクス美術館などが主要な目玉です。',
				rome: 'ローマ',
				italy: 'イタリア',
				romeDescription:
					'ローマ帝国の元首都としての市の豊かな歴史は、コロッセウム、ローマフォーラム、バチカン市国への訪問者を引き付けます。',
				osaka: '大阪',
				osakaDescription:
					'近代建築、ナイトライフ、ハートフルなストリートフードで知られる大阪城は、重要なランドマークです。',
				milan: 'ミラノ',
				milanDescription:
					'ファッションとデザインの世界的な首都であるミラノは、ゴシック様式のドゥオーモ・ディ・ミラノ大聖堂やサンタ・マリア・デッレ・グラツィエ修道院、レオナルド・ダ・ヴィンチの「最後の晩餐」が収蔵されています。',
				vienna: 'ウィーン',
				austria: 'オーストリア',
				viennaDescription:
					'モーツァルト、ベートーヴェン、ジークムント・フロイトなどの住民によって形作られた芸術的および知的遺産で有名なウィーンは、バロック様式のシェーンブルン宮殿もあります。',
				shanghai: '上海',
				china: '中国',
				shanghaiDescription:
					'ルジャズイの空中庭園、歴史的な外灘、賑やかな商店街、広大な豫園などで知られる上海は、伝統と先端技術の融合です。',
				prague: 'プラハ',
				czechRepublic: 'チェコ共和国',
				pragueDescription:
					'旧市街広場、カラフルなバロック様式の建物、ゴシック様式の教会、中世の天文時計などで有名なプラハは、豊かな歴史と活気ある文化シーンを持っています。',
				losAngeles: 'ロサンゼルス',
				losAngelesDescription:
					'ハリウッド、広大なビーチ、エンターテイメント産業で知られるロサンゼルスは、ハリウッドサインやグリフィス天文台がその中でも最も訪れられる場所です。',
			},
		},
		fr: {
			translation: {
				yourTrip: 'Votre voyage personnalisé',
				back: 'retour',
				searchCountries: 'Rechercher des pays',
				planTrip: 'Planifiez un voyage en quelques minutes',
				noBrain: 'Pas de cerveau ? Pas de problème.',
				discoverNew: 'Découvrez quelque chose de nouveau',
				where: 'Où voulez-vous aller ?',
				who: 'Qui vient avec vous ?',
				when: 'Quand voulez-vous partir ?',
				what: 'Que voulez-vous faire ?',
				selectACity: 'Sélectionnez une ville',
				reset: 'Réinitialiser',
				travelingWithChildren: 'Voyagez-vous avec des enfants ?',
				previous: 'Précédent',
				next: 'Suivant',
				submit: 'Soumettre',
				of: 'de',
				solo: 'Seul',
				partner: 'Partenaire',
				friends: 'Amis',
				family: 'Famille',
				yes: 'Oui',
				no: 'Non',
				otherOptional: 'Autre (optionnel)',
				dream: 'Tout ce que vous pouvez rêver...',
				attractions: 'Attractions célèbres',
				food: 'Bonne nourriture',
				beaches: 'Plages',
				waterSports: 'Sports nautiques',
				nightlife: 'Vie nocturne',
				park: 'Parcs et sentiers',
				shopping: 'Shopping',
				history: 'Histoire',
				culture: 'Culture',
				art: 'Arts et théâtre',
				spa: 'Spa',
				tripError:
					"Une erreur s'est produite lors de la planification de votre voyage.",
				tripLogin:
					'Vous devez être connecté pour enregistrer votre voyage.',
				topAttractions: 'Attractions principales',
				basedOnInterests: 'Basé sur vos intérêts',
				plan: 'Planifier',
				whyVisit: 'Pourquoi visiter',
				appName: 'Explorateur de villes',
				allRightsReserved: 'Tous droits réservés',
				share: 'Partager',
				shareCta: 'Partager en scannant ce code QR.',
				pageUnderContruction:
					'Cette page est actuellement en construction.',
				pageNotFound: 'Oups, page non trouvée.',
				goHome: 'Rentrer à la maison',
				click: 'Cliquez',
				hereLowercase: 'ici',
				update: 'Mettre à jour',
				guest: 'Invité',
				admin: 'Administrateur',
				explorer: 'Explorateur',
				profile: 'Profil',
				name: 'Nom',
				contact: 'Contact',
				darkMode: 'Mode sombre',
				email: 'E-mail',
				password: 'Mot de passe',
				login: 'Connexion',
				register: "S'inscrire",
				logout: 'Déconnexion',
				resetPassword: 'Réinitialiser le mot de passe',
				noAccount: "Vous n'avez pas de compte ?",
				haveAccount: 'Vous avez déjà un compte ?',
				rememberPassword: 'Souvenez-vous de votre mot de passe ?',
				forgotPassword: 'Mot de passe oublié ?',
				updateProfileFail: 'Échec de la mise à jour du profil.',
				updateProfileSuccess: 'Profil mis à jour avec succès.',
				logoutSuccess: 'Déconnexion réussie',
				logoutFail: 'Échec de la déconnexion. Veuillez réessayer.',
				results: 'Résultats',
				overview: 'Aperçu',
				wikiPage: 'Page Wikipédia',
				map: 'Carte',
				cities: 'Villes',
				languages: 'Langues',
				capital: 'Capitale',
				continent: 'Continent',
				cityName: 'Nom de la ville',
				country: 'Pays',
				population: 'Population',
				timezone: 'Fuseau horaire',
				currency: 'Devise',
				elevation: 'Élévation',
				explore: 'Explorer',
				search: 'Rechercher',
				whereTo: 'Où aller ?',
				noCountriesFound: 'Aucun pays trouvé.',
				topDestinations:
					'Principales destinations pour vos prochaines vacances.',
				poweredByAi: "Alimenté par l'IA",
				beta: 'BÊTA',
				buildTrip: 'Planifiez un voyage en quelques minutes',
				getPersonalized:
					'Obtenez un voyage personnalisé adapté à vous.',
				startTripWithAi: "Commencez un voyage avec l'IA",
				cityError:
					"Une erreur s'est produite lors de la recherche de cette ville.",
				bangkok: 'Bangkok',
				thailand: 'Thaïlande',
				bangkokDescription:
					'Connu pour sa vie de rue animée, ses sanctuaires ornés et le majestueux Grand Palais, Bangkok attire les visiteurs pour son mélange unique de culture traditionnelle et de divertissement moderne.',
				london: 'Londres',
				uk: 'Royaume-Uni',
				londonDescription:
					'Les repères historiques et culturels comme le British Museum, le Buckingham Palace et la Tour de Londres attirent les visiteurs, aux côtés de sa scène culinaire diversifiée et de son quartier théâtral.',
				paris: 'Paris',
				france: 'France',
				parisDescription:
					'Réputée pour son art, sa mode, sa gastronomie et sa culture, Paris abrite des monuments emblématiques tels que la Tour Eiffel, le Musée du Louvre et la Cathédrale Notre-Dame.',
				dubai: 'Dubaï',
				uae: 'Émirats arabes unis',
				dubaiDescription:
					'Célèbre pour ses boutiques de luxe, son architecture ultramoderne et sa vie nocturne animée, le Burj Khalifa de Dubaï, le plus haut bâtiment du monde, est une attraction majeure.',
				singapore: 'Singapour',
				singaporeDescription:
					"Connue pour sa population multiculturelle, ses jardins futuristes et ses attractions uniques comme Marina Bay Sands, Singapour offre un mélange de culture, de cuisine et d'art.",
				nyc: 'New York City',
				usa: 'États-Unis',
				nycDescription:
					'Les sites emblématiques de la ville incluent Times Square, les théâtres de Broadway, la Statue de la Liberté et Central Park, ce qui en fait un centre de divertissement, de mode et de culture.',
				istanbul: 'Istanbul',
				turkey: 'Turquie',
				istanbulDescription:
					"Sa riche histoire en tant que pont entre l'Europe et l'Asie est visible dans son architecture, avec Sainte-Sophie et la Mosquée Bleue parmi ses monuments les plus célèbres.",
				tokyo: 'Tokyo',
				japan: 'Japon',
				tokyoDescription:
					'Connu pour ses gratte-ciel imposants, son animé carrefour de Shibuya, ses temples historiques et sa culture pop vibrante, Tokyo offre un mélange unique de tradition et de modernité.',
				kualaLumpur: 'Kuala Lumpur',
				malaysia: 'Malaisie',
				kualaLumpurDescription:
					'La ville est célèbre pour son architecture coloniale, ses habitants sympathiques, ses marchés animés et les iconiques Tours jumelles Petronas.',
				barcelona: 'Barcelone',
				spain: 'Espagne',
				barcelonaDescription:
					"Renommée pour son art et son architecture, Barcelone abrite plusieurs œuvres d'Antoni Gaudí, dont l'église de la Sagrada Família.",
				amsterdam: 'Amsterdam',
				netherlands: 'Pays-Bas',
				amsterdamDescription:
					'Connue pour son patrimoine artistique, son système de canaux élaboré et ses maisons étroites à pignons, le musée Van Gogh et le Rijksmuseum sont des attractions majeures.',
				rome: 'Rome',
				italy: 'Italie',
				romeDescription:
					"La riche histoire de la ville en tant qu'ancienne capitale de l'Empire romain attire les visiteurs vers le Colisée, le Forum romain et la Cité du Vatican.",
				osaka: 'Osaka',
				osakaDescription:
					"Connu pour son architecture moderne, sa vie nocturne et sa nourriture de rue copieuse, le château d'Osaka est un monument clé.",
				milan: 'Milan',
				milanDescription:
					'Capitale mondiale de la mode et du design, Milan abrite la cathédrale gothique du Duomo di Milano et le couvent de Santa Maria delle Grazie, où se trouve "La Cène" de Léonard de Vinci.',
				vienna: 'Vienne',
				austria: 'Autriche',
				viennaDescription:
					'Connue pour son héritage artistique et intellectuel façonné par des résidents comme Mozart, Beethoven et Sigmund Freud, la ville abrite également le palais baroque de Schönbrunn.',
				shanghai: 'Shanghai',
				china: 'Chine',
				shanghaiDescription:
					'Célèbre pour son horizon de Lujiazui, son Bund historique, ses rues commerçantes animées et le vaste jardin Yu, Shanghai marie tradition et développement de pointe.',
				prague: 'Prague',
				czechRepublic: 'République tchèque',
				pragueDescription:
					"Célèbre pour sa place de la Vieille Ville, ses bâtiments baroques colorés, ses églises gothiques et l'horloge astronomique médiévale, Prague a une histoire riche et une scène culturelle vibrante.",
				losAngeles: 'Los Angeles',
				losAngelesDescription:
					"Connu pour Hollywood, ses plages étendues et l'industrie du divertissement, l'enseigne Hollywood et l'observatoire Griffith figurent parmi ses sites les plus visités.",
			},
		},
		ar: {
			translation: {
				yourTrip: 'رحلتك الشخصية',
				back: 'عودة',
				searchCountries: 'البحث عن الدول',
				planTrip: 'خطط لرحلة في دقائق',
				noBrain: 'لا دماغ؟ لا مشكلة.',
				discoverNew: 'اكتشاف شيء جديد',
				where: 'إلى أين تريد أن تذهب؟',
				who: 'من سيأتي معك؟',
				when: 'متى ترغب في الذهاب؟',
				what: 'ما الذي ترغب في القيام به؟',
				selectACity: 'اختر مدينة',
				reset: 'إعادة تعيين',
				travelingWithChildren: 'السفر مع الأطفال؟',
				previous: 'السابق',
				next: 'التالي',
				submit: 'إرسال',
				of: 'من',
				solo: 'مفرداً',
				partner: 'شريك',
				friends: 'أصدقاء',
				family: 'عائلة',
				yes: 'نعم',
				no: 'لا',
				otherOptional: 'آخر (اختياري)',
				dream: 'أي شيء يمكنك أن تحلم به...',
				attractions: 'المعالم السياحية الشهيرة',
				food: 'طعام رائع',
				beaches: 'الشواطئ',
				waterSports: 'الرياضات المائية',
				nightlife: 'حياة الليل',
				park: 'الحدائق والممرات',
				shopping: 'التسوق',
				history: 'التاريخ',
				culture: 'الثقافة',
				art: 'الفنون والمسرح',
				spa: 'منتجع صحي',
				tripError: 'حدث خطأ أثناء تخطيط رحلتك.',
				tripLogin: 'يجب عليك تسجيل الدخول لحفظ رحلتك.',
				topAttractions: 'أهم المعالم السياحية',
				basedOnInterests: 'بناءً على اهتماماتك',
				plan: 'خطة',
				whyVisit: 'لماذا زيارة',
				appName: 'مستكشف المدن',
				allRightsReserved: 'جميع الحقوق محفوظة',
				share: 'مشاركة',
				shareCta: 'مشاركة عن طريق مسح هذا الرمز الاستجابة السريعة.',
				pageUnderContruction: 'هذه الصفحة تحت الإنشاء حالياً.',
				pageNotFound: 'عذراً، الصفحة غير موجودة.',
				goHome: 'العودة إلى الصفحة الرئيسية',
				click: 'انقر',
				hereLowercase: 'هنا',
				update: 'تحديث',
				guest: 'ضيف',
				admin: 'مدير',
				explorer: 'مستكشف',
				profile: 'الملف الشخصي',
				name: 'الاسم',
				contact: 'الاتصال',
				darkMode: 'الوضع الداكن',
				email: 'البريد الإلكتروني',
				password: 'كلمة السر',
				login: 'تسجيل الدخول',
				register: 'التسجيل',
				logout: 'تسجيل الخروج',
				resetPassword: 'إعادة تعيين كلمة المرور',
				noAccount: 'ليس لديك حساب؟',
				haveAccount: 'هل لديك حساب بالفعل؟',
				rememberPassword: 'تذكر كلمة المرور الخاصة بك؟',
				forgotPassword: 'نسيت كلمة المرور؟',
				updateProfileFail: 'فشل تحديث الملف الشخصي.',
				updateProfileSuccess: 'تم تحديث الملف الشخصي بنجاح.',
				logoutSuccess: 'تم تسجيل الخروج بنجاح',
				logoutFail: 'فشل تسجيل الخروج. يرجى المحاولة مرة أخرى.',
				results: 'النتائج',
				overview: 'نظرة عامة',
				wikiPage: 'صفحة ويكيبيديا',
				map: 'خريطة',
				cities: 'المدن',
				languages: 'اللغات',
				capital: 'العاصمة',
				continent: 'القارة',
				cityName: 'اسم المدينة',
				country: 'البلد',
				population: 'السكان',
				timezone: 'المنطقة الزمنية',
				currency: 'العملة',
				elevation: 'الارتفاع',
				explore: 'استكشاف',
				search: 'بحث',
				whereTo: 'إلى أين؟',
				noCountriesFound: 'لم يتم العثور على بلدان.',
				topDestinations: 'أفضل الوجهات لعطلتك القادمة.',
				poweredByAi: 'مدعوم بالذكاء الصناعي',
				beta: 'بيتا',
				buildTrip: 'بناء رحلة في دقائق',
				getPersonalized: 'احصل على رحلة مخصصة لك.',
				startTripWithAi: 'ابدأ رحلة مع الذكاء الصناعي',
				cityError: 'حدث خطأ أثناء البحث عن تلك المدينة.',
				bangkok: 'بانكوك',
				thailand: 'تايلاند',
				bangkokDescription:
					'تشتهر بحياتها الشارعية النابضة بالحياة، والمزارات المزخرفة، والقصر الكبير الرائع، وتجذب بانكوك الزوار بمزيجها الفريد من الثقافة التقليدية والترفيه الحديث.',
				london: 'لندن',
				uk: 'المملكة المتحدة',
				londonDescription:
					'تجذب المعالم التاريخية والثقافية مثل المتحف البريطاني وقصر باكنغهام وبرج لندن الزوار، إلى جانب مشهدها الطبيعي المتنوع ومنطقة مسارحها المتعددة.',
				paris: 'باريس',
				france: 'فرنسا',
				parisDescription:
					'مشهورة بفنها وأزيائها ومأكولاتها وثقافتها، تضم باريس معالم شهيرة مثل برج إيفل ومتحف اللوفر وكاتدرائية نوتردام.',
				dubai: 'دبي',
				uae: 'الإمارات العربية المتحدة',
				dubaiDescription:
					'مشهورة بالتسوق الفاخر والهندسة المعمارية الحديثة والحياة الليلية النابضة بالحياة، برج خليفة في دبي، أطول بناية في العالم، هو معلم رئيسي.',
				singapore: 'سنغافورة',
				singaporeDescription:
					'تشتهر بسكانها متعددي الثقافات وحدائقها المستقبلية ومعالمها الفريدة مثل مارينا باي ساندز، تقدم سنغافورة مزيجًا من الثقافة والمطبخ والفن.',
				nyc: 'مدينة نيويورك',
				usa: 'الولايات المتحدة الأمريكية',
				nycDescription:
					'تشمل المواقع الرمزية للمدينة ساحة تايمز، ومسارح برودواي، وتمثال الحرية، وسنترال بارك، مما يجعلها مركزًا للترفيه والأزياء والثقافة.',
				istanbul: 'اسطنبول',
				turkey: 'تركيا',
				istanbulDescription:
					'تاريخها الغني كجسر بين أوروبا وآسيا يظهر في عمارتها، مع آيا صوفيا والمسجد الأزرق من بين معالمها الأكثر شهرة.',
				tokyo: 'طوكيو',
				japan: 'اليابان',
				tokyoDescription:
					'معروفة بأبراجها الشاهقة، ومعبر شيبويا النابض بالحياة، والمعابد التاريخية، والثقافة الشعبية النابضة بالحياة، تقدم طوكيو مزيجًا فريدًا من التقاليد والحداثة.',
				kualaLumpur: 'كوالالمبور',
				malaysia: 'ماليزيا',
				kualaLumpurDescription:
					'تشتهر المدينة بعمارتها الاستعمارية وسكانها الودودين وأسواقها النابضة بالحياة وأبراج التوأم الرمزية.',
				barcelona: 'برشلونة',
				spain: 'إسبانيا',
				barcelonaDescription:
					'مشهورة بفنها وعمارتها، برشلونة تضم العديد من أعمال أنطوني غاودي، بما في ذلك كنيسة ساجرادا فاميليا.',
				amsterdam: 'أمستردام',
				netherlands: 'هولندا',
				amsterdamDescription:
					'معروفة بتراثها الفني ونظامها المائي المعقد ومنازلها الضيقة ذات الواجهات المدفوعة، فالمتحف فان جوخ ومتحف رايكسميوزيوم هي جاذبيات رئيسية.',
				rome: 'روما',
				italy: 'إيطاليا',
				romeDescription:
					'تاريخ المدينة الغني كعاصمة سابقة للإمبراطورية الرومانية يجذب الزوار إلى الكولوسيوم والمنتدى الروماني ومدينة الفاتيكان.',
				osaka: 'أوساكا',
				osakaDescription:
					'معروفة بعمارتها الحديثة وحياتها الليلية وأطعمتها الشعبية اللذيذة، فالقلعة الأوساكية هي معلم رئيسي.',
				milan: 'ميلان',
				milanDescription:
					'عاصمة عالمية للموضة والتصميم، ميلان تضم كاتدرائية دومو دي ميلانو القوطية ودير سانتا ماريا ديلي جراتسي التي تضم "العشاء الأخير" ليوناردو دا فينشي.',
				vienna: 'فيينا',
				austria: 'النمسا',
				viennaDescription:
					'معروفة بتراثها الفني والفكري الذي شكله سكان مثل موزارت وبيتهوفن وسيغموند فرويد، تضم المدينة أيضًا قصر شونبرون الباروكي.',
				shanghai: 'شنغهاي',
				china: 'الصين',
				shanghaiDescription:
					'مشهورة بأفقها في لوجيازوي، والمنطقة التاريخية بند، والشوارع التجارية النابضة بالحياة، والحديقة الواسعة يو، تجمع شنغهاي بين التقليد والتطور الحديث.',
				prague: 'براغ',
				czechRepublic: 'جمهورية التشيك',
				pragueDescription:
					'مشهورة بساحتها القديمة، ومبانيها الباروكية الملونة، وكنائسها القوطية، وساعتها الفلكية الوسطى، براغ لديها تاريخ غني ومشهد ثقافي حيوي.',
				losAngeles: 'لوس أنجلوس',
				losAngelesDescription:
					'مشهورة بـ هوليوود، وشواطئها الواسعة، وصناعة الترفيه، لافتة هوليوود ومرصد جريفيث من بين أكثر المواقع زيارةً.',
			},
		},
		fa: {
			translation: {
				yourTrip: 'سفر شخصی شما',
				back: 'بازگشت',
				searchCountries: 'جستجوی کشورها',
				planTrip: 'برنامه ریزی سفر در چند دقیقه',
				noBrain: 'بدون مغز؟ مشکلی نیست.',
				discoverNew: 'کشف چیزی جدید',
				where: 'کجا می‌خواهید بروید؟',
				who: 'کی با شما می‌آید؟',
				when: 'کی می‌خواهید بروید؟',
				what: 'چه کاری می‌خواهید انجام دهید؟',
				selectACity: 'شهری را انتخاب کنید',
				reset: 'بازنشانی',
				travelingWithChildren: 'با کودکان سفر می‌کنید؟',
				previous: 'قبلی',
				next: 'بعدی',
				submit: 'ارسال',
				of: 'از',
				solo: 'تنها',
				partner: 'همراه',
				friends: 'دوستان',
				family: 'خانواده',
				yes: 'بله',
				no: 'نه',
				otherOptional: 'دیگر (اختیاری)',
				dream: 'هر چیزی که می‌توانید بپرورانید...',
				attractions: 'جاذبه‌های معروف',
				food: 'غذاهای خوب',
				beaches: 'سواحل',
				waterSports: 'ورزش‌های آبی',
				nightlife: 'شب‌زندگی',
				park: 'پارک‌ها و مسیرها',
				shopping: 'خرید',
				history: 'تاریخ',
				culture: 'فرهنگ',
				art: 'هنر و تئاتر',
				spa: 'استخر',
				tripError: 'هنگام برنامه‌ریزی سفر خطایی رخ داده است.',
				tripLogin: 'برای ذخیره سفر خود باید وارد شوید.',
				topAttractions: 'جاذبه‌های برتر',
				basedOnInterests: 'بر اساس علاقه‌های شما',
				plan: 'برنامه ریزی',
				whyVisit: 'چرا بازدید کنید',
				appName: 'جستجوگر شهر',
				allRightsReserved: 'تمامی حقوق محفوظ است ',
				share: 'اشتراک‌گذاری',
				shareCta: 'اشتراک گذاری با اسکن این کد QR.',
				pageUnderContruction: 'هذه الصفحة تحت الإنشاء حالياً.',
				pageNotFound: 'عذراً، الصفحة غير موجودة.',
				goHome: 'العودة إلى الصفحة الرئيسية',
				click: 'کلیک',
				hereLowercase: 'اینجا',
				update: 'به‌روزرسانی',
				guest: 'مهمان',
				admin: 'مدیر',
				explorer: 'کاوشگر',
				profile: 'پروفایل',
				name: 'نام',
				contact: 'تماس',
				darkMode: 'حالت تاریک',
				email: 'ایمیل',
				password: 'رمز عبور',
				login: 'ورود',
				register: 'ثبت نام',
				logout: 'خروج',
				resetPassword: 'بازنشانی رمز عبور',
				noAccount: 'حسابی ندارید؟',
				haveAccount: 'آیا حساب کاربری دارید؟',
				rememberPassword: 'رمز عبور خود را به یاد داشته باشید؟',
				forgotPassword: 'رمز عبور خود را فراموش کرده‌اید؟',
				updateProfileFail: 'به‌روزرسانی پروفایل ناموفق بود.',
				updateProfileSuccess: 'پروفایل با موفقیت به‌روزرسانی شد.',
				logoutSuccess: 'با موفقیت خارج شدید',
				logoutFail: 'خروج ناموفق بود. لطفا دوباره امتحان کنید.',
				results: 'نتایج',
				overview: 'مرور',
				wikiPage: 'صفحه ویکی‌پدیا',
				map: 'نقشه',
				cities: 'شهرها',
				languages: 'زبان‌ها',
				capital: 'پایتخت',
				continent: 'قاره',
				cityName: 'نام شهر',
				country: 'کشور',
				population: 'جمعیت',
				timezone: 'منطقه زمانی',
				currency: 'واحد پول',
				elevation: 'ارتفاع',
				explore: 'کاوش',
				search: 'جستجو',
				whereTo: 'کجا؟',
				noCountriesFound: 'کشوری یافت نشد.',
				topDestinations: 'برترین مقاصد برای تعطیلات بعدی شما.',
				poweredByAi: 'قدرت گرفته از هوش مصنوعی',
				beta: 'بتا',
				buildTrip: 'ساخت یک سفر در چند دقیقه',
				getPersonalized: 'دریافت یک سفر شخصی‌سازی شده برای شما.',
				startTripWithAi: 'شروع یک سفر با هوش مصنوعی',
				cityError: 'خطایی در جستجوی آن شهر رخ داد.',
				bangkok: 'بانکوک',
				thailand: 'تایلند',
				bangkokDescription:
					'به خاطر زندگی شهری پرانرژی، معابد زیبا و کاخ بزرگ شاهان، بانکوک با جذب بازدیدکنندگان به خاطر ترکیب منحصر به فرد فرهنگ سنتی و سرگرمی مدرنش مشهور است.',
				london: 'لندن',
				uk: 'پادشاهی متحده',
				londonDescription:
					'نقاط تاریخی و فرهنگی مانند موزه بریتانیا، کاخ باکینگهام و برج لندن، همراه با صحنه‌های گوناگون غذایی و منطقه تئاتر، بازدیدکنندگان را جذب می‌کند.',
				paris: 'پاریس',
				france: 'فرانسه',
				parisDescription:
					'به خاطر هنر، مد، غذا و فرهنگش معروف است. پاریس خانه مکان‌های نمادینی مانند برج ایفل، موزه لوور و کلیسای نوتردام است.',
				dubai: 'دبی',
				uae: 'امارات متحده عربی',
				dubaiDescription:
					'به خاطر خرید لوکس، معماری فوق‌العاده مدرن و صحنه شبانه‌زنده، برج خلیفه دبی، بلندترین ساختمان جهان، یک جاذبه کلیدی است.',
				singapore: 'سنگاپور',
				singaporeDescription:
					'به خاطر جمعیت چندفرهنگی، باغ‌های آینده‌نگرانه و جاذبه‌های منحصر به فرد مانند مارینا بی سندز، سنگاپور ترکیبی از فرهنگ، غذا و هنر ارائه می‌دهد.',
				nyc: 'شهر نیویورک',
				usa: 'ایالات متحده آمریکا',
				nycDescription:
					'نقاط نمادین شهر شامل تایمز اسکوئر، تئاتر‌های برادوی، مجسمه آزادی و پارک مرکزی است که آن را مرکزی برای سرگرمی، مد و فرهنگ می‌سازد.',
				istanbul: 'استانبول',
				turkey: 'ترکیه',
				istanbulDescription:
					'تاریخ غنی آن به عنوان پلی بین اروپا و آسیا در معماری آن مشهود است، با آیا صوفیا و مسجد آبی از جمله نمادهای معروف آن.',
				tokyo: 'توکیو',
				japan: 'ژاپن',
				tokyoDescription:
					'به خاطر ابر‌ساختمان‌هایش، میدان شیبویا پرانرژی، معابد تاریخی و فرهنگ پاپ پرانرژی، توکیو میکس منحصر به فردی از سنتی و مدرن ارائه می‌دهد.',
				kualaLumpur: 'کوالالامپور',
				malaysia: 'مالزی',
				kualaLumpurDescription:
					'این شهر به خاطر معماری کولونیال، مردمان مهمان‌نواز، بازارهای پرانرژی و برج‌های دوقلوی پتروناس شهرت دارد.',
				barcelona: 'برشلون',
				spain: 'اسپانیا',
				barcelonaDescription:
					'معروف به هنر و معماری، برشلون خانه چند از آثار آنتونی گاودی است، از جمله کلیسای ساگرادا فامیلیا.',
				amsterdam: 'آمستردام',
				netherlands: 'هلند',
				amsterdamDescription:
					'به خاطر میراث هنری، سیستم کانال پیچیده و خانه‌های باریک با نمای با ایزار، موزه ون گوگ و موزه رایکسموزیوم جاذبه‌های اصلی هستند.',
				rome: 'رم',
				italy: 'ایتالیا',
				romeDescription:
					'تاریخ غنی این شهر به عنوان پایتخت سابق امپراتوری روم باعث جلب بازدیدکنندگان به کولوسئوم، فروم رومی و شهر واتیکان می‌شود.',
				osaka: 'اوساکا',
				osakaDescription:
					'به خاطر معماری مدرن، شبانه‌زندگی و غذای خوراکی خیابانی، قلعه اوساکا یک نشانه کلیدی است.',
				milan: 'میلان',
				milanDescription:
					'پایتخت جهانی مد و طراحی، میلان خانه کلیسای دومو دی میلانو و صومعه سانتا ماریا دله گراتسیه است که "آخرین شام" لئوناردو داوینچی را در خود جای داده است.',
				vienna: 'وین',
				austria: 'اتریش',
				viennaDescription:
					'به خاطر میراث هنری و فکری که توسط ساکنانی مانند موتسارت، بتهوون و زیگموند فروید شکل گرفته است، شهر همچنین خانه کاخ شونبرون باروک است.',
				shanghai: 'شانگهای',
				china: 'چین',
				shanghaiDescription:
					'به خاطر آسمان‌خط لوجیازوی، بند تاریخی، خیابان‌های پرترافیک و باغ یو گسترده، شانگهای ترکیبی از سنت و توسعه پیشرو را ترکیب می‌کند.',
				prague: 'پراگ',
				czechRepublic: 'جمهوری چک',
				pragueDescription:
					'معروف به میدان شهر قدیمی، ساختمان‌های باروک رنگارنگ، کلیساهای گوتیک و ساعت فلکی قرون وسطی، پراگ تاریخ غنی و صحنه فرهنگی پرانرژی دارد.',
				losAngeles: 'لس آنجلس',
				losAngelesDescription:
					'مشهور به هالیوود، ساحل‌های وسیع و صنعت سرگرمی، تابلوی هالیوود و ارصادگاه گریفیت از جمله پربازدیدترین مکان‌های آن است.',
			},
		},
		pl: {
			translation: {
				yourTrip: 'Twoja spersonalizowana podróż',
				back: 'powrót',
				searchCountries: 'Szukaj krajów',
				planTrip: 'Zaplanuj podróż w kilka minut',
				noBrain: 'Bez mózgu? Nie ma problemu.',
				discoverNew: 'Odkryj coś nowego',
				where: 'Gdzie chcesz jechać?',
				who: 'Kto jedzie z tobą?',
				when: 'Kiedy chcesz jechać?',
				what: 'Co chciałbyś zrobić?',
				selectACity: 'Wybierz miasto',
				reset: 'Resetuj',
				travelingWithChildren: 'Podróżujesz z dziećmi?',
				previous: 'Poprzedni',
				next: 'Następny',
				submit: 'Wyślij',
				of: 'z',
				solo: 'Solo',
				partner: 'Partner',
				friends: 'Przyjaciele',
				family: 'Rodzina',
				yes: 'Tak',
				no: 'Nie',
				otherOptional: 'Inne (opcjonalne)',
				dream: 'Wszystko, o czym możesz marzyć...',
				attractions: 'Znane atrakcje',
				food: 'Świetne jedzenie',
				beaches: 'Plaże',
				waterSports: 'Sporty wodne',
				nightlife: 'Życie nocne',
				park: 'Parki i szlaki',
				shopping: 'Zakupy',
				history: 'Historia',
				culture: 'Kultura',
				art: 'Sztuka i teatr',
				spa: 'Spa',
				tripError: 'Podczas planowania podróży wystąpił błąd.',
				tripLogin: 'Musisz być zalogowany, aby zapisać swoją podróż.',
				topAttractions: 'Najważniejsze atrakcje',
				basedOnInterests: 'Na podstawie twoich zainteresowań',
				plan: 'Plan',
				whyVisit: 'Dlaczego odwiedzić',
				appName: 'Przeglądarka Miast',
				allRightsReserved: 'Wszelkie prawa zastrzeżone',
				share: 'Udostępnij',
				shareCta: 'Udostępnij, skanując ten kod QR.',
				pageUnderContruction: 'Ta strona jest obecnie w budowie.',
				pageNotFound: 'Ups, strona nie została znaleziona.',
				goHome: 'Idź do domu',
				click: 'Kliknij',
				hereLowercase: 'tutaj',
				update: 'Aktualizacja',
				guest: 'Gość',
				admin: 'Administrator',
				explorer: 'Eksplorator',
				profile: 'Profil',
				name: 'Imię',
				contact: 'Kontakt',
				darkMode: 'Tryb ciemny',
				email: 'Email',
				password: 'Hasło',
				login: 'Zaloguj',
				register: 'Zarejestruj',
				logout: 'Wyloguj',
				resetPassword: 'Resetuj hasło',
				noAccount: 'Nie masz konta?',
				haveAccount: 'Masz już konto?',
				rememberPassword: 'Pamiętasz swoje hasło?',
				forgotPassword: 'Zapomniałeś hasła?',
				updateProfileFail: 'Nie udało się zaktualizować profilu.',
				updateProfileSuccess: 'Pomyślnie zaktualizowano profil.',
				logoutSuccess: 'Wylogowano pomyślnie',
				logoutFail: 'Nie udało się wylogować. Spróbuj ponownie.',
				results: 'Wyniki',
				overview: 'Przegląd',
				wikiPage: 'Strona wikipedii',
				map: 'Mapa',
				cities: 'Miasta',
				languages: 'Języki',
				capital: 'Stolica',
				continent: 'Kontynent',
				cityName: 'Nazwa miasta',
				country: 'Kraj',
				population: 'Populacja',
				timezone: 'Strefa czasowa',
				currency: 'Waluta',
				elevation: 'Wysokość',
				explore: 'Odkrywaj',
				search: 'Szukaj',
				whereTo: 'Gdzie?',
				noCountriesFound: 'Nie znaleziono krajów.',
				topDestinations: 'Najlepsze miejsca na Twoje następne wakacje.',
				poweredByAi: 'Napędzane przez sztuczną inteligencję',
				beta: 'BETA',
				buildTrip: 'Zbuduj wycieczkę w kilka minut',
				getPersonalized:
					'Otrzymaj spersonalizowaną wycieczkę dostosowaną do Ciebie.',
				startTripWithAi: 'Rozpocznij wycieczkę z AI',
				cityError: 'Wystąpił błąd podczas wyszukiwania tego miasta.',
				bangkok: 'Bangkok',
				thailand: 'Tajlandia',
				bangkokDescription:
					'Znany z pulsu ulicznego życia, ozdobnych świątyń i majestatycznego Wielkiego Pałacu, Bangkok przyciąga odwiedzających swoim unikalnym połączeniem tradycyjnej kultury i nowoczesnej rozrywki.',
				london: 'Londyn',
				uk: 'Zjednoczone Królestwo',
				londonDescription:
					'Historyczne i kulturalne zabytki, takie jak Muzeum Brytyjskie, Pałac Buckingham i Tower of London, przyciągają odwiedzających, obok różnorodnej sceny kulinarnej i dzielnicy teatralnej.',
				paris: 'Paryż',
				france: 'Francja',
				parisDescription:
					'Słynący z sztuki, mody, gastronomii i kultury, Paryż jest domem dla ikonicznych miejsc takich jak Wieża Eiffla, Luwr i Katedra Notre-Dame.',
				dubai: 'Dubaj',
				uae: 'Zjednoczone Emiraty Arabskie',
				dubaiDescription:
					'Słynie z luksusowych zakupów, ultranowoczesnej architektury i tętniącej życiem sceny nocnej. Burdż Chalifa, najwyższy budynek na świecie, to jedna z kluczowych atrakcji Dubaju.',
				singapore: 'Singapur',
				singaporeDescription:
					'Znany ze swojego wielokulturowego społeczeństwa, futurystycznych ogrodów i unikalnych atrakcji, takich jak Marina Bay Sands, Singapur oferuje mieszankę kultury, kuchni i sztuki.',
				nyc: 'Nowy Jork',
				usa: 'USA',
				nycDescription:
					'Do ikonicznych miejsc miasta należą Times Square, teatry na Broadwayu, Statua Wolności i Central Park, co czyni go centrum rozrywki, mody i kultury.',
				istanbul: 'Stambuł',
				turkey: 'Turcja',
				istanbulDescription:
					'Jego bogata historia jako mostu między Europą a Azją jest widoczna w architekturze, a Hagia Sophia i Błękitny Meczet należą do najbardziej znanych zabytków.',
				tokyo: 'Tokio',
				japan: 'Japonia',
				tokyoDescription:
					'Znany z potężnych wieżowców, ruchliwego skrzyżowania Shibuya, historycznych świątyń i żywej kultury popowej, Tokio oferuje unikalne połączenie tradycji i nowoczesności.',
				kualaLumpur: 'Kuala Lumpur',
				malaysia: 'Malezja',
				kualaLumpurDescription:
					'Miasto słynie z kolonialnej architektury, przyjaznych mieszkańców, tętniących rynków i kultowych Wież Bliźniaczych Petronas.',
				barcelona: 'Barcelona',
				spain: 'Hiszpania',
				barcelonaDescription:
					'Słynie z sztuki i architektury, Barcelona jest domem dla kilku dzieł Antoniego Gaudí, w tym kościoła Sagrada Família.',
				amsterdam: 'Amsterdam',
				netherlands: 'Holandia',
				amsterdamDescription:
					'Znany z dziedzictwa artystycznego, rozbudowanego systemu kanałów i wąskich domów z fasetowymi fasadami, Muzeum Van Gogha i Rijksmuseum są głównymi atrakcjami.',
				rome: 'Rzym',
				italy: 'Włochy',
				romeDescription:
					'Bogata historia miasta jako dawnej stolicy Cesarstwa Rzymskiego przyciąga odwiedzających do Koloseum, Forum Rzymskiego i Watykanu.',
				osaka: 'Osaka',
				osakaDescription:
					'Znany z nowoczesnej architektury, życia nocnego i obfitej ulicznej kuchni, Zamek Osaka jest kluczową atrakcją.',
				milan: 'Mediolan',
				milanDescription:
					'Globalna stolica mody i designu, Mediolan jest domem dla gotyckiej katedry Duomo di Milano i klasztoru Santa Maria delle Grazie, gdzie znajduje się "Ostatnia Wieczerza" Leonarda da Vinci.',
				vienna: 'Wiedeń',
				austria: 'Austria',
				viennaDescription:
					'Znany ze swojego dziedzictwa artystycznego i intelektualnego kształtowanego przez mieszkańców takich jak Mozart, Beethoven i Sigmund Freud, miasto jest także domem dla barokowego pałacu Schönbrunn.',
				shanghai: 'Szanghaj',
				china: 'Chiny',
				shanghaiDescription:
					'Znany z linii horyzontu w dzielnicy Lujiazui, historycznego Bundu, ruchliwych ulic handlowych i rozległego ogrodu Yu, Szanghaj łączy tradycję z nowoczesnym rozwojem.',
				prague: 'Praga',
				czechRepublic: 'Republika Czeska',
				pragueDescription:
					'Słynie z Rynku Starego Miasta, kolorowych budynków barokowych, gotyckich kościołów i średniowiecznego Zegara Astronomicznego, Praga ma bogatą historię i dynamiczną scenę kulturalną.',
				losAngeles: 'Los Angeles',
				losAngelesDescription:
					'Znany z Hollywood, rozległych plaż i przemysłu rozrywkowego, Hollywood Sign i Obserwatorium Griffitha są jednymi z najczęściej odwiedzanych miejsc.',
			},
		},
	},
	// THIS MUST BE CONSISTENT WITH /src/utils getDefaultLanguage
	lng:
		ALL_LANGUAGE_CODES.find(
			(lng) =>
				lng ===
				localStorage.getItem(LANGUAGE_KEY)?.replace(/['"]+/g, ''),
		) ??
		ALL_LANGUAGE_CODES.find(
			(lng) => lng === navigator.language.substring(0, 2),
		) ??
		DEFAULT_LANGUAGE,
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
};
