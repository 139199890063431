import { ExternalLink, InternalLink } from '../common/Link';
import { HeadingMedium, ParagraphMedium } from 'baseui/typography';
import { useLocation, useNavigate } from 'react-router-dom';

import { CenterRow } from '../layout/CenterRow';
import { CitiesInCountry } from './CitiesInCountry';
import { CtaButton } from '../common/CtaButton';
import { LanguageContext } from '../../contexts/language/LanguageContext';
import { Loading } from '../common/Loading';
import { SectionTitle } from '../layout/SectionTitle';
import { Table } from 'baseui/table';
import { Tag } from 'baseui/tag';
import { useContext } from 'react';
import { useGetCountry } from '../../api';
import { useTranslation } from 'react-i18next';

export function CountryPage() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();
	const { language } = useContext(LanguageContext);
	const pathNames = location.pathname.split('/');
	const countryCode = pathNames[2];

	const {
		countryLoading,
		countryError,
		countryData: country,
	} = useGetCountry({
		language,
		countryCode,
	});

	if (countryLoading) return <Loading />;
	if (countryError)
		return <ParagraphMedium>{t('countryError')}</ParagraphMedium>;

	return (
		<>
			<HeadingMedium style={{ marginBottom: '1rem' }}>
				{country.flag.emoji} {country.name}
			</HeadingMedium>
			<SectionTitle text={t('overview')} />
			<Table
				data={[
					[
						t('capital'),
						<InternalLink to={`/city/${country.capital.geonameid}`}>
							{country.capital.name}
						</InternalLink>,
					],
					[t('population'), country.population.toLocaleString()],
					[t('continent'), country.continent.name],
					[t('currency'), country.currency.name],
					[
						t('timezone'),
						`${country.timezone.timezone} (${country.timezone.code})`,
					],
				]}
			/>
			<SectionTitle text={t('languages')} />
			{Object.values(country.languages).map((language) => (
				<Tag closeable={false} size="small" variant="solid">
					{language}
				</Tag>
			))}
			<SectionTitle text={t('cities')} />
			<CitiesInCountry countryCode={countryCode} />
			<CenterRow padTop>
				<ExternalLink href={country.wiki_url}>
					{country.name} {t('wikiPage')}
				</ExternalLink>
			</CenterRow>
			<CtaButton
				text={t('planTrip')}
				onClick={() => navigate(`/plan?countryCode=${countryCode}`)}
			/>
		</>
	);
}
