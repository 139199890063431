import { APIProvider, Map as GoogleMap } from '@vis.gl/react-google-maps';
import {
	GOOGLE_MAPS_CONFIG_DARK,
	GOOGLE_MAPS_CONFIG_LIGHT,
} from '../../../../config/googleMapsConfig';
import { MapMarkerT, MetaComponentPropsT } from '../../../../types';

import { Block } from 'baseui/block';
import { LanguageContext } from '../../../../contexts/language/LanguageContext';
import { MapMarker } from './MapMarker';
import { StyleContext } from '../../../../contexts/style/StyleContext';
import { useContext } from 'react';

export function Map({ component }: MetaComponentPropsT) {
	const { latitude, longitude, markers, onClickMarker } = component.value;
	const ref = component?.ref;
	const { isDarkMode } = useContext(StyleContext);
	const { language } = useContext(LanguageContext);
	return (
		<Block ref={ref}>
			<APIProvider
				// @ts-ignore
				apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
				language={language}
			>
				<GoogleMap
					style={{ width: '90vw', height: '50vh', outline: 'none' }}
					defaultCenter={{ lat: latitude, lng: longitude }}
					defaultZoom={11}
					gestureHandling={'greedy'}
					disableDefaultUI={true}
					// backgroundColor={'red'}
					mapTypeId="terrain"
					styles={
						isDarkMode
							? GOOGLE_MAPS_CONFIG_DARK
							: GOOGLE_MAPS_CONFIG_LIGHT
					}
				>
					{markers?.map((marker: MapMarkerT) => (
						<MapMarker
							marker={marker}
							onClickMarker={onClickMarker}
						/>
					))}
				</GoogleMap>
			</APIProvider>
		</Block>
	);
}
