import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { ParagraphMedium } from 'baseui/typography';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

export function SharePage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [css, theme] = useStyletron();
	return (
		<Block
			width="100%"
			display="flex"
			flexDirection="column"
			alignItems="center"
		>
			<img
				src={'/images/no-brain-qr.png'}
				alt="Logo"
				width="70%"
				height="70%"
			/>
			<ParagraphMedium $style={{ marginTop: '1rem' }}>
				{t('shareCta')}
			</ParagraphMedium>
			<Button
				size="compact"
				shape="pill"
				onClick={() => navigate('/')}
				style={{ marginTop: '2.5rem' }}
			>
				{t('goHome')}
			</Button>
		</Block>
	);
}
