import { Block } from 'baseui/block';
import { MetaComponentPropsT } from '../../../types';
import { ParagraphMedium } from 'baseui/typography';
import get from 'lodash/get';

export function DateRange({ component }: MetaComponentPropsT) {
	const dates = component.value;
	const startDate = new Date(get(dates, 0));
	const endDate = new Date(get(dates, 1));
	return (
		<Block display="flex">
			&#128197;
			<ParagraphMedium $style={{ marginLeft: '0.5rem' }}>
				{` ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
			</ParagraphMedium>
		</Block>
	);
}
