import {
	MetaComponentPropsT,
	MetaComponentT,
	TripPlanDayT,
} from '../../../../types';

import { TripPlanDay } from './TripPlanDay';

export function TripPlan({ component, state }: MetaComponentPropsT) {
	const plan = component?.value;
	if (!plan) return null;
	return plan?.map((day: TripPlanDayT, index: number) => {
		const component = {
			value: { day, dayNum: index + 1 },
		} as MetaComponentT;
		return <TripPlanDay component={component} state={state} />;
	});
}
