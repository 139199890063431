import { LocationT, MapMarkerT, TripT } from '../../types';
import { useContext, useRef, useState } from 'react';
import { useGetCity, useGetCountry } from '../../api';

import { HeadingMedium } from 'baseui/typography';
import { LanguageContext } from '../../contexts/language/LanguageContext';
import { Meta } from '../meta/Meta';
import { Table } from 'baseui/table';
import get from 'lodash/get';
import { getMarkers } from './utils';
import { scrollToRef } from '../../utils';
import { useTranslation } from 'react-i18next';

export function Trip({
	trip,
	planLoading,
	overviewLoading,
}: {
	trip: TripT | null;
	planLoading?: boolean;
	overviewLoading?: boolean;
}) {
	const { t } = useTranslation();
	const { language } = useContext(LanguageContext);
	const mapRef = useRef(null);
	const [selectedLocationId, setSelectedLocationId] = useState<
		string | null | undefined
	>(null);
	const cityGeonameid = get(trip, ['query', 'city', 'geonameid'], '');
	const countryCode = get(trip, ['query', 'city', 'country', 'code'], '');
	const { cityLoading, cityData } = useGetCity({
		language,
		cityGeonameid,
	});
	const { countryLoading, countryData } = useGetCountry({
		language,
		countryCode: countryCode ?? '',
	});
	if (!trip) return null;
	const { query, res } = trip;
	const { city, selectedDates } = query;
	const { planData, overviewData } = res;
	const { markers, locationIdToMarkerNumber } = getMarkers(trip);
	// Click on a marker on the map (MapMarker.tsx)
	const onClickMarker = (marker: MapMarkerT) =>
		setSelectedLocationId(marker.location?.id);
	// Click on a marker button next to a location item (Location.tsx)
	const onClickMarkerButton = (location: LocationT) => {
		// scroll to map & highlight the corresponding marker
		setSelectedLocationId(location?.id);
		setTimeout(() => {
			// timeout prevents useEffect of location object from overriding the scroll to mapRef
			scrollToRef(mapRef);
		}, 1);
	};
	const locationLabel = !countryData
		? `${city?.name}`
		: `${city?.name}, ${get(countryData, 'name')} ${get(countryData, ['flag', 'emoji'])}`;
	return (
		<>
			<Meta
				config={{
					title: `${t('yourTripTo')} ${locationLabel}`,
					state: {
						selectedLocationId,
						locationIdToMarkerNumber,
						onClickMarkerButton,
					},
					components: [
						{
							type: 'DateRange',
							value: selectedDates,
						},
						{
							type: 'Map',
							value: {
								latitude: city?.latitude,
								longitude: city?.longitude,
								markers,
								onClickMarker,
							},
							ref: mapRef,
						},
						{
							type: 'TripPlan',
							value: get(planData, 'plan'),
							label: 'plan',
							loading: planLoading,
						},
						{
							type: 'Paragraph',
							label: t('topReasonsToVisit'),
							value: get(overviewData, 'topReasonsToVisit'),
							loading: overviewLoading,
						},
						{
							type: 'LocationList',
							label: t('topAttractions'),
							value: get(overviewData, 'topAttractions'),
							loading: overviewLoading,
						},
						{
							type: 'LocationList',
							label: t('topNeighborhoods'),
							value: get(overviewData, 'topNeighborhoods'),
							loading: overviewLoading,
						},
						{
							type: 'LocationListCategorized',
							label: t('basedOnYourInterests'),
							value: get(overviewData, 'basedOnYourInterests'),
							loading: overviewLoading,
						},
					],
				}}
			/>
			{!countryLoading && !cityLoading && (
				<>
					<HeadingMedium
						style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
					>
						{`${t('quickFactsAbout')} ${locationLabel}`}
					</HeadingMedium>
					<Table
						data={[
							[
								t('population'),
								cityData?.population?.toLocaleString(),
							],
							[
								t('timezone'),
								`${cityData?.timezone?.timezone} (${cityData?.timezone?.code})`,
							],
							[t('currency'), cityData.currency.name],
							[
								t('elevation'),
								`${cityData.elevation.toLocaleString()} meters`,
							],
						]}
					/>
				</>
			)}
		</>
	);
}
