import {
	createDarkTheme,
	createTheme,
	darkThemePrimitives,
	lightThemePrimitives,
} from 'baseui';

export const FONT = 'Lato, sans-serif';
export const PRIMARY_COLOR = '#75cfd0';
export const SECONDARY_COLOR = '#fdad00';
export const ERROR_COLOR = '#ff383f';

export const LIGHT_THEME = createTheme({
	...lightThemePrimitives,
	primaryFontFamily: FONT,
	primary: PRIMARY_COLOR,
});

export const DARK_THEME = createDarkTheme({
	...darkThemePrimitives,
	primaryFontFamily: FONT,
	primary: PRIMARY_COLOR,
});
