export type AppConfigT = {
	// Metadata
	appName: string;
	adminCopywrite: string;
	adminEmail: string;
	description: string;
	keywords: Array<string>;
	faviconPath: string;
	// Contact
	phoneNumber: string;
	email: string;
};
export const APP_CONFIG: AppConfigT = {
	appName: 'No Brain Travel',
	adminCopywrite: '',
	adminEmail: 'admin@nobraintravel.com',
	description: 'Plan trips easily and for free.',
	keywords: ['No Brain Travel', 'rental'],
	faviconPath: '/images/zombie-vacation-icon.png',
	phoneNumber: '+1(307)400-8617',
	email: 'citycrawlerllc@gmail.com',
};
