import React, { ReactNode, useContext, useMemo } from 'react';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { AuthPage } from '../auth/AuthPage';
import { Navbar } from './Navbar';
import { StyleContext } from '../../contexts/style/StyleContext';
import { useStyletron } from 'baseui';

const MARGINS_ENABLED_STYLES = {
	justifyContent: 'center',
	alignItems: 'center',
	marginLeft: '3vw',
	marginRight: '3vw',
	paddingTop: '2vh',
	paddingBottom: '2vh',
};
const MARGINS_DISABLED_STYLES = {
	justifyContent: 'center',
	alignItems: 'center',
};

export function ScreenContainer({
	children,
	options,
}: {
	children: ReactNode;
	options?: {
		disableMargins?: boolean;
		requireAuth?: boolean;
	};
}) {
	const [css] = useStyletron();
	const { backgroundColor } = useContext(StyleContext);
	const { user } = useContext(AuthContext);

	const styles =
		options?.disableMargins === true
			? MARGINS_DISABLED_STYLES
			: MARGINS_ENABLED_STYLES;

	// const FooterContent = () => (
	// 	<Footer>
	// 		<HeadingSmall>
	// 			<Branding />
	// 			<Block display="flex" justifyContent="center">
	// 				<ParagraphXSmall>
	// 					{/* TODO update this once I change the business name */}
	// 					{`© 2024 City Crawler LLC ${t('allRightsReserved')}`}
	// 				</ParagraphXSmall>
	// 			</Block>
	// 		</HeadingSmall>
	// 	</Footer>
	// );

	const content: ReactNode = useMemo(() => {
		if (options?.requireAuth && !user) {
			return <AuthPage authMode="login" />;
		}
		return children as ReactNode;
	}, [children, options?.requireAuth, user]);

	return (
		<div
			className={css({
				position: 'relative',
				minHeight: '100vh',
				background: backgroundColor,
			})}
		>
			<Navbar />
			<div className={css(styles)}>{content}</div>
			{/* <FooterContent /> */}
		</div>
	);
}
