import { Card, StyledAction, StyledBody } from 'baseui/card';
import { useContext, useState } from 'react';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { AuthModal } from '../auth/AuthModal';
import { Button } from 'baseui/button';
import { GptCityT } from '../../types';
import { sendPositiveNotification } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const CityCard = (city: GptCityT) => {
	const { t } = useTranslation();
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();
	const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

	const redirectUrl = `/plan?countryCode=${city.countryCode}&city=${JSON.stringify(city.city)}`;

	const handleCityClick = () => {
		if (user) {
			navigate(redirectUrl);
		} else {
			sendPositiveNotification(t('loginToPlanTrip'));
			setIsAuthModalOpen(true);
		}
	};
	return (
		<>
			<Card
				overrides={{ Root: { style: { marginTop: '2vh' } } }}
				headerImage={`images/cities/${city.image}.jpg`}
				title={`${t(city.name)}, ${t(city.country)}`}
			>
				<StyledBody>{t(city.description)}</StyledBody>
				<StyledAction>
					<Button
						onClick={handleCityClick}
						overrides={{ BaseButton: { style: { width: '100%' } } }}
					>
						{t('explore')}
					</Button>
				</StyledAction>
			</Card>
			<AuthModal
				isOpen={isAuthModalOpen && !user}
				onClose={() => setIsAuthModalOpen(false)}
				successRedirectUrl={redirectUrl}
			/>
		</>
	);
};
