import { Dispatch, ReactNode, SetStateAction } from 'react';

import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { Footer } from './Footer';
import { PRIMARY_COLOR } from '../../config/themeConfig';
import { ProgressBar } from 'baseui/progress-bar';
import { useTranslation } from 'react-i18next';

export function Steps({
	step,
	setStep,
	numSteps,
	nextButtonEnabled,
	children,
}: {
	step: number;
	setStep: Dispatch<SetStateAction<number>>;
	numSteps: number;
	nextButtonEnabled: boolean;
	children: ReactNode;
}) {
	const { t } = useTranslation();
	const isFinalStep = step === numSteps - 1;
	const isResultStep = step === numSteps;
	const backButtonEnabled = step > 1;
	return (
		<Block
			width="100%"
			display="flex"
			flexDirection="column"
			alignItems="center"
			marginBottom={!isResultStep ? '7rem' : 0}
		>
			{step < 5 && (
				<ProgressBar
					value={step}
					minValue={0}
					maxValue={5}
					overrides={{
						Root: {
							style: {
								marginBottom: '1rem',
							},
						},
						BarProgress: {
							style: {
								backgroundColor: PRIMARY_COLOR,
							},
						},
					}}
				/>
			)}
			{children}
			{!isResultStep && (
				<Footer>
					<Block
						width="100%"
						padding="1rem"
						paddingLeft="2rem"
						paddingRight="2rem"
						display="flex"
						flexDirection="row"
						justifyContent={
							backButtonEnabled ? 'space-between' : 'flex-end'
						}
					>
						{backButtonEnabled && (
							<Button
								size="large"
								kind="tertiary"
								onClick={() => setStep((s) => s - 1)}
							>
								{t('back')}
							</Button>
						)}
						<Button
							size="large"
							disabled={!nextButtonEnabled}
							onClick={() => setStep((s) => s + 1)}
						>
							{isFinalStep ? t('submit') : t('next')}
						</Button>
					</Block>
				</Footer>
			)}
		</Block>
	);
}
