import {
	LanguageCodeT,
	LanguageLabelT,
} from '../../components/common/Language';
import React, { ReactNode } from 'react';

import { LANGUAGE_KEY } from '../../config/i18nConfig';
import { LanguageContext } from './LanguageContext';
import { getDefaultLanguage } from '../../utils';
import { useLocalStorage } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

export type LanguageOptionT = Array<{
	id: LanguageCodeT;
	label: LanguageLabelT;
}>;

export function LanguageProvider({ children }: { children: ReactNode }) {
	const { i18n } = useTranslation();
	const [language, setLanguage] = useLocalStorage<LanguageCodeT>(
		LANGUAGE_KEY,
		getDefaultLanguage(),
	);
	const handleSetLanguage = (language: LanguageCodeT) => {
		i18n.changeLanguage(language);
		setLanguage(language);
	};
	return (
		<LanguageContext.Provider
			value={{
				language,
				setLanguage: handleSetLanguage,
			}}
		>
			{children}
		</LanguageContext.Provider>
	);
}
