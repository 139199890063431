import { CenterRow } from '../layout/CenterRow';
import { PRIMARY_COLOR } from '../../config/themeConfig';
import { Spinner } from 'baseui/spinner';

export function Loading() {
	return (
		<CenterRow>
			<Spinner
				$size="large"
				// @ts-ignore
				$style={{
					borderTopColor: PRIMARY_COLOR,
					width: '15vw',
					height: '15vw',
					marginTop: '10vh',
				}}
			/>
		</CenterRow>
	);
}
