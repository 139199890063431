import {
	HeadingLarge,
	ParagraphLarge,
	ParagraphMedium,
} from 'baseui/typography';

import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { CitiesInCountry } from '../../location/CitiesInCountry';
import { CitySearchT } from '../../../types';
import { LocationSearch } from '../../location/LocationSearch';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function Where({
	countryCode,
	setCountryCode,
	city,
	setCity,
}: {
	countryCode: string | null;
	setCountryCode: (countryCode: string | null) => void;
	city: CitySearchT | null;
	setCity: (city: CitySearchT | null) => void;
}) {
	const { t } = useTranslation();
	const LocationSelection = useCallback(() => {
		const ResetButton = () => (
			<Button
				shape="pill"
				size="compact"
				kind="secondary"
				onClick={() => {
					setCity(null);
					setCountryCode(null);
				}}
			>
				{t('reset')}
			</Button>
		);
		if (city)
			return (
				<>
					<ParagraphLarge $style={{ marginBottom: '1rem' }}>
						{city.name}
					</ParagraphLarge>
					<ResetButton />
				</>
			);
		else if (countryCode)
			return (
				<>
					<ParagraphMedium $style={{ marginBottom: '1rem' }}>
						{t('selectACity')}
					</ParagraphMedium>
					<Block position="relative">
						<CitiesInCountry
							countryCode={countryCode}
							onCitySelect={(city) => setCity(city)}
						/>
					</Block>
					<ResetButton />
				</>
			);
		return (
			<LocationSearch
				onCountrySelect={(cc: string) => setCountryCode(cc)}
			/>
		);
	}, [city, countryCode, setCity, setCountryCode, t]);
	return (
		<>
			<HeadingLarge $style={{ marginBottom: '2rem' }}>
				{t('where')}
			</HeadingLarge>
			<LocationSelection />
		</>
	);
}
