import { Block } from 'baseui/block';
import { HeadingXSmall } from 'baseui/typography';
import { LocationList } from './LocationList';
import { MetaComponentPropsT } from '../../../../types';
import { getEmoji } from '../../../../utils';
import { useTranslation } from 'react-i18next';

export function LocationListCategorized({
	component,
	state,
}: MetaComponentPropsT) {
	const { t } = useTranslation();
	if (!component?.value) return null;
	return (
		<>
			{Object.entries(component.value).map((entry) => (
				<Block key={`location_list_${entry[0]}`}>
					<HeadingXSmall
						style={{ marginTop: '1rem' }}
					>{`${getEmoji(entry[0])} ${t(entry[0])}`}</HeadingXSmall>
					<LocationList
						component={{ type: 'LocationList', value: entry[1] }}
						state={state}
					/>
				</Block>
			))}
		</>
	);
}
