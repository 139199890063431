import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { HeadingXSmall } from 'baseui/typography';
import { Meta } from '../../meta/Meta';
import { TripGroupTypeT } from '../../../types';
import { useTranslation } from 'react-i18next';

export function Who({
	groupType,
	setGroupType,
	isTravelingWithChildren,
	setIsTravelingWithChildren,
}: {
	groupType: TripGroupTypeT | null;
	setGroupType: (groupType: TripGroupTypeT) => void;
	isTravelingWithChildren: boolean;
	setIsTravelingWithChildren: (isTravelingWithChildren: boolean) => void;
}) {
	const { t } = useTranslation();
	return (
		<>
			<Meta
				config={{
					title: t('who'),
					components: [
						{
							type: 'SimpleSelect',
							value: groupType,
							setValue: setGroupType,
							options: ['solo', 'partner', 'friends', 'family'],
						},
					],
				}}
			/>
			{(groupType === 'friends' || groupType === 'family') && (
				<>
					<HeadingXSmall>{t('travelingWithChildren')}</HeadingXSmall>
					<Block
						display="flex"
						width="100%"
						justifyContent="space-around"
						marginTop="1rem"
					>
						<Button
							kind={
								isTravelingWithChildren
									? 'primary'
									: 'secondary'
							}
							onClick={() => setIsTravelingWithChildren(true)}
							overrides={{
								Root: { style: { width: '40%' } },
							}}
						>
							{t('yes')}
						</Button>
						<Button
							kind={
								!isTravelingWithChildren
									? 'primary'
									: 'secondary'
							}
							onClick={() => setIsTravelingWithChildren(false)}
							overrides={{
								Root: { style: { width: '40%' } },
							}}
						>
							{t('no')}
						</Button>
					</Block>
				</>
			)}
		</>
	);
}
