import {
	ALIGN,
	HeaderNavigation,
	StyledNavigationList,
} from 'baseui/header-navigation';

import { ReactNode } from 'react';
import { useStyletron } from 'baseui';

export function Footer({ children }: { children: ReactNode }) {
	const [css] = useStyletron();
	return (
		<div
			className={css({
				position: 'absolute',
				bottom: 0,
				width: '100%',
			})}
		>
			<HeaderNavigation
				overrides={{
					Root: {
						style: {
							background: 'transparent',
							border: '0px solid transparent',
						},
					},
				}}
			>
				<StyledNavigationList $align={ALIGN.center}>
					{children}
				</StyledNavigationList>
			</HeaderNavigation>
		</div>
	);
}
