import { Block } from 'baseui/block';
import { Button } from 'baseui/button';

export function CtaButton({
	text,
	onClick,
}: {
	text: string;
	onClick: () => void;
}) {
	return (
		<Block
			position="fixed"
			bottom="5rem"
			width="100%"
			display="flex"
			justifyContent="center"
		>
			<Button size="large" shape="pill" onClick={onClick}>
				{text}
			</Button>
		</Block>
	);
}
