import { Carousel } from '../common/Carousel';
import { CityCard } from '../location/CityCard';
import { Header } from '../layout/Header';
import { TOP_20_CITIES } from '../../data/cities';
import { useTranslation } from 'react-i18next';

export function TopCities() {
	const { t } = useTranslation();
	return (
		<>
			<Header text={t('topDestinations')} />
			<Carousel items={TOP_20_CITIES} renderItem={CityCard} />
		</>
	);
}
