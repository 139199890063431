import { getDatabase, ref, remove } from 'firebase/database';

export function onDelete(
	dbPath: string,
	onSuccess?: () => void,
	onError?: (error: any) => void,
) {
	const db = getDatabase();
	remove(ref(db, dbPath)).then(onSuccess).catch(onError);
}
