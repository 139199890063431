import { AppNavBar, NavItem } from 'baseui/app-nav-bar';
import { NavLink, useNavigate } from 'react-router-dom';
import {
	sendNegativeNotification,
	sendPositiveNotification,
} from '../../utils';
import { useContext, useMemo } from 'react';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { Branding } from './Branding';
import { DarkMode } from './DarkMode';
import { Item } from 'baseui/menu';
import { LabelMedium } from 'baseui/typography';
import { Language } from './Language';
import { StyleContext } from '../../contexts/style/StyleContext';
import { auth } from '../../firebaseSetup';
import { signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

export function Navbar() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user, isAdmin } = useContext(AuthContext);
	const { isDarkMode, toggleStyleMode } = useContext(StyleContext);
	const displayName = user?.displayName ?? '';
	const usernameSubtitle = useMemo(
		() => (!user ? t('guest') : isAdmin ? t('admin') : t('explorer')),
		[isAdmin, t, user],
	);
	const handleLogout = () =>
		signOut(auth)
			.then(() => sendPositiveNotification(t('logoutSuccess')))
			.catch(() => sendNegativeNotification(t('logoutFail')));
	const darkModeItem: NavItem = {
		label: 'darkMode',
		info: { id: 'darkMode', url: null },
	};
	const languageItem: NavItem = {
		label: 'language',
		info: { id: 'language', url: null },
	};
	const guestMainItems: NavItem[] = [
		{ label: 'myTrips', info: { id: 'trips', url: '/trips' } },
		{ label: 'share', info: { id: 'share', url: '/share' } },
		darkModeItem,
		languageItem,
	];
	const adminMainItems: NavItem[] = [darkModeItem, languageItem];
	const guestUserOptions: NavItem[] = [
		{ label: 'login', info: { id: 'login', url: '/login' } },
		{ label: 'register', info: { id: 'register', url: '/register' } },
	];
	const authUserOptions: NavItem[] = [
		{ label: 'profile', info: { id: 'profile', url: '/profile' } },
		{
			label: 'subscriptions',
			info: { id: 'subscriptions', url: '/subscription' },
		},
		{
			label: 'logout',
			info: {
				id: 'logout',
				onClick: handleLogout,
			},
		},
	];
	const userItems = !!user ? authUserOptions : guestUserOptions;
	const mainItems = isAdmin ? adminMainItems : guestMainItems;

	const NavbarItem = (item: Item) => {
		const id = item.info?.id;
		if (id === 'language') {
			return <Language />;
		}
		if (id === 'darkMode') {
			return (
				<DarkMode
					isDarkMode={isDarkMode}
					toggleStyleMode={toggleStyleMode}
				/>
			);
		}
		if (id === 'logout') {
			return <LabelMedium>{t(item.label)}</LabelMedium>;
		}
		return (
			<NavLink to={item.info.url}>
				<LabelMedium>{t(item.label)}</LabelMedium>
			</NavLink>
		);
	};
	const handleItemSelect = (item: Item) => {
		if (item.info?.id === 'language') return;
		if (item.info?.id === 'darkMode') toggleStyleMode();
		if (item.info?.onClick) item.info.onClick();
		if (item.info?.url) navigate(item.info?.url);
	};
	return (
		<AppNavBar
			title={Branding()}
			mainItems={mainItems}
			userItems={userItems}
			onMainItemSelect={handleItemSelect}
			onUserItemSelect={handleItemSelect}
			mapItemToNode={NavbarItem}
			username={displayName}
			usernameSubtitle={usernameSubtitle}
			userImgUrl="../../../public/images/"
			overrides={{
				Root: {
					style: {
						background: 'transparent',
						border: '0px solid transparent',
					},
				},
			}}
		/>
	);
}
