import { CitySearchT, TripGroupTypeT } from '../types';

import { LanguageContext } from '../contexts/language/LanguageContext';
import { getNumDaysBetween } from '../utils';
import { useContext } from 'react';

const LOCATION_DETAILS_FIELDS = `{
	title: the name of the option
    description: a short description of what the option is
    url: a url to the website that represents this option
    coordinates: an object containing the following fields: {
        lat: latitude of the coordinate associated with this option
        lng: longiture of the coordinates associated with this option
    }
}`;

const usePromptWrapper = (context: string, shape: string) => {
	const { language } = useContext(LanguageContext);
	return `Return an unformatted, condensed, stringified JSON object that can be parsed with javascript JSON.parse() function with no newline characters.
		Translate all of the values to this language code: ${language}.
		The context of this request is recommendations for ${context}. 
		The object should have the following shape: {${shape}}`;
};

const getStringField = (prompt: string) => `a string representing ${prompt}`;

const getLocationsField = (prompt: string) =>
	`an array objects representing ${prompt} with the following fields: ${LOCATION_DETAILS_FIELDS}`;

export const useTripOverviewPrompt = ({
	city,
	countryCode,
	activities,
}: {
	city: CitySearchT | null;
	countryCode: string | null;
	activities: Array<string>;
}) => {
	return usePromptWrapper(
		`${city?.name} in country code ${countryCode}`,
		`topReasonsToVisit: ${getStringField('top reasons why people travel to this destination')},
		topAttractions: ${getLocationsField('top attractions in the city')}
		topNeighborhoods: ${getLocationsField('top neighborhoods to explore in the city')}
		basedOnYourInterests: an object with the following fields ${JSON.stringify(activities)}, 
		each corresponding to ${getLocationsField('the best places a user would go for that particular activity')}`,
	);
};

export const useTripPlanPrompt = ({
	city,
	countryCode,
	selectedDates,
	groupType,
	isTravelingWithChildren,
	activities,
	otherActivities,
}: {
	countryCode: string | null;
	city: CitySearchT | null;
	selectedDates: Array<string>;
	groupType: TripGroupTypeT | null;
	isTravelingWithChildren: boolean;
	activities: Array<string>;
	otherActivities: string;
}) => {
	const numDaysBetween = getNumDaysBetween(
		selectedDates[0],
		selectedDates[1],
	);
	return usePromptWrapper(
		`plan a trip to ${city?.name} in country code ${countryCode}.
		The plan should have options for ${numDaysBetween} days.
		Ensure the plan accomodates the following type of group: ${groupType}.
		The travelers ${isTravelingWithChildren ? 'are' : 'are not'} traveling with children.
		The travelers are interested in the following activities: ${JSON.stringify(activities)}.`,
		`${getPlanFieldPrompt()}`,
	);
};

const getPlanFieldPrompt = () =>
	`plan: array of objects for what the user should do each day of the trip
    with each object representing the plan for a single day containing the following fields: {
        overview: string representing an overview of what the user will be doing that day 
        options: array of objects representing 3 things to do for the day which are not meals
        and should be represented as an object with the following fields: {
            type: 
                'hotelCheckIn' if checking into hotel
                'hotelCheckOut' if checking out of hotel
                'food' if a meal recommendation
                or the value of the activity this option corresponds to
            ${LOCATION_DETAILS_FIELDS}
        }
        meals: object containing a meal recommendation for breakfast, lunch and dinner, 
        each object should have the following fields: {
            ${LOCATION_DETAILS_FIELDS}
        }
    }`;
