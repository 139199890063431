import { CreateTripCard } from '../trip/CreateTripCard';
import { TopCities } from '../location/TopCities';

export function HomePage() {
	return (
		<>
			{/* Plan a trip */}
			<CreateTripCard />
			{/* Cities to visit */}
			<TopCities />
		</>
	);
}
