import {
	CitySearchT,
	OverviewDataT,
	PlanDataT,
	TripGroupTypeT,
} from '../../../types';
import { addIdsToOverviewData, addIdsToPlanData } from '../utils';
import { getDatabase, ref, set } from 'firebase/database';
import {
	sendNegativeNotification,
	sendPositiveNotification,
} from '../../../utils';
import { useContext, useMemo, useState } from 'react';
import {
	useTripOverviewPrompt,
	useTripPlanPrompt,
} from '../../../prompts/planTripPrompts';

import { AuthContext } from '../../../contexts/auth/AuthContext';
import { AuthModal } from '../../auth/AuthModal';
import { CtaButton } from '../../common/CtaButton';
import { Loading } from '../../common/Loading';
import { ParagraphMedium } from 'baseui/typography';
import { Trip } from '../Trip';
import { TripT } from '../../../types';
import { getTripPath } from '../../../apiPaths';
import { useGpt } from '../../../api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export function Result({
	countryCode,
	city,
	selectedDates,
	groupType,
	isTravelingWithChildren,
	activities,
	otherActivities,
}: {
	countryCode: string | null;
	city: CitySearchT | null;
	selectedDates: Array<string>;
	groupType: TripGroupTypeT | null;
	isTravelingWithChildren: boolean;
	activities: Array<string>;
	otherActivities: string;
}) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const db = getDatabase();

	const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
	const [isTripSaved, setIsTripSaved] = useState(false);

	const { user } = useContext(AuthContext);

	const tripId = uuid();

	// Trip overview
	const {
		data: overviewData,
		loading: overviewLoading,
		error: overviewError,
	} = useGpt(
		useTripOverviewPrompt({
			city,
			countryCode,
			activities,
		}),
		'tripOverview',
	);

	// Trip plan
	const {
		data: planData,
		loading: planLoading,
		error: planError,
	} = useGpt(
		useTripPlanPrompt({
			city,
			countryCode,
			selectedDates,
			groupType,
			isTravelingWithChildren,
			activities,
			otherActivities,
		}),
		'tripPlan',
	);

	// @ts-ignore
	const trip: TripT = useMemo(
		() => ({
			id: tripId,
			query: {
				countryCode,
				city,
				selectedDates,
				groupType,
				isTravelingWithChildren,
				activities,
				otherActivities,
			},
			res: {
				planData: addIdsToPlanData(planData as PlanDataT),
				overviewData: addIdsToOverviewData(
					overviewData as OverviewDataT,
				),
			},
		}),
		[
			activities,
			city,
			countryCode,
			groupType,
			isTravelingWithChildren,
			otherActivities,
			overviewData,
			planData,
			selectedDates,
			tripId,
		],
	);

	const saveTripText = useMemo(
		() =>
			user && isTripSaved
				? t('yourTrips')
				: user
					? t('saveTrip')
					: t('tripLogin'),
		[isTripSaved, t, user],
	);

	if (overviewError || planError) {
		return <ParagraphMedium>{t('tripError')}</ParagraphMedium>;
	}

	if (overviewLoading || planLoading) {
		return <Loading />;
	}

	const handleSaveTrip = () => {
		if (!user) {
			setIsAuthModalOpen(true);
			return;
		}
		if (isTripSaved) {
			navigate('/trips');
			return;
		}
		const dbPath = getTripPath(user.uid, tripId);
		set(ref(db, dbPath), trip)
			.then(() => {
				setIsTripSaved(true);
				sendPositiveNotification(t('tripSaveSuccess'));
			})
			.catch((error) => sendNegativeNotification(t('tripSaveError')));
	};

	return (
		<>
			<Trip
				trip={trip}
				planLoading={planLoading}
				overviewLoading={overviewLoading}
			/>
			<CtaButton onClick={handleSaveTrip} text={saveTripText} />
			<AuthModal
				isOpen={isAuthModalOpen && !user}
				onClose={() => setIsAuthModalOpen(false)}
			/>
		</>
	);
}
