import { Checkbox } from 'baseui/checkbox';
import { useTranslation } from 'react-i18next';

export function DarkMode({
	isDarkMode,
	toggleStyleMode,
}: {
	isDarkMode: boolean;
	toggleStyleMode: () => void;
}) {
	const { t } = useTranslation();
	return (
		<Checkbox checked={isDarkMode} onChange={toggleStyleMode}>
			{t('darkMode')}
		</Checkbox>
	);
}
