import { ReactNode, useContext } from 'react';

import { PRIMARY_COLOR } from '../../config/themeConfig';
import { StyleContext } from '../../contexts/style/StyleContext';

export function CardWithBackgroundImageAndGradient({
	backgroundUrl,
	gradientColor,
	gradientDirection,
	fitBackground,
	children,
}: {
	backgroundUrl: string;
	gradientColor: 'primary' | 'background' | 'transparent';
	gradientDirection:
		| 'to top'
		| 'to bottom'
		| 'to left'
		| 'to right'
		| 'to top left'
		| 'to top right'
		| 'to bottom left'
		| 'to bottom right';
	fitBackground: boolean; // fit image to size of card
	children: ReactNode;
}) {
	const { backgroundColor } = useContext(StyleContext);
	const backgroundGradientColor =
		gradientColor === 'transparent'
			? 'transparent'
			: gradientColor === 'primary'
				? PRIMARY_COLOR
				: backgroundColor;
	const backgroundImage = `linear-gradient(${gradientDirection}, ${backgroundGradientColor}, transparent), url(${backgroundUrl})`;
	const backgroundSize = fitBackground ? 'cover' : 'auto';
	return (
		<div
			style={{
				position: 'relative',
				textAlign: 'center',
				width: '100%',
				borderRadius: '3rem',
				backgroundImage,
				backgroundSize,
				padding: '2rem',
				marginBottom: '2rem',
			}}
		>
			{children}
		</div>
	);
}
