import { Block } from 'baseui/block';
import { ParagraphXSmall } from 'baseui/typography';
import { StyleContext } from '../../../../contexts/style/StyleContext';
import markerPng from '../../../../img/marker.png';
import { useContext } from 'react';
export function MapMarkerButton({
	text,
	onClick,
}: {
	text: string;
	onClick: () => void;
}) {
	const { backgroundColor } = useContext(StyleContext);
	const number = parseInt(text);
	const isDoubleDigit = number > 9;
	return (
		<Block
			height="100%"
			width="100%"
			display="flex"
			justifyContent="center"
			alignItems="center"
			style={{ cursor: 'pointer' }}
			onClick={onClick}
		>
			<Block position="relative" display="inline-block">
				<Block
					position="absolute"
					left={isDoubleDigit ? '1.05rem' : '1.25rem'}
					top="0.45rem"
				>
					<ParagraphXSmall
						style={{
							paddingLeft: '0.25rem',
							paddingRight: '0.25rem',
							textShadow: `0.75px 0 0 ${backgroundColor}, 0 -0.75px 0 ${backgroundColor}, 0 0.75px 0 ${backgroundColor}, -0.75px 0 0 ${backgroundColor}`,
						}}
					>
						{text}
					</ParagraphXSmall>
				</Block>
				<img
					src={markerPng}
					alt="map marker"
					height="55rem"
					width="55rem"
				/>
			</Block>
		</Block>
	);
}
