import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import { MetaComponentT } from '../../../../types';
import { PRIMARY_COLOR } from '../../../../config/themeConfig';
import { useTranslation } from 'react-i18next';

export function SimpleSelectOption({
	component,
}: {
	component: MetaComponentT;
}) {
	const { t } = useTranslation();
	const { value, setValue, option } = component;
	return (
		<Block
			onClick={() => setValue && setValue(option)}
			overrides={{
				Block: {
					style: {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						cursor: 'pointer',
						marginBottom: '0.5rem',
					},
				},
			}}
		>
			<Card
				overrides={{
					Root: {
						style: {
							borderColor:
								option === value ? PRIMARY_COLOR : null,
							width: '80%',
						},
					},
				}}
			>
				{t(option)}
			</Card>
		</Block>
	);
}
