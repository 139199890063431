import { CustomLink, InternalLink } from '../common/Link';
import { useContext, useEffect, useState } from 'react';

import { CenterRow } from '../layout/CenterRow';
import { CitySearchT } from '../../types';
import { LanguageContext } from '../../contexts/language/LanguageContext';
import { Loading } from '../common/Loading';
import { PRIMARY_COLOR } from '../../config/themeConfig';
import { Pagination } from 'baseui/pagination';
import { ParagraphMedium } from 'baseui/typography';
import { Spinner } from 'baseui/spinner';
import { Table } from 'baseui/table';
import get from 'lodash/get';
import { useGetCitiesInCountry } from '../../api';
import { useTranslation } from 'react-i18next';

const NUM_CITIES_PER_PAGE = 4;
const MIN_POPULATION_DEFAULT = 400000;

export function CitiesInCountry({
	countryCode,
	onCitySelect,
}: {
	countryCode: string;
	onCitySelect?: (city: CitySearchT) => void;
}) {
	const { t } = useTranslation();
	const { language } = useContext(LanguageContext);
	const [currentCitiesPage, setCurrentCitiesPage] = useState(1);
	const [numCitiesPages, setNumCitiesPages] = useState<number | null>(null);
	const {
		citiesInCountryLoading,
		citiesInCountryError,
		citiesInCountryData,
	} = useGetCitiesInCountry({
		page: currentCitiesPage,
		perPage: NUM_CITIES_PER_PAGE,
		minPopulation: MIN_POPULATION_DEFAULT,
		language,
		countryCode,
	});

	useEffect(() => {
		!numCitiesPages &&
			setNumCitiesPages(get(citiesInCountryData, 'total_pages'));
	}, [citiesInCountryData, numCitiesPages]);

	if (!countryCode || citiesInCountryLoading) return <Loading />;

	if (citiesInCountryError)
		<ParagraphMedium>Error fetching cities</ParagraphMedium>;

	const CitiesTable = () => {
		const cities = get(citiesInCountryData, 'cities');
		if (citiesInCountryLoading || !cities) {
			const loadingData = [];
			for (let i = 0; i < NUM_CITIES_PER_PAGE; i++) {
				loadingData.push([
					<Spinner
						$size="small"
						// @ts-ignore
						$style={{
							borderTopColor: PRIMARY_COLOR,
						}}
					/>,
				]);
			}
			return (
				<Table
					columns={[t('cityName'), t('population')]}
					data={loadingData}
				/>
			);
		} else {
			const getLinkedCityName = (city: CitySearchT) => {
				if (onCitySelect)
					return (
						<CustomLink onClick={() => onCitySelect(city)}>
							{city.name}
						</CustomLink>
					);
				else
					return (
						<InternalLink to={`/city/${city.geonameid}`}>
							{city.name}
						</InternalLink>
					);
			};
			const citiesData = cities.map((city) => [
				getLinkedCityName(city),
				city.population.toLocaleString(),
			]);
			return (
				<Table
					columns={[t('cityName'), t('population')]}
					data={citiesData}
				/>
			);
		}
	};

	return (
		<>
			<CitiesTable />
			<CenterRow>
				<Pagination
					numPages={numCitiesPages ?? 1}
					currentPage={currentCitiesPage}
					onPageChange={({ nextPage }) => {
						setCurrentCitiesPage(
							Math.min(
								Math.max(nextPage, 1),
								numCitiesPages ?? 1,
							),
						);
					}}
					labels={{
						prevButton: t('previous'),
						nextButton: t('next'),
						preposition: t('of'),
					}}
				/>
			</CenterRow>
		</>
	);
}
