import { ListItem, ListItemLabel } from 'baseui/list';
import { LocationT, MetaComponentPropsT } from '../../../../types';
import { getEmoji, scrollToRef } from '../../../../utils';
import { useEffect, useRef } from 'react';

import { Block } from 'baseui/block';
import { Link } from 'react-router-dom';
import { MapMarkerButton } from '../map/MapMarkerButton';
import { PRIMARY_COLOR } from '../../../../config/themeConfig';
import { ParagraphMedium } from 'baseui/typography';
import { StyledLink } from 'baseui/link';
import { TriangleRight } from 'baseui/icon';
import get from 'lodash/get';

export function Location({ component, state }: MetaComponentPropsT) {
	const locationRef = useRef(null);
	const location: LocationT = component.value;
	const locationId = get(location, 'id');
	const locationMarkerNumber = locationId
		? get(state, ['locationIdToMarkerNumber', locationId])
		: null;
	const isSelected = get(state, 'selectedLocationId') === get(location, 'id');
	const onClickMarkerButton = get(state, 'onClickMarkerButton');
	useEffect(() => {
		isSelected && scrollToRef(locationRef);
	}, [isSelected]);

	if (!location) return null;
	const overrides = isSelected
		? {
				border: `1px solid ${PRIMARY_COLOR}`,
			}
		: {};
	return (
		<ListItem
			ref={locationRef}
			artwork={() =>
				location?.type ? (
					<Block style={{ fontSize: '30px' }}>
						{getEmoji(location?.type)}
					</Block>
				) : (
					<TriangleRight color={PRIMARY_COLOR} size="30px" />
				)
			}
			endEnhancer={() => (
				<MapMarkerButton
					text={locationMarkerNumber}
					// @ts-ignore
					onClick={() => onClickMarkerButton(location)}
				/>
			)}
			overrides={{
				Root: { style: { ...overrides } },
			}}
		>
			<ListItemLabel>
				<Link to={location.url} target="__blank">
					<StyledLink $style={{ maxWidth: '60vw' }}>
						{location.title}
					</StyledLink>
				</Link>
				<ParagraphMedium $style={{ maxWidth: '60vw' }}>
					{location.description}
				</ParagraphMedium>
			</ListItemLabel>
		</ListItem>
	);
}
