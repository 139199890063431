import { ReactNode } from 'react';

export function CenterRow({
	padTop,
	children,
}: {
	padTop?: boolean;
	children: ReactNode;
}) {
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				paddingBottom: '2rem',
				paddingTop: padTop ? '2rem' : '0rem',
			}}
		>
			{children}
		</div>
	);
}
