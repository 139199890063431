import { Modal, ModalBody } from 'baseui/modal';

import { AuthPage } from './AuthPage';

export function AuthModal({
	isOpen,
	onClose,
	successRedirectUrl,
}: {
	isOpen: boolean;
	onClose: () => void;
	successRedirectUrl?: string;
}) {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalBody>
				<AuthPage
					authMode="login"
					successRedirectUrl={successRedirectUrl}
				/>
			</ModalBody>
		</Modal>
	);
}
