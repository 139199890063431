import { HeadingMedium, ParagraphMedium } from 'baseui/typography';
import React, { useContext, useEffect, useState } from 'react';
import {
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
} from 'firebase/auth';
import {
	sendNegativeNotification,
	sendPositiveNotification,
} from '../../utils';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { StyledLink } from 'baseui/link';
import { auth } from '../../firebaseSetup';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'styletron-react';
import { useTranslation } from 'react-i18next';

type AuthMode = 'login' | 'register' | 'resetPassword';

export function AuthPage({
	authMode,
	successRedirectUrl,
}: {
	authMode: AuthMode;
	successRedirectUrl?: string;
}) {
	const { t } = useTranslation();
	const [css] = useStyletron();
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [selectedAuthMode, setSelectedAuthMode] =
		useState<AuthMode>(authMode);

	const authModeData = {
		login: {
			name: t('login'),
			switchTextPrepend: t('noAccount'),
			switchTextPostpend: t('register'),
			switchValue: 'register' as AuthMode,
		},
		register: {
			name: t('register'),
			switchTextPrepend: t('haveAccount'),
			switchTextPostpend: t('login'),
			switchValue: 'login' as AuthMode,
		},
		resetPassword: {
			name: t('resetPassword'),
			switchTextPrepend: t('rememberPassword'),
			switchTextPostpend: t('login'),
			switchValue: 'login' as AuthMode,
		},
	};

	const { name, switchTextPostpend, switchTextPrepend, switchValue } =
		authModeData[selectedAuthMode];

	const resetForm = () => {
		setEmail('');
		setPassword('');
	};
	const handleSubmit = () => {
		switch (selectedAuthMode) {
			case 'login':
				signInWithEmailAndPassword(auth, email, password)
					.then(() => sendPositiveNotification(t('signInSuccess')))
					.catch(() => sendNegativeNotification(t('signInFailure')));
				resetForm();
				break;
			case 'register':
				createUserWithEmailAndPassword(auth, email, password)
					.then(() =>
						sendPositiveNotification(t('createAccountSuccess')),
					)
					.catch(() =>
						sendNegativeNotification(t('createAccountFailure')),
					);
				resetForm();
				break;
			case 'resetPassword':
				sendPasswordResetEmail(auth, email)
					.then(() => {
						sendPositiveNotification(
							`${t('resetPasswordSuccess')}${email}.`,
						);
						resetForm();
						navigate('/');
					})
					.catch(() =>
						sendPositiveNotification(
							`${t('resetPasswordFailure')}${email}.`,
						),
					);
				break;
		}
	};

	useEffect(() => {
		if (!!user && successRedirectUrl) navigate(successRedirectUrl);
		else if (!!user && !successRedirectUrl) navigate('/');
	}, [navigate, successRedirectUrl, user]);

	return (
		<>
			<HeadingMedium>{name}</HeadingMedium>
			<ParagraphMedium>
				{switchTextPrepend}{' '}
				<StyledLink
					onClick={() => setSelectedAuthMode(switchValue)}
					$style={{ cursor: 'pointer' }}
				>
					{switchTextPostpend}
				</StyledLink>
				{'.'}
			</ParagraphMedium>
			<FormControl label={t('email')}>
				<Input
					onChange={(e) => setEmail(e.target.value)}
					value={email}
				/>
			</FormControl>
			{selectedAuthMode !== 'resetPassword' && (
				<FormControl label={t('password')}>
					<Input
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						type="password"
					/>
				</FormControl>
			)}
			<Button onClick={handleSubmit} style={{ width: '100%' }}>
				{name}
			</Button>
			{selectedAuthMode === 'login' && (
				<ParagraphMedium className={css({ marginTop: '1rem' })}>
					{t('forgotPassword')} {t('click')}{' '}
					<StyledLink
						onClick={() => setSelectedAuthMode('resetPassword')}
						$style={{ cursor: 'pointer' }}
					>
						{t('hereLowercase')}
					</StyledLink>
					.
				</ParagraphMedium>
			)}
		</>
	);
}
