import { MapMarkerT } from '../../../../types';
import { Marker } from '@vis.gl/react-google-maps';
import { StyleContext } from '../../../../contexts/style/StyleContext';
import markerPng from '../../../../img/marker.png';
import { useContext } from 'react';
import { useStyletron } from 'styletron-react';

export function MapMarker({
	marker,
	onClickMarker,
}: {
	marker: MapMarkerT;
	onClickMarker?: (marker: MapMarkerT) => void;
}) {
	const { textColor, backgroundColor } = useContext(StyleContext);
	const [css] = useStyletron();
	const className = css({
		marginBottom: '0.9rem',
		textShadow: `0.75px 0 0 ${backgroundColor}, 0 -0.75px 0 ${backgroundColor}, 0 0.75px 0 ${backgroundColor}, -0.75px 0 0 ${backgroundColor}`,
	});
	return (
		<Marker
			position={marker.location.coordinates}
			onClick={() => onClickMarker && onClickMarker(marker)}
			// @ts-ignore
			options={{
				icon: markerPng,
			}}
			label={{
				text: marker?.label,
				color: textColor,
				fontSize: '11px',
				className,
			}}
		/>
	);
}
