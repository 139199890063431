import './index.css';

import { PLACEMENT, ToasterContainer } from 'baseui/toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AuthPage } from './components/auth/AuthPage';
import { AuthProvider } from './contexts/auth/AuthProvider';
import { CityPage } from './components/location/CityPage';
import { ConfigProvider } from './contexts/config/ConfigProvider';
import { CountryPage } from './components/location/CountryPage';
import { HomePage } from './components/home/HomePage';
import { I18N_CONFIG } from './config/i18nConfig';
import { LanguageProvider } from './contexts/language/LanguageProvider';
import { NotFoundPage } from './components/NotFoundPage';
import { ProfilePage } from './components/auth/ProfilePage';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ScreenContainer } from './components/common/ScreenContainer';
import { SharePage } from './components/SharePage';
import { StyleProvider } from './contexts/style/StyleProvider';
import { SubscriptionPage } from './components/payment/SubscriptionPage';
import { TestPage } from './components/test/TestPage';
import { TripDashboardPage } from './components/trip/TripDashboardPage';
import { TripMutatePage } from './components/trip/TripMutatePage';
import { TripPage } from './components/trip/TripPage';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const queryClient = new QueryClient();

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ScreenContainer>
				<HomePage />
			</ScreenContainer>
		),
	},
	{
		path: '/register',
		element: (
			<ScreenContainer>
				<AuthPage authMode="register" successRedirectUrl="/" />
			</ScreenContainer>
		),
	},
	{
		path: '/login',
		element: (
			<ScreenContainer>
				<AuthPage authMode="login" successRedirectUrl="/" />
			</ScreenContainer>
		),
	},
	{
		path: '/resetPassword',
		element: (
			<ScreenContainer>
				<AuthPage authMode="resetPassword" successRedirectUrl="/" />
			</ScreenContainer>
		),
	},
	{
		path: '/profile',
		element: (
			<ScreenContainer options={{ requireAuth: true }}>
				<ProfilePage />
			</ScreenContainer>
		),
	},
	{
		path: '/share',
		element: (
			<ScreenContainer options={{ requireAuth: true }}>
				<SharePage />
			</ScreenContainer>
		),
	},
	{
		path: '/plan',
		element: (
			<ScreenContainer options={{ requireAuth: true }}>
				<TripMutatePage />
			</ScreenContainer>
		),
	},
	{
		path: '/trips',
		element: (
			<ScreenContainer options={{ requireAuth: true }}>
				<TripDashboardPage />
			</ScreenContainer>
		),
	},
	{
		path: '/trips/:id',
		element: (
			<ScreenContainer options={{ requireAuth: true }}>
				<TripPage />
			</ScreenContainer>
		),
	},
	{
		path: '/country/:id',
		element: (
			<ScreenContainer>
				<CountryPage />
			</ScreenContainer>
		),
	},
	{
		path: '/city/:id',
		element: (
			<ScreenContainer>
				<CityPage />
			</ScreenContainer>
		),
	},
	{
		path: '/subscription',
		element: (
			<ScreenContainer>
				<SubscriptionPage />
			</ScreenContainer>
		),
	},
	{
		path: '/test',
		element: (
			<ScreenContainer>
				<TestPage />
			</ScreenContainer>
		),
	},
	{
		path: '*',
		element: <NotFoundPage />,
	},
]);

i18n.use(initReactI18next).init(I18N_CONFIG);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);
root.render(
	<React.StrictMode>
		<ConfigProvider>
			<StyleProvider>
				<LanguageProvider>
					<AuthProvider>
						<QueryClientProvider client={queryClient}>
							<ToasterContainer
								placement={PLACEMENT.bottomRight}
								autoHideDuration={5000}
							>
								<RouterProvider router={router} />
								{/* <App /> */}
							</ToasterContainer>
						</QueryClientProvider>
					</AuthProvider>
				</LanguageProvider>
			</StyleProvider>
		</ConfigProvider>
	</React.StrictMode>,
);
