import { HeadingLarge, ParagraphMedium } from 'baseui/typography';

import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { STRIPE_SUBSCRIPTION_URL } from '../../constants';
import { useTranslation } from 'react-i18next';

export function SubscriptionPage() {
	const { t } = useTranslation();
	return (
		<Block display="flex" flexDirection="column" alignItems="center">
			<HeadingLarge
				overrides={{ Block: { style: { marginBottom: '1rem' } } }}
			>
				{t('subscribeForMore')}
			</HeadingLarge>
			<ParagraphMedium
				overrides={{ Block: { style: { marginBottom: '0.75rem' } } }}
			>
				{t('onePlan')}
			</ParagraphMedium>
			<Button
				onClick={() => window.open(STRIPE_SUBSCRIPTION_URL, '_blank')}
			>
				{t('subscribe')}
			</Button>
		</Block>
	);
}
