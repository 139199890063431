import { Block } from 'baseui/block';
import { FormControl } from 'baseui/form-control';
import { HeadingLarge } from 'baseui/typography';
import { Input } from 'baseui/input';
import { Tag } from 'baseui/tag';
import { getEmoji } from '../../../utils';
import { useTranslation } from 'react-i18next';

// TODO improve this list / set it dynamically
const ACTIVITIES = [
	// tourism
	'attractions',
	'shopping',
	// outdoors
	'beaches',
	'parks',
	// sports
	'waterSports',
	'sportingEvents',
	// adult
	'bars',
	'clubs',
	'events',
	// high society
	'culture',
	'history',
	'art',
	// relaxation
	'spa',
	// entertainment
	'amusementParks',
	'movies',
	'arcades',
	// family
	'familyFriendly',
];

export function What({
	activities,
	setActivities,
	setOtherActivities,
}: {
	activities: Array<string>;
	setActivities: (activities: Array<string>) => void;
	setOtherActivities: (otherActivities: string) => void;
}) {
	const { t } = useTranslation();
	return (
		<Block display="flex" flexDirection="column" alignItems="center">
			<HeadingLarge marginBottom="2rem">{t('what')}</HeadingLarge>
			<Block display="flex" flexWrap={true} paddingBottom="1rem">
				{ACTIVITIES.map((activity) => {
					const isSelected = activities.includes(activity);
					return (
						<Tag
							variant={isSelected ? 'solid' : 'outlined'}
							closeable={false}
							size="large"
							overrides={{
								Text: { style: { maxWidth: 'none' } },
							}}
							onClick={() => {
								const updatedActivities = isSelected
									? activities.filter((a) => a !== activity)
									: [...activities, activity];
								setActivities(updatedActivities);
							}}
							startEnhancer={() => (
								<div>{getEmoji(activity)}</div>
							)}
						>
							{t(activity)}
						</Tag>
					);
				})}
			</Block>
			<FormControl label={t('otherOptional')}>
				<Input
					placeholder={t('dream')}
					onChange={(e) => setOtherActivities(e.target.value)}
				/>
			</FormControl>
		</Block>
	);
}
