import { DARK_THEME, LIGHT_THEME } from '../../config/themeConfig';
import React, { ReactNode } from 'react';

import { BaseProvider } from 'baseui';
import { StyleContext } from './StyleContext';
import { StyleMode } from '../../constants';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { useLocalStorage } from 'usehooks-ts';
import { useStyletron } from 'baseui';

const engine = new Styletron();

export function StyleProvider({ children }: { children: ReactNode }) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [css, theme] = useStyletron();
	const [styleMode, setStyleMode] = useLocalStorage(
		'no-brain-style-mode',
		StyleMode.LIGHT,
	);
	const toggleStyleMode = () => {
		if (styleMode === StyleMode.LIGHT) setStyleMode(StyleMode.DARK);
		else setStyleMode(StyleMode.LIGHT);
	};
	const isLightMode = styleMode === StyleMode.LIGHT;
	const isDarkMode = styleMode === StyleMode.DARK;
	const backgroundColor = isLightMode
		? theme.colors.white
		: theme.colors.black;
	const textColor = isLightMode ? theme.colors.black : theme.colors.white;
	return (
		<StyletronProvider value={engine}>
			<StyleContext.Provider
				value={{
					isLightMode,
					isDarkMode,
					toggleStyleMode,
					backgroundColor,
					textColor,
				}}
			>
				<BaseProvider theme={isLightMode ? LIGHT_THEME : DARK_THEME}>
					{children}
				</BaseProvider>
			</StyleContext.Provider>
		</StyletronProvider>
	);
}
