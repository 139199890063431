import { HeadingSmall, ParagraphMedium } from 'baseui/typography';

import { Block } from 'baseui/block';
import { LocationList } from './LocationList';
import { MetaComponentPropsT } from '../../../../types';

export function TripPlanDay({ component, state }: MetaComponentPropsT) {
	const planDay = component?.value;
	if (!planDay) return null;
	const { day, dayNum } = planDay;
	if (!day) return null;
	const locations = [
		{ ...day?.meals?.breakfast, type: 'breakfast' },
		day.options[0],
		{ ...day?.meals?.lunch, type: 'lunch' },
		day.options[1],
		{ ...day?.meals?.dinner, type: 'dinner' },
		day.options[2],
	];
	return (
		<Block marginBottom="1rem">
			<HeadingSmall>Day {dayNum}</HeadingSmall>
			<ParagraphMedium>{day.overview}</ParagraphMedium>
			<LocationList
				component={{ type: 'LocationList', value: locations }}
				state={state}
			/>
		</Block>
	);
}
