import { createContext } from 'react';

export const StyleContext = createContext<{
	isLightMode: boolean;
	isDarkMode: boolean;
	toggleStyleMode: () => void;
	backgroundColor: string;
	textColor: string;
}>({
	isLightMode: true,
	isDarkMode: false,
	toggleStyleMode: () => null,
	backgroundColor: 'white',
	textColor: 'black',
});
