import { Button } from 'baseui/button';
import { Card } from 'baseui/card';
import { Input } from 'baseui/input';
import { ReactNode } from 'react';
import { Search } from 'baseui/icon';
import { onEnterPress } from '../../utils';
import { useTranslation } from 'react-i18next';

export function SearchCard({
	searchText,
	setSearchText,
	handleSearch,
	renderFoundEntities,
	isSearchButtonDisabled,
}: {
	searchText: string;
	setSearchText: (text: string) => void;
	handleSearch: () => void;
	renderFoundEntities: () => ReactNode;
	isSearchButtonDisabled: boolean;
}) {
	const { t } = useTranslation();
	return (
		<Card overrides={{ Root: { style: { width: '100%' } } }}>
			<Input
				value={searchText}
				onChange={(e) => setSearchText(e.target.value)}
				placeholder={t('searchCountries')}
				endEnhancer={() => <Search />}
				onKeyDown={(e) => onEnterPress(e, handleSearch)}
			/>
			<Button
				onClick={handleSearch}
				disabled={isSearchButtonDisabled}
				overrides={{
					Root: { style: { width: '100%', marginTop: '1rem' } },
				}}
			>
				{t('search')}
			</Button>
			{renderFoundEntities()}
		</Card>
	);
}
