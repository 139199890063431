import { Block } from 'baseui/block';
import { ConfigContext } from '../../contexts/config/ConfigContext';
import { HeadingSmall } from 'baseui/typography';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const Branding = () => {
	const { t } = useTranslation();
	const config = useContext(ConfigContext);
	return (
		<NavLink to="/">
			<Block display="flex" width="100%" justifyContent="center">
				<img
					src={config.faviconPath}
					alt="Logo"
					width={40}
					height={40}
					style={{ paddingRight: 10 }}
				/>
				<HeadingSmall>{t('appName')}</HeadingSmall>
			</Block>
		</NavLink>
	);
};
