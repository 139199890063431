import { GptCityT } from '../types';

export const BANGKOK: GptCityT = {
	id: 1,
	name: 'bangkok',
	country: 'thailand',
	description: 'bangkokDescription',
	image: 'bangkok',
	geonameid: 1609350,
	countryCode: 'TH',
	city: {
		geonameid: 1609350,
		name: 'Bangkok',
		population: 5104476,
		latitude: 13.75398,
		longitude: 100.50144,
		division: {
			code: null,
			geonameid: 1607533,
			name: 'Phra Nakhon',
			type: 'ADM2',
		},
		country: {
			code: 'TH',
			name: 'Thailand',
			geonameid: 1605651,
			depends_on: null,
		},
		currency: {
			code: 'THB',
			name: 'Thai baht',
		},
		timezone: {
			timezone: 'Asia/Bangkok',
			gtm_offset: 25200,
			gmt_offset: 25200,
			is_daylight_saving: false,
			code: '+07',
		},
		wiki_id: 'Q1861',
		wiki_url: 'https://en.wikipedia.org/wiki/Bangkok',
		elevation: 12,
		status: 'success',
	},
};
export const LONDON: GptCityT = {
	id: 2,
	name: 'london',
	country: 'uk',
	description: 'londonDescription',
	image: 'london',
	geonameid: 2643743,
	countryCode: 'GB',
	city: {
		geonameid: 2643743,
		name: 'London',
		population: 8961989,
		latitude: 51.50853,
		longitude: -0.12574,
		division: {
			code: 'GB-LND',
			geonameid: 2648110,
			name: 'Greater London',
			type: 'ADM2',
		},
		country: {
			code: 'GB',
			name: 'United Kingdom',
			geonameid: 2635167,
			depends_on: null,
		},
		currency: {
			code: 'GBP',
			name: 'Pound sterling',
		},
		timezone: {
			timezone: 'Europe/London',
			gtm_offset: 3600,
			gmt_offset: 3600,
			is_daylight_saving: true,
			code: 'BST',
		},
		wiki_id: 'Q84',
		wiki_url: 'https://en.wikipedia.org/wiki/London',
		elevation: 25,
		status: 'success',
	},
};
export const PARIS: GptCityT = {
	id: 3,
	name: 'paris',
	country: 'france',
	description: 'parisDescription',
	image: 'paris',
	geonameid: 2988507,
	countryCode: 'FR',
	city: {
		geonameid: 2988507,
		name: 'Paris',
		population: 2138551,
		latitude: 48.85341,
		longitude: 2.3488,
		division: {
			code: 'FR-75',
			geonameid: 2968815,
			name: 'Paris',
			type: 'ADM2',
		},
		country: {
			code: 'FR',
			name: 'France',
			geonameid: 3017382,
			depends_on: null,
		},
		currency: {
			code: 'EUR',
			name: 'Euro',
		},
		timezone: {
			timezone: 'Europe/Paris',
			gtm_offset: 7200,
			gmt_offset: 7200,
			is_daylight_saving: true,
			code: 'CEST',
		},
		wiki_id: 'Q2863958',
		wiki_url: 'https://en.wikipedia.org/wiki/Arrondissement_of_Paris',
		elevation: 38,
		status: 'success',
	},
};
export const DUBAI: GptCityT = {
	id: 4,
	name: 'dubai',
	country: 'uae',
	description: 'dubaiDescription',
	image: 'dubai',
	geonameid: 292223,
	countryCode: 'AE',
	city: {
		geonameid: 292223,
		name: 'Dubai',
		population: 3478300,
		latitude: 25.07725,
		longitude: 55.30927,
		division: {
			code: 'AE-DU',
			geonameid: 292224,
			name: 'Dubai',
			type: 'ADM1',
		},
		country: {
			code: 'AE',
			name: 'United Arab Emirates',
			geonameid: 290557,
			depends_on: null,
		},
		currency: {
			code: 'AED',
			name: 'United Arab Emirates dirham',
		},
		timezone: {
			timezone: 'Asia/Dubai',
			gtm_offset: 14400,
			gmt_offset: 14400,
			is_daylight_saving: false,
			code: '+04',
		},
		wiki_id: 'Q612',
		wiki_url: 'https://en.wikipedia.org/wiki/Dubai',
		elevation: 24,
		status: 'success',
	},
};
export const SINGAPORE: GptCityT = {
	id: 5,
	name: 'singapore',
	country: 'singapore',
	description: 'singaporeDescription',
	image: 'singapore',
	geonameid: 1880252,
	countryCode: 'SG',
	city: {
		geonameid: 1880252,
		name: 'Singapore',
		population: 5638700,
		latitude: 1.28967,
		longitude: 103.85007,
		division: {
			code: null,
			geonameid: null,
			name: null,
			type: null,
		},
		country: {
			code: 'SG',
			name: 'Singapore',
			geonameid: 1880251,
			depends_on: null,
		},
		currency: {
			code: 'SGD',
			name: 'Singapore dollar',
		},
		timezone: {
			timezone: 'Asia/Singapore',
			gtm_offset: 28800,
			gmt_offset: 28800,
			is_daylight_saving: false,
			code: '+08',
		},
		wiki_id: null,
		wiki_url: null,
		elevation: 19,
		status: 'success',
	},
};
export const NEW_YORK_CITY: GptCityT = {
	id: 6,
	name: 'nyc',
	country: 'usa',
	description: 'nycDescription',
	image: 'new_york',
	geonameid: 5125771,
	countryCode: 'US',
	city: {
		geonameid: 5125771,
		name: 'Manhattan',
		population: 1487536,
		latitude: 40.78343,
		longitude: -73.96625,
		division: {
			code: null,
			geonameid: 5128594,
			name: 'New York County',
			type: 'ADM2',
		},
		country: {
			code: 'US',
			name: 'United States of America',
			geonameid: 6252001,
			depends_on: null,
		},
		currency: {
			code: 'USD',
			name: 'United States dollar',
		},
		timezone: {
			timezone: 'America/New_York',
			gtm_offset: -14400,
			gmt_offset: -14400,
			is_daylight_saving: true,
			code: 'EDT',
		},
		wiki_id: 'Q11299',
		wiki_url: 'https://en.wikipedia.org/wiki/Manhattan',
		elevation: 35,
		status: 'success',
	},
};
export const ISTANBUL: GptCityT = {
	id: 7,
	name: 'istanbul',
	country: 'turkey',
	description: 'istanbulDescription',
	image: 'istanbul',
	geonameid: 745044,
	countryCode: 'TR',
	city: {
		geonameid: 745044,
		name: 'Istanbul',
		population: 14804116,
		latitude: 41.01384,
		longitude: 28.94966,
		division: {
			code: 'TR-34',
			geonameid: 745042,
			name: 'Istanbul',
			type: 'ADM1',
		},
		country: {
			code: 'TR',
			name: 'Turkey',
			geonameid: 298795,
			depends_on: null,
		},
		currency: {
			code: 'TRY',
			name: 'Turkish lira',
		},
		timezone: {
			timezone: 'Europe/Istanbul',
			gtm_offset: 10800,
			gmt_offset: 10800,
			is_daylight_saving: false,
			code: '+03',
		},
		wiki_id: 'Q406',
		wiki_url: 'https://en.wikipedia.org/wiki/Istanbul',
		elevation: 24,
		status: 'success',
	},
};
export const TOKYO: GptCityT = {
	id: 8,
	name: 'tokyo',
	country: 'japan',
	description: 'tokyoDescription',
	image: 'tokyo',
	geonameid: 1850147,
	countryCode: 'JP',
	city: {
		geonameid: 1850147,
		name: 'Tokyo',
		population: 8336599,
		latitude: 35.6895,
		longitude: 139.69171,
		division: {
			code: 'JP-13',
			geonameid: 1850144,
			name: 'Tokyo',
			type: 'ADM1',
		},
		country: {
			code: 'JP',
			name: 'Japan',
			geonameid: 1861060,
			depends_on: null,
		},
		currency: {
			code: 'JPY',
			name: 'Japanese yen',
		},
		timezone: {
			timezone: 'Asia/Tokyo',
			gtm_offset: 32400,
			gmt_offset: 32400,
			is_daylight_saving: false,
			code: 'JST',
		},
		wiki_id: 'Q308891',
		wiki_url: 'https://en.wikipedia.org/wiki/Special_wards_of_Tokyo',
		elevation: 42,
		status: 'success',
	},
};
export const KUALA_LUMPUR: GptCityT = {
	id: 9,
	name: 'kualaLumpur',
	country: 'malaysia',
	description: 'kualaLumpurDescription',
	image: 'kuala_lumpur',
	geonameid: 1735161,
	countryCode: 'MY',
	city: {
		geonameid: 1735161,
		name: 'Kuala Lumpur',
		population: 1453975,
		latitude: 3.1412,
		longitude: 101.68653,
		division: {
			code: null,
			geonameid: 12231269,
			name: 'WP. Kuala Lumpur',
			type: 'ADM2',
		},
		country: {
			code: 'MY',
			name: 'Malaysia',
			geonameid: 1733045,
			depends_on: null,
		},
		currency: {
			code: 'MYR',
			name: 'Malaysian ringgit',
		},
		timezone: {
			timezone: 'Asia/Kuala_Lumpur',
			gtm_offset: 28800,
			gmt_offset: 28800,
			is_daylight_saving: false,
			code: '+08',
		},
		wiki_id: 'Q1865',
		wiki_url: 'https://en.wikipedia.org/wiki/Kuala_Lumpur',
		elevation: 50,
		status: 'success',
	},
};
export const SEOUL: GptCityT = {
	id: 10,
	name: 'Seoul',
	country: 'South Korea',
	description:
		'A high-tech city known for its pop culture, street markets, palaces, and the N Seoul Tower, reflecting its blend of modernity and tradition.',
	image: 'seoul',
	geonameid: 1609350,
	countryCode: 'TH',
	city: {
		geonameid: 1609350,
		name: 'Bangkok',
		population: 5104476,
		latitude: 13.75398,
		longitude: 100.50144,
		division: {
			code: null,
			geonameid: 1607533,
			name: 'Phra Nakhon',
			type: 'ADM2',
		},
		country: {
			code: 'TH',
			name: 'Thailand',
			geonameid: 1605651,
			depends_on: null,
		},
		currency: {
			code: 'THB',
			name: 'Thai baht',
		},
		timezone: {
			timezone: 'Asia/Bangkok',
			gtm_offset: 25200,
			gmt_offset: 25200,
			is_daylight_saving: false,
			code: '+07',
		},
		wiki_id: 'Q1861',
		wiki_url: 'https://en.wikipedia.org/wiki/Bangkok',
		elevation: 12,
		status: 'success',
	},
};
// export const HONG_KONG: GptCityT = {
// 	id: 11,
// 	name: 'Hong Kong',
// 	country: 'China',
// 	description:
// 		"Famous for its skyline, shopping, and varied cuisine, Victoria Peak offers stunning views of the city's landscapes.",
// 	image: 'hong_kong',
// 	geonameid: 1609350,
// };
export const BARCELONA: GptCityT = {
	id: 12,
	name: 'barcelona',
	country: 'spain',
	description: 'barcelonaDescription',
	image: 'barcelona',
	geonameid: 3128760,
	countryCode: 'ES',
	city: {
		geonameid: 3128760,
		name: 'Barcelona',
		population: 1620343,
		latitude: 41.38879,
		longitude: 2.15899,
		division: {
			code: 'ES-B',
			geonameid: 3128759,
			name: 'Barcelona',
			type: 'ADM2',
		},
		country: {
			code: 'ES',
			name: 'Spain',
			geonameid: 2510769,
			depends_on: null,
		},
		currency: {
			code: 'EUR',
			name: 'Euro',
		},
		timezone: {
			timezone: 'Europe/Madrid',
			gtm_offset: 7200,
			gmt_offset: 7200,
			is_daylight_saving: true,
			code: 'CEST',
		},
		wiki_id: 'Q1492',
		wiki_url: 'https://en.wikipedia.org/wiki/Barcelona',
		elevation: 15,
		status: 'success',
	},
};
export const AMSTERDAM: GptCityT = {
	id: 13,
	name: 'amsterdam',
	country: 'netherlands',
	description: 'amsterdamDescription',
	image: 'amsterdam',
	geonameid: 2759794,
	countryCode: 'NL',
	city: {
		geonameid: 2759794,
		name: 'Amsterdam',
		population: 741636,
		latitude: 52.37403,
		longitude: 4.88969,
		division: {
			code: null,
			geonameid: 2759793,
			name: 'Gemeente Amsterdam',
			type: 'ADM2',
		},
		country: {
			code: 'NL',
			name: 'Netherlands',
			geonameid: 2750405,
			depends_on: null,
		},
		currency: {
			code: 'EUR',
			name: 'Euro',
		},
		timezone: {
			timezone: 'Europe/Amsterdam',
			gtm_offset: 7200,
			gmt_offset: 7200,
			is_daylight_saving: true,
			code: 'CEST',
		},
		wiki_id: 'Q727',
		wiki_url: 'https://en.wikipedia.org/wiki/Amsterdam',
		elevation: 14,
		status: 'success',
	},
};
export const ROME: GptCityT = {
	id: 14,
	name: 'rome',
	country: 'italy',
	description: 'romeDescription',
	image: 'rome',
	geonameid: 3169070,
	countryCode: 'IT',
	city: {
		geonameid: 3169070,
		name: 'Rome',
		population: 2318895,
		latitude: 41.89193,
		longitude: 12.51133,
		division: {
			code: 'IT-RM',
			geonameid: 3169069,
			name: 'Province of Rome',
			type: 'ADM2',
		},
		country: {
			code: 'IT',
			name: 'Italy',
			geonameid: 3175395,
			depends_on: null,
		},
		currency: {
			code: 'EUR',
			name: 'Euro',
		},
		timezone: {
			timezone: 'Europe/Rome',
			gtm_offset: 7200,
			gmt_offset: 7200,
			is_daylight_saving: true,
			code: 'CEST',
		},
		wiki_id: 'Q220',
		wiki_url: 'https://en.wikipedia.org/wiki/Rome',
		elevation: 20,
		status: 'success',
	},
};
export const OSAKA: GptCityT = {
	id: 15,
	name: 'osaka',
	country: 'japan',
	description: 'osakaDescription',
	image: 'osaka',
	geonameid: 1853909,
	countryCode: 'JP',
	city: {
		geonameid: 1853909,
		name: 'Osaka',
		population: 2753862,
		latitude: 34.69379,
		longitude: 135.50107,
		division: {
			code: null,
			geonameid: 1853897,
			name: 'Osaka City',
			type: 'ADM2',
		},
		country: {
			code: 'JP',
			name: 'Japan',
			geonameid: 1861060,
			depends_on: null,
		},
		currency: {
			code: 'JPY',
			name: 'Japanese yen',
		},
		timezone: {
			timezone: 'Asia/Tokyo',
			gtm_offset: 32400,
			gmt_offset: 32400,
			is_daylight_saving: false,
			code: 'JST',
		},
		wiki_id: 'Q35765',
		wiki_url: 'https://en.wikipedia.org/wiki/Osaka',
		elevation: 18,
		status: 'success',
	},
};
export const MILAN: GptCityT = {
	id: 16,
	name: 'milan',
	country: 'italy',
	description: 'milanDescription',
	image: 'milan',
	geonameid: 3173435,
	countryCode: 'IT',
	city: {
		geonameid: 3173435,
		name: 'Milan',
		population: 1371498,
		latitude: 45.46427,
		longitude: 9.18951,
		division: {
			code: 'IT-MI',
			geonameid: 3173434,
			name: 'Metropolitan City of Milan',
			type: 'ADM2',
		},
		country: {
			code: 'IT',
			name: 'Italy',
			geonameid: 3175395,
			depends_on: null,
		},
		currency: {
			code: 'EUR',
			name: 'Euro',
		},
		timezone: {
			timezone: 'Europe/Rome',
			gtm_offset: 7200,
			gmt_offset: 7200,
			is_daylight_saving: true,
			code: 'CEST',
		},
		wiki_id: null,
		wiki_url: null,
		elevation: 122,
		status: 'success',
	},
};
export const VIENNA: GptCityT = {
	id: 17,
	name: 'vienna',
	country: 'austria',
	description: 'viennaDescription',
	image: 'vienna',
	geonameid: 2761369,
	countryCode: 'AT',
	city: {
		geonameid: 2761369,
		name: 'Vienna',
		population: 1691468,
		latitude: 48.20849,
		longitude: 16.37208,
		division: {
			code: null,
			geonameid: 2761333,
			name: 'Vienna',
			type: 'ADM2',
		},
		country: {
			code: 'AT',
			name: 'Austria',
			geonameid: 2782113,
			depends_on: null,
		},
		currency: {
			code: 'EUR',
			name: 'Euro',
		},
		timezone: {
			timezone: 'Europe/Vienna',
			gtm_offset: 7200,
			gmt_offset: 7200,
			is_daylight_saving: true,
			code: 'CEST',
		},
		wiki_id: 'Q1741',
		wiki_url: 'https://en.wikipedia.org/wiki/Vienna',
		elevation: 171,
		status: 'success',
	},
};
export const SHANGHAI: GptCityT = {
	id: 18,
	name: 'shanghai',
	country: 'china',
	description: 'shanghaiDescription',
	image: 'shanghai',
	geonameid: 1796236,
	countryCode: 'CN',
	city: {
		geonameid: 1796236,
		name: 'Shanghai',
		population: 22315474,
		latitude: 31.22222,
		longitude: 121.45806,
		division: {
			code: null,
			geonameid: 12324204,
			name: 'Shanghai Municipality',
			type: 'ADM2',
		},
		country: {
			code: 'CN',
			name: 'China',
			geonameid: 1814991,
			depends_on: null,
		},
		currency: {
			code: 'CNY',
			name: 'Renminbi',
		},
		timezone: {
			timezone: 'Asia/Shanghai',
			gtm_offset: 28800,
			gmt_offset: 28800,
			is_daylight_saving: false,
			code: 'CST',
		},
		wiki_id: 'Q8686',
		wiki_url: 'https://en.wikipedia.org/wiki/Shanghai',
		elevation: 11,
		status: 'success',
	},
};
export const PRAGUE: GptCityT = {
	id: 19,
	name: 'prague',
	country: 'czechRepublic',
	description: 'pragueDescription',
	image: 'prague',
	geonameid: 3067696,
	countryCode: 'CZ',
	city: {
		geonameid: 3067696,
		name: 'Prague',
		population: 1165581,
		latitude: 50.08804,
		longitude: 14.42076,
		division: {
			code: 'CZ-10',
			geonameid: 3067695,
			name: 'Prague',
			type: 'ADM1',
		},
		country: {
			code: 'CZ',
			name: 'Czech Republic',
			geonameid: 3077311,
			depends_on: null,
		},
		currency: {
			code: 'CZK',
			name: 'Czech koruna',
		},
		timezone: {
			timezone: 'Europe/Prague',
			gtm_offset: 7200,
			gmt_offset: 7200,
			is_daylight_saving: true,
			code: 'CEST',
		},
		wiki_id: 'Q1085',
		wiki_url: 'https://en.wikipedia.org/wiki/Prague',
		elevation: 203,
		status: 'success',
	},
};
export const LOS_ANGELES: GptCityT = {
	id: 20,
	name: 'losAngeles',
	country: 'usa',
	description: 'losAngelesDescription',
	image: 'los_angeles',
	geonameid: 5368361,
	countryCode: 'US',
	city: {
		geonameid: 5368361,
		name: 'Los Angeles',
		population: 3898747,
		latitude: 34.05223,
		longitude: -118.24368,
		division: {
			code: null,
			geonameid: 5368381,
			name: 'Los Angeles',
			type: 'ADM2',
		},
		country: {
			code: 'US',
			name: 'United States of America',
			geonameid: 6252001,
			depends_on: null,
		},
		currency: {
			code: 'USD',
			name: 'United States dollar',
		},
		timezone: {
			timezone: 'America/Los_Angeles',
			gtm_offset: -25200,
			gmt_offset: -25200,
			is_daylight_saving: true,
			code: 'PDT',
		},
		wiki_id: 'Q65',
		wiki_url: 'https://en.wikipedia.org/wiki/Los_Angeles',
		elevation: 89,
		status: 'success',
	},
};

export const TOP_20_CITIES: Array<GptCityT> = [
	BANGKOK,
	LONDON,
	PARIS,
	DUBAI,
	SINGAPORE,
	NEW_YORK_CITY,
	ISTANBUL,
	TOKYO,
	KUALA_LUMPUR,
	SEOUL,
	// HONG_KONG,
	BARCELONA,
	AMSTERDAM,
	ROME,
	OSAKA,
	MILAN,
	VIENNA,
	SHANGHAI,
	PRAGUE,
	LOS_ANGELES,
];
