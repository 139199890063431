import { getTripForUserPath, getTripPath } from '../../apiPaths';
import {
	sendNegativeNotification,
	sendPositiveNotification,
} from '../../utils';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { Button } from 'baseui/button';
import { CenterRow } from '../layout/CenterRow';
import { Trip } from './Trip';
import { TripT } from '../../types';
import { onDelete } from '../../hooks/useMutate';
import { useContext } from 'react';
import { useQueryValue } from '../../hooks/useQuery';
import { useTranslation } from 'react-i18next';

export function TripPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const location = useLocation();
	const pathNames = location.pathname.split('/');
	const tripId = pathNames[2];

	const [trip] = useQueryValue<TripT>(
		getTripForUserPath(user?.uid ?? '', tripId),
	);
	if (!user) navigate('/login');
	const handleCancelTrip = () => {
		if (!user) return;
		onDelete(
			getTripPath(user.uid, tripId),
			() => {
				sendPositiveNotification(t('tripCancelSuccess'));
				navigate('/trips');
			},
			(error) => sendNegativeNotification(t('tripCancelError')),
		);
	};
	return (
		<>
			<Trip trip={trip} />
			{trip && (
				<CenterRow>
					<Button
						style={{ marginTop: '2rem' }}
						onClick={handleCancelTrip}
					>
						{t('cancelTrip')}
					</Button>
				</CenterRow>
			)}
		</>
	);
}
