import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { ArrowLeft, ArrowRight } from 'baseui/icon';

import { Button } from 'baseui/button';
import { ReactNode } from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import { useStyletron } from 'styletron-react';

export function Carousel<T>({
	items,
	renderItem,
}: {
	items: Array<T>;
	renderItem: (item: T) => ReactNode;
}) {
	const [css] = useStyletron();
	const renderArrowPrevClassName = css({
		position: 'absolute',
		top: '50%',
		left: '0%',
		zIndex: 10,
		paddingLeft: '1rem',
	});
	const renderArrowNextClassName = css({
		position: 'absolute',
		top: '50%',
		right: '0%',
		zIndex: 10,
		paddingRight: '1rem',
	});
	// Carousel components
	const renderArrowPrev = (
		clickHandler: () => void,
		hasPrev: boolean,
		label: string,
	) =>
		hasPrev ? (
			<div className={renderArrowPrevClassName}>
				<Button
					kind="secondary"
					shape="circle"
					size="compact"
					onClick={clickHandler}
					aria-label={label}
				>
					<ArrowLeft size={64} />
				</Button>
			</div>
		) : null;
	const renderArrowNext = (
		clickHandler: () => void,
		hasNext: boolean,
		label: string,
	) =>
		hasNext ? (
			<div className={renderArrowNextClassName}>
				<Button
					kind="secondary"
					shape="circle"
					size="compact"
					onClick={clickHandler}
					aria-label={label}
				>
					<ArrowRight size={64} />
				</Button>
			</div>
		) : null;
	return (
		<ResponsiveCarousel
			swipeable={false}
			showIndicators={false}
			showStatus={false}
			renderArrowNext={renderArrowNext}
			renderArrowPrev={renderArrowPrev}
		>
			{/* @ts-ignore */}
			{items.map((item) => renderItem(item))}
		</ResponsiveCarousel>
	);
}
