import { LocationT, MetaComponentPropsT } from '../../../../types';

import { Location } from './Location';

export function LocationList({ component, state }: MetaComponentPropsT) {
	return (
		<>
			{component?.value?.map((value: LocationT) => (
				<Location
					component={{ type: 'Location', value }}
					state={state}
					key={`location_${value.title}`}
				/>
			))}
		</>
	);
}
