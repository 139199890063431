import { APP_CONFIG } from '../../config/appConfig';
import { ConfigContext } from './ConfigContext';
import { ReactNode } from 'react';

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
	const config = APP_CONFIG;
	// Title
	document.title = config.appName;
	// Description
	document
		.querySelector('meta[name="description"]')
		?.setAttribute('content', config.description);
	// Keywords
	document.querySelector('meta[name="keywords"]')?.setAttribute(
		'content',
		config.keywords?.reduce(
			(previousValue, currentValue) =>
				`${previousValue}, ${currentValue}`,
		),
	);
	// Favicon
	var link = document.createElement('link');
	const oldLink = document.getElementById('dynamic-favicon');
	link.id = 'dynamic-favicon';
	link.rel = 'shortcut icon';
	link.href = config.faviconPath;
	if (oldLink) {
		document.head.removeChild(oldLink);
	}
	document.head.appendChild(link);

	return (
		<ConfigContext.Provider value={config}>
			{children}
		</ConfigContext.Provider>
	);
};
