import { DateRange } from './components/DateRange';
import { Loading } from '../common/Loading';
import { Location } from './components/trip/Location';
import { LocationList } from './components/trip/LocationList';
import { LocationListCategorized } from './components/trip/LocationListCategorized';
import { Map } from './components/map/Map';
import { MetaComponentPropsT } from '../../types';
import { Paragraph } from './components/typography/Paragraph';
import { ParagraphList } from './components/typography/ParagraphList';
import { ParagraphMedium } from 'baseui/typography';
import { SimpleSelect } from './components/form/SimpleSelect';
import { SimpleSelectOption } from './components/form/SimpleSelectOption';
import { TripPlan } from './components/trip/TripPlan';
import { useTranslation } from 'react-i18next';

export function MetaComponent({ component, state }: MetaComponentPropsT) {
	const { t } = useTranslation();
	if (component?.loading) return <Loading />;
	switch (component.type) {
		case 'Paragraph':
			return <Paragraph component={component} />;
		case 'ParagraphList':
			return <ParagraphList component={component} />;
		case 'SimpleSelect':
			return <SimpleSelect component={component} />;
		case 'SimpleSelectOption':
			return <SimpleSelectOption component={component} />;
		case 'DateRange':
			return <DateRange component={component} />;
		case 'Map':
			return <Map component={component} />;
		case 'TripPlan':
			return <TripPlan component={component} state={state} />;
		case 'Location':
			return <Location component={component} state={state} />;
		case 'LocationList':
			return <LocationList component={component} state={state} />;
		case 'LocationListCategorized':
			return (
				<LocationListCategorized component={component} state={state} />
			);
		default:
			return <ParagraphMedium>{t('error')}</ParagraphMedium>;
	}
}
