import { HeadingMedium, ParagraphMedium } from 'baseui/typography';
import { useGetCity, useGetCountry } from '../../api';
import { useLocation, useNavigate } from 'react-router-dom';

import { Block } from 'baseui/block';
import { CtaButton } from '../common/CtaButton';
import { InternalLink } from '../common/Link';
import { LanguageContext } from '../../contexts/language/LanguageContext';
import { Loading } from '../common/Loading';
import { Meta } from '../meta/Meta';
import { SectionTitle } from '../layout/SectionTitle';
import { Table } from 'baseui/table';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export function CityPage() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();
	const { language } = useContext(LanguageContext);
	const pathNames = location.pathname.split('/');
	const cityGeonameid = parseInt(pathNames[2]);
	const {
		cityLoading,
		cityError,
		cityData: city,
	} = useGetCity({
		language,
		cityGeonameid,
	});
	const countryCode = city?.country?.code;
	const { countryLoading, countryData: country } = useGetCountry({
		language,
		countryCode,
	});

	if (cityLoading || countryLoading) return <Loading />;
	if (cityError) return <ParagraphMedium>{t('cityError')}</ParagraphMedium>;

	return (
		<>
			<HeadingMedium style={{ marginBottom: '1rem' }}>
				{`${country?.flag?.emoji} ${city.name}`}
			</HeadingMedium>
			<SectionTitle text={t('overview')} />
			<Table
				data={[
					[
						t('country'),
						<InternalLink to={`/country/${country.code}`}>
							{country.name}
						</InternalLink>,
					],
					[t('population'), city.population.toLocaleString()],
					[
						t('timezone'),
						`${city.timezone.timezone} (${city.timezone.code})`,
					],
					[t('currency'), city.currency.name],
					[
						t('elevation'),
						`${city.elevation.toLocaleString()} meters`,
					],
				]}
			/>
			<SectionTitle text={t('map')} />
			<Block paddingBottom="1rem">
				<Meta
					config={{
						components: [
							{
								type: 'Map',
								value: {
									latitude: city?.latitude,
									longitude: city?.longitude,
								},
							},
						],
					}}
				/>
			</Block>
			<CtaButton
				text={t('planTrip')}
				onClick={() =>
					navigate(
						`/plan?countryCode=${countryCode}&city=${JSON.stringify(city)}`,
					)
				}
			/>
		</>
	);
}
