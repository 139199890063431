import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { StyledLink } from 'baseui/link';

export const ExternalLink = ({
	href,
	children,
}: {
	href: string;
	children: ReactNode;
}) => (
	<StyledLink href={href} target="__blank">
		{children}
	</StyledLink>
);

export const InternalLink = ({
	to,
	children,
}: {
	to: string;
	children: ReactNode;
}) => (
	<Link to={to}>
		<StyledLink>{children}</StyledLink>
	</Link>
);

export const CustomLink = ({
	onClick,
	children,
}: {
	onClick: () => void;
	children: ReactNode;
}) => (
	<StyledLink onClick={onClick} $style={{ cursor: 'pointer' }}>
		{children}
	</StyledLink>
);
