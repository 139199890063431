import Alert from 'baseui/icon/alert';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { PRIMARY_COLOR } from '../config/themeConfig';
import { ParagraphMedium } from 'baseui/typography';
import { ScreenContainer } from './common/ScreenContainer';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

export function NotFoundPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [css, theme] = useStyletron();
	const UnderConstructionPage = () => (
		<Block
			width="100%"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			paddingTop="10vh"
		>
			<Alert size={theme.sizing.scale1600} color={PRIMARY_COLOR} />
			<ParagraphMedium>{t('pageNotFound')}</ParagraphMedium>
			<Button
				size="compact"
				shape="pill"
				onClick={() => navigate('/')}
				style={{ marginTop: '1rem' }}
			>
				{t('goHome')}
			</Button>
		</Block>
	);
	return (
		<ScreenContainer>
			<UnderConstructionPage />
		</ScreenContainer>
	);
}
