import { SyntheticEvent, useContext, useRef } from 'react';

import { Block } from 'baseui/block';
import { LabelMedium } from 'baseui/typography';
import { LanguageContext } from '../../contexts/language/LanguageContext';
import { Select } from 'baseui/select';
import { StatefulPopover } from 'baseui/popover';
import { StyleContext } from '../../contexts/style/StyleContext';
import { getLanguageIcon } from '../../icons/getLanguageIcon';
import { useTranslation } from 'react-i18next';

export type LanguageCodeT =
	| 'en'
	| 'zh'
	| 'es'
	| 'it'
	| 'pt'
	| 'de'
	| 'ru'
	| 'ja'
	| 'fr'
	| 'ar'
	| 'fa'
	| 'pl';

export type LanguageLabelT =
	| 'English'
	| '中文'
	| 'Español'
	| 'Italiano'
	| 'Português'
	| 'Deutsch'
	| 'Русский'
	| '日本語'
	| 'Français'
	| 'العربية'
	| 'فارسی'
	| 'Polski';

export const ALL_LANGUAGE_CODES: LanguageCodeT[] = [
	'en',
	'es',
	'zh',
	'it',
	'pt',
	'de',
	'ru',
	'ja',
	'fr',
	'ar',
	'fa',
	'pl',
];

export const ALL_LANGUAGE_LABELS = [
	'English',
	'中文',
	'Español',
	'Italiano',
	'Português',
	'Deutsch',
	'Русский',
	'日本語',
	'Français',
	'العربية',
	'فارسی',
	'Polski',
];

export const LANGUAGE_OPTIONS: Array<{
	id: LanguageCodeT;
	label: LanguageLabelT;
}> = [
	{ id: 'en', label: 'English' }, // English
	{ id: 'zh', label: '中文' }, //Chinese - '中文 (Zhōngwén)'
	{ id: 'es', label: 'Español' }, // Spanish
	{ id: 'it', label: 'Italiano' }, // Italian
	{ id: 'pt', label: 'Português' }, // Portuguese
	{ id: 'de', label: 'Deutsch' }, // German
	{ id: 'ru', label: 'Русский' }, // Russian - 'Русский (Russkiy)'
	{ id: 'ja', label: '日本語' }, // Japanese - '日本語 (Nihongo)'
	{ id: 'fr', label: 'Français' }, // French
	{ id: 'ar', label: 'العربية' }, // Arabic -  "العربية (Al-'Arabīyah)"
	{ id: 'fa', label: 'فارسی' }, // Persian - 'فارسی (Fārsi)'
	{ id: 'pl', label: 'Polski' }, // Polish
];

// @ts-ignore
const CustomPopover = ({ onMouseEnter, ...rest }) => {
	return (
		// @ts-ignore
		<StatefulPopover
			onMouseEnter={() => {
				return;
			}}
			{...rest}
			overrides={{ Body: { style: { margin: '1rem' } } }}
		/>
	);
};

export function Language() {
	const { t } = useTranslation();
	const ref = useRef(null);
	const { isDarkMode } = useContext(StyleContext);
	const { language, setLanguage } = useContext(LanguageContext);
	const value = [LANGUAGE_OPTIONS.find((o) => o?.id === language)] ?? [
		LANGUAGE_OPTIONS[0],
	];

	const LanguageIcon = getLanguageIcon(isDarkMode);

	return (
		<Block
			display="flex"
			alignItems="center"
			$style={{
				zIndex: 10, // Adjust this value based on your needs
				position: 'relative', // `z-index` requires a position context
			}}
			onClick={(e: SyntheticEvent) => {
				e.preventDefault(); // Prevent default action
				e.stopPropagation(); // Stop event from bubbling up
			}}
		>
			<Select
				ref={ref}
				options={LANGUAGE_OPTIONS}
				labelKey="label"
				valueKey="id"
				getValueLabel={({ option }) => {
					return <Block display="flex">{LanguageIcon}</Block>;
				}}
				size="mini"
				onChange={({ value }) => {
					// @ts-ignore
					setLanguage(value[0]?.id);
					if (ref?.current) {
						// @ts-ignore
						ref?.current?.blur();
					}
				}}
				// @ts-ignore
				value={value}
				clearable={false}
				searchable={false}
				overrides={{
					Dropdown: { style: { width: '6rem', maxHeight: '100rem' } },
					Popover: {
						component: CustomPopover,
					},
				}}
			/>
			<LabelMedium $style={{ marginLeft: '0.75rem' }}>
				{t('language')}
			</LabelMedium>
		</Block>
	);
}
