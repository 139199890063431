import {
	CitiesInCountryResponseT,
	CityDetailsT,
	CountryDetailsT,
	CountrySearchT,
	GptMessageT,
} from './types';
import { getFunctions, httpsCallable } from 'firebase/functions';

import get from 'lodash/get';
// import { model } from './firebaseSetup';
import { useQuery } from 'react-query';

// Gemini
// export const useGemini = (prompt: string, queryKey: string) => {
// 	const { isLoading, error, data } = useQuery(['gemini', queryKey], () =>
// 		model.generateContent(prompt),
// 	);
// 	let res = null;
// 	try {
// 		res = JSON.parse(data?.response?.text() ?? '');
// 	} catch {
// 		res = null;
// 	}
// 	return {
// 		isLoading,
// 		error,
// 		data: res,
// 	};
// };

const functions = getFunctions();
// Rapid API
const callGetCountries = httpsCallable(functions, 'callGetCountries');
const callGetCountry = httpsCallable(functions, 'callGetCountry');
const callGetCitiesInCountry = httpsCallable(
	functions,
	'callGetCitiesInCountry',
);
const callGetCity = httpsCallable(functions, 'callGetCity');
// OpenAI
const callGptChatCompletions = httpsCallable(
	functions,
	'callGptChatCompletions',
);
// Amadeus
const callAmadeusCitySearch = httpsCallable(functions, 'callAmadeusCitySearch');

export const useCitySearch = ({
	keyword,
	countryCode,
	max,
}: {
	keyword: string;
	countryCode?: string;
	max?: number;
}) => {
	const { isLoading, error, data } = useQuery(['citySearch', keyword], () =>
		callAmadeusCitySearch({ keyword, countryCode, max }),
	);
	return {
		citySearchLoading: isLoading,
		citySearchError: error,
		citySearchData: data,
	};
};

export const useGetCountries = ({
	language,
}: {
	language: string;
}): {
	countriesLoading: boolean;
	countriesError: any;
	countriesData: Array<CountrySearchT>;
} => {
	const { isLoading, error, data } = useQuery(
		['countriesData', language],
		() => callGetCountries({ language }),
	);
	return {
		countriesLoading: isLoading,
		countriesError: error,
		countriesData: get(data, ['data', 'countries']),
	};
};

export const useGetCountry = ({
	language,
	countryCode,
}: {
	language: string;
	countryCode: string;
}): {
	countryLoading: boolean;
	countryError: any;
	countryData: CountryDetailsT;
} => {
	const { isLoading, error, data } = useQuery(
		['countryData', language, countryCode],
		() => callGetCountry({ language, countryCode }),
		{ enabled: !!countryCode },
	);
	return {
		countryLoading: isLoading,
		countryError: error,
		// @ts-ignore
		countryData: get(data, ['data']),
	};
};

export const useGetCitiesInCountry = ({
	page,
	perPage,
	minPopulation,
	language,
	countryCode,
}: {
	page: number;
	perPage: number;
	minPopulation: number;
	language: string;
	countryCode: string;
}): {
	citiesInCountryLoading: boolean;
	citiesInCountryError: any;
	citiesInCountryData: CitiesInCountryResponseT;
} => {
	const { isLoading, error, data } = useQuery(
		['citiesInCountryData', language, countryCode, page, minPopulation],
		() =>
			callGetCitiesInCountry({
				page,
				perPage,
				minPopulation,
				language,
				countryCode,
			}),
	);
	return {
		citiesInCountryLoading: isLoading,
		citiesInCountryError: error,
		// @ts-ignore
		citiesInCountryData: get(data, ['data']),
	};
};

export const useGetCity = ({
	language,
	cityGeonameid,
}: {
	language: string;
	cityGeonameid: number;
}): {
	cityLoading: boolean;
	cityError: any;
	cityData: CityDetailsT;
} => {
	const { isLoading, error, data } = useQuery(
		['cityData', language, cityGeonameid],
		() => callGetCity({ language, cityGeonameid }),
	);
	return {
		cityLoading: isLoading,
		cityError: error,
		// @ts-ignore
		cityData: get(data, ['data']),
	};
};

export const useGpt = (prompt: string, queryKey?: string) => {
	const { isLoading, error, data } = useQuery(
		['gptChatCompletionsData', queryKey ?? 'generic'],
		() =>
			callGptChatCompletions({
				messages: [
					{
						role: 'user',
						content: prompt,
					},
				],
			}),
		{
			enabled: !!prompt,
			staleTime: Infinity, // cache data infinitely to prevent re-fetching during re-renders
		},
	);
	const resString = get(data, ['data', 'choices', 0, 'message', 'content']);
	let resObject = {};
	try {
		resObject = JSON.parse(resString);
	} catch {
		resObject = {};
	}
	return {
		data: resObject,
		loading: isLoading,
		error: error,
	};
};

export const useGptChatCompletions = (
	messages: Array<GptMessageT>,
	queryKey?: string,
) => {
	const { isLoading, error, data } = useQuery(
		['gptChatCompletionsData', queryKey ?? 'generic'],
		() =>
			callGptChatCompletions({
				messages,
			}),
		{
			enabled: !!messages?.length,
			staleTime: Infinity, // cache data infinitely to prevent re-fetching during re-renders
		},
	);
	const resString = get(data, ['data', 'choices', 0, 'message', 'content']);
	let resObject = {};
	try {
		resObject = JSON.parse(resString);
	} catch {
		resObject = {};
	}
	return {
		data: resObject,
		loading: isLoading,
		error: error,
	};
};
