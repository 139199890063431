import { Calendar } from 'baseui/datepicker';
import { HeadingLarge } from 'baseui/typography';
import { useTranslation } from 'react-i18next';

export function When({
	selectedDates,
	setSelectedDates,
}: {
	selectedDates: Array<string>;
	setSelectedDates: (selectedDates: Array<string>) => void;
}) {
	const { t } = useTranslation();
	const today = new Date();
	const oneYearFromNow = new Date();
	oneYearFromNow.setFullYear(today.getFullYear() + 1);
	return (
		<>
			<HeadingLarge marginBottom="2rem">{t('when')}</HeadingLarge>
			<Calendar
				onChange={({ date }) => {
					// @ts-ignore
					setSelectedDates(date?.map((date) => date.toString()));
				}}
				value={selectedDates.map((date) => new Date(date))}
				range
				// @ts-ignore
				minDate={today}
				// @ts-ignore
				maxDate={oneYearFromNow}
				overrides={{ CalendarContainer: { style: { width: '100%' } } }}
			/>
		</>
	);
}
