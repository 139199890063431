import { HeadingLarge, HeadingMedium } from 'baseui/typography';

import { Block } from 'baseui/block';
import { MetaComponent } from './MetaComponent';
import { MetaConfigT } from '../../types';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

export function Meta({ config }: { config: MetaConfigT }) {
	const { t } = useTranslation();
	const title = get(config, 'title');
	return (
		<>
			{title && <HeadingLarge>{title}</HeadingLarge>}
			{config.components?.map(
				(component) =>
					!get(component, 'skip') && (
						<Block marginTop="1rem" width="100%">
							{component?.label && (
								<HeadingMedium
									$style={{
										marginTop: '1rem',
										marginBottom: '0.5rem',
									}}
								>
									{t(component.label)}
								</HeadingMedium>
							)}
							<MetaComponent
								component={component}
								state={config?.state ?? {}}
							/>
						</Block>
					),
			)}
		</>
	);
}
