import { MetaComponentPropsT } from '../../../../types';
import { SimpleSelectOption } from './SimpleSelectOption';

export function SimpleSelect({ component }: MetaComponentPropsT) {
	const { value, setValue, options } = component;
	return (
		<>
			{options?.map((option) => (
				<SimpleSelectOption
					component={{
						type: 'SimpleSelectOption',
						value,
						setValue,
						option,
					}}
				/>
			))}
		</>
	);
}
