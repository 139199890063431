import { HeadingLarge, ParagraphMedium } from 'baseui/typography';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { ListItem } from 'baseui/list';
import get from 'lodash/get';
import { getTripsForUserPath } from '../../apiPaths';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryArray } from '../../hooks/useQuery';
import { useTranslation } from 'react-i18next';

export function TripDashboardPage() {
	const { t } = useTranslation();
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();
	const [trips] = useQueryArray(getTripsForUserPath(user?.uid ?? ''));
	if (!user) {
		navigate('/login');
	}
	const hasPlannedTrips = trips.length;
	const planTripText = hasPlannedTrips
		? t('planAnotherTrip')
		: t('planFirstTrip');
	return (
		<>
			<HeadingLarge>{t('yourTrips')}</HeadingLarge>
			{!hasPlannedTrips && (
				<ParagraphMedium>{t('noTrips')}</ParagraphMedium>
			)}
			{trips.map((trip) => (
				<Block
					onClick={() => navigate(`/trips/${get(trip, ['id'])}`)}
					$style={{ cursor: 'pointer' }}
				>
					<ListItem>
						<ParagraphMedium>
							{get(trip, ['query', 'city', 'name'])}
						</ParagraphMedium>
					</ListItem>
				</Block>
			))}
			<Block
				width="100%"
				display="flex"
				justifyContent="center"
				marginTop="2rem"
			>
				<Button onClick={() => navigate('/plan')}>
					{planTripText}
				</Button>
			</Block>
		</>
	);
}
