export const STRIPE_SUBSCRIPTION_URL =
	'https://buy.stripe.com/eVa5na0o08JH2nS5kk';
export const STRIPE_PORTAL_URL =
	'billing.stripe.com/p/login/14k4hqffu0eJ5Nu144';
export const MAX_TRIPS_BEFORE_SUBSCRIPTION = 3;

export enum StyleMode {
	LIGHT,
	DARK,
}

export const EMOJI_CODEPOINTS = {
	attractions: 0x1f5fc,
	shopping: 0x1f6cd,
	beaches: 0x1f3d6,
	parks: 0x1f332,
	waterSports: 0x1f30a,
	sportingEvents: 0x26bd,
	bars: 0x1f37a,
	clubs: 0x1f37e,
	events: 0x1f50a,
	culture: 0x1f3ad,
	history: 0x1f5ff,
	art: 0x1f5bc,
	spa: 0x1f486,
	amusementParks: 0x1f3a2,
	movies: 0x1f37f,
	arcades: 0x1f579,
	familyFriendly: 0x1f476,
	breakfast: 0x1f95e,
	lunch: 0x1f96a,
	dinner: 0x1f35c,
	food: 0x1f958,
	nightlife: 0x1f37e,
	park: 0x1f3de,
	phone: 0x1f4f1,
	email: 0x1f4e7,
	hotel: 0x1f3e8,
	hotelCheckIn: 0x1f3e8,
	hotelCheckOut: 0x1f3e8,
};
