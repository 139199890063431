import { CitySearchT, TripGroupTypeT } from '../../types';
import { useContext, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { MAX_TRIPS_BEFORE_SUBSCRIPTION } from '../../constants';
import { Result } from './steps/Result';
import { Steps } from '../common/Steps';
import { What } from './steps/What';
import { When } from './steps/When';
import { Where } from './steps/Where';
import { Who } from './steps/Who';
import { getTripsForUserPath } from '../../apiPaths';
import { useQueryArray } from '../../hooks/useQuery';

export function TripMutatePage() {
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const [trips] = useQueryArray(getTripsForUserPath(user?.uid ?? ''));
	const requiresSubscription =
		!!trips && trips?.length > MAX_TRIPS_BEFORE_SUBSCRIPTION;
	const [searchParams] = useSearchParams();
	const countryCodeParams = searchParams.get('countryCode');
	const cityParams = searchParams.get('city');
	const parsedCityParams = cityParams ? JSON.parse(cityParams) : null;
	// Skip first step (where) if prefilled through URL
	const startingStep = countryCodeParams && parsedCityParams ? 2 : 1;

	const [step, setStep] = useState(startingStep);
	const [countryCode, setCountryCode] = useState<string | null>(
		countryCodeParams,
	);
	const [city, setCity] = useState<CitySearchT | null>(parsedCityParams);
	const [selectedDates, setSelectedDates] = useState<Array<string>>([]);
	const [groupType, setGroupType] = useState<TripGroupTypeT | null>(null);
	const [isTravelingWithChildren, setIsTravelingWithChildren] =
		useState(false);
	const [activities, setActivities] = useState<Array<string>>([]);
	const [otherActivities, setOtherActivities] = useState('');

	const nextButtonEnabled = useMemo(() => {
		if (step === 1) return !!countryCode && !!city;
		if (step === 2) return selectedDates.length > 1;
		if (step === 3) return !!groupType;
		if (step === 4) return activities.length > 0 || !!otherActivities;
		return true;
	}, [
		activities.length,
		city,
		countryCode,
		groupType,
		otherActivities,
		selectedDates.length,
		step,
	]);

	const StepContent = useMemo(() => {
		if (step === 1) {
			return (
				<Where
					countryCode={countryCode}
					setCountryCode={setCountryCode}
					city={city}
					setCity={setCity}
				/>
			);
		}
		if (step === 2) {
			return (
				<When
					selectedDates={selectedDates}
					setSelectedDates={setSelectedDates}
				/>
			);
		}
		if (step === 3) {
			return (
				<Who
					groupType={groupType}
					setGroupType={setGroupType}
					isTravelingWithChildren={isTravelingWithChildren}
					setIsTravelingWithChildren={setIsTravelingWithChildren}
				/>
			);
		}
		if (step === 4) {
			return (
				<What
					activities={activities}
					setActivities={setActivities}
					setOtherActivities={setOtherActivities}
				/>
			);
		}
		if (step === 5) {
			return (
				<Result
					countryCode={countryCode}
					city={city}
					selectedDates={selectedDates}
					groupType={groupType}
					isTravelingWithChildren={isTravelingWithChildren}
					activities={activities}
					otherActivities={otherActivities}
				/>
			);
		}
		return null;
	}, [
		activities,
		city,
		countryCode,
		groupType,
		isTravelingWithChildren,
		otherActivities,
		selectedDates,
		step,
	]);

	// If the user has too many trips associated with their account, force them to subscribe
	if (requiresSubscription) {
		navigate('/subscription');
	}
	return (
		<Steps
			step={step}
			setStep={setStep}
			numSteps={5}
			nextButtonEnabled={nextButtonEnabled}
		>
			{StepContent}
		</Steps>
	);
}
