import { LanguageCodeT } from '../../components/common/Language';
import { createContext } from 'react';

export const LanguageContext = createContext<{
	language: LanguageCodeT;
	setLanguage: (language: LanguageCodeT) => void;
}>({
	language: 'en',
	setLanguage: () => null,
});
